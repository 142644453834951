import React from 'react';
import { Paper, Typography, Box } from '@mui/material';

interface BaseCardProps {
  title: string;
  isSelected?: boolean;
  onClick?: () => void;
  comingSoon?: boolean;
  children?: React.ReactNode;
  totalCount?: string;
  icon?: React.ReactElement;
}

const BaseCard: React.FC<BaseCardProps> = ({
  title,
  isSelected,
  onClick,
  comingSoon,
  children,
  totalCount,
  icon,
}) => (
  <Paper
    elevation={isSelected ? 8 : 1}
    sx={{
      p: 2,
      cursor: 'pointer',
      transition: 'all 0.3s',
      transform: isSelected ? 'scale(1.02)' : 'none',
      '&:hover': {
        elevation: 3,
      },
      borderRadius: '8px',
      border: isSelected
        ? '1px solid var(--Background-B6---P---Purple, #865E9C)'
        : '1px solid var(--Outline-O5---Separator, #D4D4D9)',
      boxShadow: isSelected ? '-4px 8px 24px 0px rgba(0, 0, 0, 0.25)' : 'none',
      height: 'auto', // min : 152 px
      flex: '1 0 0',
      overflow: 'hidden',
    }}
    onClick={onClick}
  >
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        mb: 2,
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography
          sx={{
            color: 'var(--Font-F3---Body, #585863)',
            fontSize: '16px',
            fontWeight: 700,
            letterSpacing: '0.2px',
            mr: 1,
          }}
        >
          {title}
        </Typography>
        {icon &&
          React.cloneElement(icon, {
            style: { color: isSelected ? '#865E9C' : undefined },
          })}
      </Box>
      <Typography
        sx={{
          color: 'var(--Font-F3---Body, #585863)',
          fontSize: '16px',
          fontWeight: 700,
          letterSpacing: '0.2px',
        }}
      >
        {totalCount}
      </Typography>
      {comingSoon && (
        <Box>
          <Typography
            sx={{
              color: '#2A2A30',
              fontSize: '16px',
              fontWeight: 700,
              lineHeight: '22px',
            }}
            textAlign="right"
          >
            Coming soon
          </Typography>
          <Typography
            sx={{
              color: '#585863',
              fontSize: '14px',
              fontWeight: 500,
              lineHeight: '20px',
            }}
          >
            Check back for updates!
          </Typography>
        </Box>
      )}
    </Box>

    {!comingSoon && ( // For temporary use
      <Box sx={{ overflow: 'hidden' }}>{children}</Box>
    )}
  </Paper>
);

export default BaseCard;

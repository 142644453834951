import React from 'react';
import { BarChart, Bar, XAxis, YAxis } from 'recharts';
import BaseCard from './BaseCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeartPulse } from '@fortawesome/pro-duotone-svg-icons';

interface VitalsCardProps {
  isSelected?: boolean;
  onClick?: () => void;
  comingSoon?: boolean;
  data?: {
    labels: string[];
    normal: number[];
    atRisk: number[];
    actionRequired: number[];
  };
}

export const VitalsCard: React.FC<VitalsCardProps> = ({
  isSelected,
  onClick,
  comingSoon,
  data,
}) => {
  if (comingSoon) {
    return (
      <BaseCard
        title="Vitals"
        isSelected={isSelected}
        onClick={onClick}
        comingSoon={true}
        icon={<FontAwesomeIcon icon={faHeartPulse} color="#585863" />}
      />
    );
  }

  const chartData = data?.labels.map((label, index) => ({
    name: label,
    normal: data.normal[index],
    atRisk: data.atRisk[index],
    actionRequired: data.actionRequired[index],
  }));

  return (
    <BaseCard
      title="Vitals"
      isSelected={isSelected}
      onClick={onClick}
      icon={<FontAwesomeIcon icon={faHeartPulse} color="#585863" />}
    >
      <BarChart width={112} height={125} data={chartData}>
        <XAxis dataKey="name" />
        <YAxis />
        <Bar dataKey="normal" stackId="a" fill="#4caf50" />
        <Bar dataKey="atRisk" stackId="a" fill="#ffb74d" />
        <Bar dataKey="actionRequired" stackId="a" fill="#ff1744" />
      </BarChart>
    </BaseCard>
  );
};

import React from 'react';
import { Box, Typography } from '@mui/material';
import BaseCard from './BaseCard';
import HubIcon from '../../../images/icons/HubIcon';

interface HubCardProps {
  isSelected?: boolean;
  onClick?: () => void;
  data: {
    offline: number;
    unassigned: number;
    online: number;
    total: number;
  };
}

const StatusBox: React.FC<{ count: number; status: string }> = ({
  count,
  status,
}) => {
  const getColor = (status: string) => {
    switch (status) {
      case 'offline':
        return {
          bg: 'var(--Semantics-S11---Error-Bg, #FCF0EE)',
          border: '2px solid var(--Semantics-S10---Error-Ic-F, #DB5543)',
        };
      case 'unassigned':
        return {
          bg: 'var(--Semantics-S11---Warning-Bg, #FFF4E5)',
          border: '2px solid var(--Semantics-S10---Warning-Ic-F, #FFA726)',
        };
      case 'online':
        return {
          bg: 'var(--Semantics-S11---Success-Bg, #E8F5E9)',
          border: '2px solid var(--Semantics-S10---Success-Ic-F, #66BB6A)',
        };
      default:
        return {
          bg: '#FFFFFF',
          border: '2px solid #000000',
        };
    }
  };

  const { bg, border } = getColor(status);

  return (
    <Box
      sx={{
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography
        sx={{
          width: 56,
          height: 56,
          flexShrink: 0,
          borderRadius: '50%',
          bgcolor: bg,
          color: '#585863',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          mb: 1,
          border: border,
        }}
      >
        {count}
      </Typography>
      <Typography color="#585863" variant="body2">
        {status}
      </Typography>
    </Box>
  );
};

const HubCard: React.FC<HubCardProps> = ({ isSelected, onClick, data }) => (
  <BaseCard
    title="Hubs"
    isSelected={isSelected}
    onClick={onClick}
    totalCount={`${data.total} total`}
    icon={<HubIcon color={isSelected ? '#865E9C' : '#9999A4'} />}
  >
    <Box sx={{ display: 'flex', gap: 2, justifyContent: 'space-around' }}>
      {['offline', 'unassigned', 'online'].map((status) => (
        <StatusBox
          key={status}
          count={data[status as keyof typeof data]}
          status={status}
        />
      ))}
    </Box>
  </BaseCard>
);

export default HubCard;

import { Box, Typography } from '@mui/material';
import React from 'react';

export const CustomLegend = ({ payload }: any) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 0.5,
        fontSize: '0.75rem',
      }}
    >
      {payload?.map((entry: any, index: number) => (
        <Box
          key={`legend-${index}`}
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
          }}
        >
          <Box
            sx={{
              width: 8,
              height: 8,
              borderRadius: '50%',
              backgroundColor: entry?.color,
            }}
          />
          <Typography
            sx={{
              color: '#585863',
              fontSize: '14px',
              fontWeight: 500,
              lineHeight: '16px',
            }}
          >
            {entry?.value} {entry?.name}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

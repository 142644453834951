import {
  Box,
  Button,
  IconButton,
  MenuItem,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { lightBackground1 } from '../../../mainTheme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBarsFilter,
  faCircleExclamation,
  faTriangleExclamation,
  faCircleCheck,
} from '@fortawesome/pro-solid-svg-icons';
import {
  faMagnifyingGlass,
  faAngleRight,
} from '@fortawesome/pro-light-svg-icons';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import StatusChip from '../../Shared/StatusChip';
import { getDeviceList } from '../../../store/devices/actionCreators';
import { Device } from '../../../store/devices/deviceTypes';
import { CustomerCareHubRow } from '../../../store/hubOwner/types';
import { Status } from '../../../shared/constants';
import { DashboardTabs } from '../../Dashboard/components/DashboardTabs';
import CustomerCareDTable from '../../Shared/CustomerCareDTable';
import DashboardTableLegend from '../../Dashboard/detailPanel/DashboardTableLegend';
import { parseDateToLocal } from '../../../shared/utils';
import CustomerCareSelect from '../../Shared/CustomerCareSelect';

interface Options {
  label: string;
  value: string;
}

const ActionCell = ({ deviceId }: { deviceId: number }) => {
  const history = useHistory();
  const handleNavigation = () => {
    history.push(`/v2/hubs/${deviceId}`);
  };

  return (
    <Box
      width="32px"
      display="flex"
      justifyContent="center"
      borderRadius="8px"
      border="1px solid var(--Outline-O5---Separator, #D4D4D9)"
    >
      <IconButton size="small" onClick={handleNavigation}>
        <FontAwesomeIcon icon={faAngleRight} size="sm" />
      </IconButton>
    </Box>
  );
};

const stateSelector = (state: RootState) => {
  const { devices } = state;
  const { deviceList, isLoading: isDeviceListLoading } = devices;

  return {
    deviceList,
    isDeviceListLoading,
  };
};

const CustomerHubOverview = () => {
  const dispatch = useDispatch();

  const { deviceList, isDeviceListLoading } = useSelector<
    RootState,
    ReturnType<typeof stateSelector>
  >(stateSelector);

  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedFacility, setSelectedFacility] = useState('all');
  const [selectedLocation, setSelectedLocation] = useState('all');
  const [selectedStatus, setSelectedStatus] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    if (!deviceList) {
      dispatch(getDeviceList());
    }
  }, [deviceList, dispatch]);

  const columns = [
    {
      field: 'patient',
      headerName: 'Patient',
      flex: 1,
    },
    ...(selectedTab !== 2
      ? [{ field: 'facility_name', headerName: 'Facility', flex: 1 }]
      : []),
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      sortComparator: (v1: any, v2: any) => {
        return v1.text.localeCompare(v2.text);
      },
      renderCell: (params: any) => {
        const statusValue = params.value;
        const getBackgroundColor = () => {
          switch (statusValue.text) {
            case Status.Online:
              return 'var(--Semantics-S2---Success-Bg, #ECF8F0)';
            case Status.Offline:
              return 'var(--Semantics-S11---Error-Bg, #FCF0EE)';
            case Status.Unassigned:
              return '#FFFAEB';
            default:
              return '#FCF0EE';
          }
        };
        return (
          <Box display="flex" alignItems="center">
            <StatusChip
              icon={statusValue.icon}
              iconColor={statusValue.color}
              labelText={statusValue.text}
              conditionalValue={true}
              backgroundColor={getBackgroundColor()}
            />
          </Box>
        );
      },
    },
    { field: 'hub_serial_number', headerName: 'Serial number', flex: 1 },
    { field: 'last_seen_online', headerName: 'Last online', flex: 1 },
    {
      field: 'action',
      headerName: 'Actions',
      renderCell: (params: any) => <ActionCell deviceId={params.row.id} />,
    },
  ];

  const rows: CustomerCareHubRow[] = useMemo(() => {
    let filteredDevices = deviceList || [];

    if (deviceList) {
      if (selectedTab === 1) {
        filteredDevices = filteredDevices?.filter(
          (device: Device) => device.facility_id != null
        );
      } else if (selectedTab === 2) {
        filteredDevices = filteredDevices?.filter(
          (device: Device) => device.facility_id == null
        );
      }
    }
    return (
      filteredDevices?.map((device: Device) => {
        const isUnassigned = device.customer_id === null;
        const isOnline = device.status === Status.Online.toLowerCase();
        const hasPatient = device?.first_name || device?.last_name;

        let statusIcon = faTriangleExclamation;
        let statusColor = '#DB5543'; // red for offline
        let statusText = Status.Offline;

        if (isUnassigned) {
          statusIcon = faCircleExclamation;
          statusColor = '#FCCD32'; // yellow for unassigned
          statusText = Status.Unassigned;
        } else if (isOnline) {
          statusIcon = faCircleCheck;
          statusColor = '#42AF60'; // green for online
          statusText = Status.Online;
        }

        return {
          id: device.id,
          patient: hasPatient
            ? `${device?.first_name || ''} ${device?.last_name || ''}`.trim()
            : 'N/A',
          facility_name: device.facility_name || 'N/A',
          facility_location: device.facility_location || 'N/A', // Assuming this will be added later
          status: {
            icon: statusIcon,
            color: statusColor,
            text: statusText,
          },
          hub_serial_number: device?.serial_number,
          last_seen_online: device.last_seen_online
            ? parseDateToLocal(device.last_seen_online, 'yyyy-MM-dd HH:mm:ss')
            : 'N/A',
          action: faBarsFilter,
          contact_name: device.contact_name || null,
          contact_phone: device.contact_phone || null,
          contact_email: device.contact_email || null,
          isOnline: isOnline,
        };
      }) ?? []
    );
  }, [deviceList, selectedTab]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleFacilityChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value as string;
    setSelectedFacility(value);
  };

  const handleLocationChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value as string;
    setSelectedLocation(value);
  };

  const handleStatusChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value as string;
    setSelectedStatus(value);
  };

  const handleResetFilters = () => {
    setSelectedFacility('all');
    setSelectedLocation('all');
    setSelectedStatus('all');
    setSearchTerm('');
  };

  const filteredData = rows?.filter((row) => {
    const matchesSearchTerm =
      searchTerm === '' ||
      row.patient.toLowerCase().includes(searchTerm.toLowerCase()) ||
      row.status.text.toLowerCase().includes(searchTerm.toLowerCase()) ||
      row.facility_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      row.hub_serial_number.toLowerCase().includes(searchTerm.toLowerCase()) ||
      row.last_seen_online.toLowerCase().includes(searchTerm.toLowerCase());

    const matchesFacility =
      selectedFacility === 'all' || row.facility_name === selectedFacility;
    const matchesLocation =
      selectedLocation === 'all' || row.facility_location === selectedLocation;
    const matchesStatus =
      selectedStatus === 'all' || row.status.text === selectedStatus;

    return (
      matchesSearchTerm && matchesFacility && matchesLocation && matchesStatus
    );
  });

  const FacilityOptions = useMemo<Options[]>(() => {
    // Default option
    const defaultOption: Options = {
      label: 'All Facilities',
      value: 'all',
    };

    if (!rows?.length) {
      return [defaultOption];
    }

    // Use Map to ensure uniqueness
    const facilityMap = new Map<string, Options>();

    rows.forEach((row) => {
      if (row.facility_name && row.facility_name !== 'N/A') {
        facilityMap.set(row.facility_name, {
          label: row.facility_name,
          value: row.facility_name,
        });
      }
    });

    // Convert Map to array and sort
    const sortedFacilities = Array.from(facilityMap.values()).sort((a, b) =>
      a.label.localeCompare(b.label)
    );

    return [defaultOption, ...sortedFacilities];
  }, [rows]);

  const LocationOptions = useMemo<Options[]>(() => {
    // Default option
    const defaultOption: Options = {
      label: 'All Locations',
      value: 'all',
    };

    if (!rows?.length) {
      return [defaultOption];
    }

    // Use Map to ensure uniqueness
    const locationMap = new Map<string, Options>();

    rows.forEach((row) => {
      if (row.facility_location && row.facility_location !== 'N/A') {
        locationMap.set(row.facility_location, {
          label: row.facility_location,
          value: row.facility_location,
        });
      }
    });

    // Convert Map to array and sort
    const sortedLocations = Array.from(locationMap.values()).sort((a, b) =>
      a.label.localeCompare(b.label)
    );

    return [defaultOption, ...sortedLocations];
  }, [rows]);

  const StatusOptions = useMemo<Options[]>(() => {
    const options: Options[] = [
      { label: 'All Status', value: 'all' },
      { label: 'Online', value: 'Online' },
      { label: 'Offline', value: 'Offline' },
      { label: 'Unassigned', value: 'Unassigned' },
    ];

    return options;
  }, []);

  const tableLegendData = [
    {
      name: 'Online',
      color: '#42AF60',
    },
    {
      name: 'Offline',
      color: '#DB5543',
    },
    {
      name: 'Unassigned',
      color: '#FCCD32',
    },
  ];

  // Calculate the online count
  const onlineCount = useMemo(() => {
    return rows?.filter((row) => row.isOnline).length || 0;
  }, [rows]);
  const totalCount = rows?.length || 0;

  return (
    <Box display="flex" flexDirection="column" bgcolor={lightBackground1}>
      <DashboardTabs
        selectedTab={selectedTab}
        onTabChange={(tab) => setSelectedTab(tab)}
      />

      <Box
        mb={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        gap={2}
        p={2}
      >
        <Typography sx={{ color: '#2A2A30' }}>
          <Box component="span" sx={{ color: '#000000' }}>
            {onlineCount}/{totalCount}
          </Box>
          {' Hubs online'}
        </Typography>
        <TextField
          placeholder="Search"
          value={searchTerm}
          onChange={handleSearchChange}
          variant="outlined"
          InputProps={{
            startAdornment: (
              <Box display="flex" alignItems="center" mr={1}>
                <FontAwesomeIcon icon={faMagnifyingGlass} size="lg" />
              </Box>
            ),
            sx: {
              borderRadius: '10px',
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#D4D4D9',
              },
              color: '#2A2A30',
              '& input::placeholder': {
                color: '#7F7F7F',
                opacity: 1,
              },
            },
          }}
          sx={{
            minWidth: '566px',
            '& .MuiInputLabel-root': {
              color: '#2A2A30',
            },
          }}
        />
      </Box>

      <Box display="flex" mb={2} justifyContent="space-between" p={2}>
        <Box
          display="flex"
          gap={2}
          flexDirection={{ xs: 'column', sm: 'column', md: 'row' }}
        >
          {selectedTab !== 2 && (
            <>
              <Box display="flex" flexGrow={1}>
                <CustomerCareSelect
                  label="Facility name"
                  value={selectedFacility}
                  onChange={handleFacilityChange}
                  sx={{
                    minWidth: '380px',
                  }}
                >
                  {FacilityOptions.map((op: Options) => (
                    <MenuItem key={op.value} value={op.value}>
                      {op.label}
                    </MenuItem>
                  ))}
                </CustomerCareSelect>
              </Box>
              <Box display="flex" flexGrow={1}>
                <CustomerCareSelect
                  label="Facility location"
                  value={selectedLocation}
                  onChange={handleLocationChange}
                  sx={{
                    minWidth: '200px',
                  }}
                >
                  {LocationOptions.map((op: Options) => (
                    <MenuItem key={op.value} value={op.value}>
                      {op.label}
                    </MenuItem>
                  ))}
                </CustomerCareSelect>
              </Box>
            </>
          )}
          <Box display="flex">
            <CustomerCareSelect
              label="Status"
              value={selectedStatus}
              onChange={handleStatusChange}
              sx={{
                minWidth: '200px',
              }}
            >
              {StatusOptions.map((op: Options) => (
                <MenuItem key={op.value} value={op.value}>
                  {op.label}
                </MenuItem>
              ))}
            </CustomerCareSelect>
          </Box>
        </Box>

        <Box display="flex">
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleResetFilters}
          >
            Reset
          </Button>
        </Box>
      </Box>

      <Box px={2} pb={2}>
        <DashboardTableLegend tableLegendData={tableLegendData} />
        <CustomerCareDTable
          rows={filteredData}
          columns={columns}
          loading={isDeviceListLoading}
          pageSize={10}
          defaultSortField="status"
          defaultSortDirection="asc"
          hideViewAllButton
          minHeight="200px"
        />
      </Box>
    </Box>
  );
};

export default CustomerHubOverview;

import { Box, Button, Chip, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { lightBackground1 } from '../../mainTheme';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import LoadingSpinner from '../Shared/LoadingSpinner';
import * as Yup from 'yup';
import { Field, Form, Formik, FormikProps } from 'formik';
import MaskedInput from '../Shared/MaskedInput';
import UserDeletedModal from './components/UserDeletedModal';
import {
  deleteCaregiverById,
  getCaregiverById,
  setDeleteCaregiverSuccess,
  updateCaregiverById,
} from '../../store/facility/actionCreators';
import { getDashboardUser } from '../../store/dashboardUser/actionCreators';
import { getCookie, getFacilityRoleById } from '../../shared/utils';
import ChangeRoleModal from './components/ChangeRoleModal';
import { AccountDelModalTypes, FACILITY_ROLE } from '../../shared/constants';
import DeleteAccountModals from '../Shared/DeleteAccountModals/DeleteAccountModals';
import { logOut } from '../../store/auth/actionCreators';

const baseStyle = {
  color: '#000',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '24px',
  letterSpacing: '0.2px',
};

const headingStyle = {
  ...baseStyle,
  fontSize: '22px',
  lineHeight: '30px',
  letterSpacing: '0.36px',
};

const headLineStyle = {
  ...baseStyle,
  fontSize: '18px',
};

const contentStyle = {
  ...baseStyle,
  color: 'var(--Font-F3---Body, #585863)',
  fontSize: '16px',
  paddingBottom: 2,
};

const validationSchema = Yup.object().shape({
  phone: Yup.string()
    .matches(/^\(\d{3}\) \d{3}-\d{4}$/, 'Phone number is not valid')
    .required('Phone number is required'),
});

const CaregiverProfile = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();

  const { caregiver, isLoading, deleteCaregiverSuccess } = useSelector(
    (state: RootState) => state.facility
  );

  const { dashboardUserInfo } = useSelector(
    (state: RootState) => state.dashboardUser
  );

  const [isEditing, setIsEditing] = useState(false);
  const [isUserDeletedModalOpen, setUserDeletedModalOpen] = useState(false);
  const [isChangeRoleModalOpen, setChangeRoleModalOpen] = useState(false);
  const [currentModal, setCurrentModal] = useState<string | null>(null);

  const formikRef = useRef<FormikProps<{ phone: string }>>(null);
  const isSameCustomer =
    caregiver?.customer_id === dashboardUserInfo?.customer_id;

  // Use a ref to ensure `getDashboardUser` is dispatched only once per render
  const hasFetchedDashboardUser = useRef(false);
  useEffect(() => {
    if (!hasFetchedDashboardUser.current && isSameCustomer) {
      dispatch(getDashboardUser());
      hasFetchedDashboardUser.current = true;
    }
  }, [dispatch, isSameCustomer]);

  const handleBackClick = () => {
    history.goBack();
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = async () => {
    const values = formikRef?.current?.values;
    // Await to call the user updated details when this completes before moving on
    await dispatch(
      updateCaregiverById(Number(id), {
        phone: values?.phone || '',
      })
    );
    // Dispatch getCaregiverById after updateCaregiverById completes
    await dispatch(getCaregiverById(Number(id)));
    setIsEditing(false);
  };

  const handleRoleChangeSubmit = async (values: { role: number }) => {
    if (caregiver && Number(values.role) !== caregiver.facility_role_id) {
      await dispatch(
        updateCaregiverById(Number(id), {
          facility_role_id: Number(values.role),
        })
      );
      await dispatch(getCaregiverById(Number(id)));
    }
    setChangeRoleModalOpen(false);
  };

  const handleCloseUserDeletedModal = () => {
    setUserDeletedModalOpen(false);
    if (isSameCustomer) {
      dispatch(logOut(getCookie('refresh_token') || ''));
    } else {
      history.push('/v2/caregivers');
    }
  };

  useEffect(() => {
    if (deleteCaregiverSuccess) {
      setUserDeletedModalOpen(true);
      dispatch(setDeleteCaregiverSuccess(false));
    }
  }, [deleteCaregiverSuccess, dispatch]);

  const handleChangeRoleClick = () => {
    setChangeRoleModalOpen(true);
  };

  const handlers = {
    onClose: () => setCurrentModal(null),
    onConfirmDelete: () =>
      setCurrentModal(AccountDelModalTypes.ASSIGN_ADMIN_MODAL),
    onNext: () => setCurrentModal(AccountDelModalTypes.CONFIRM_DELETION_MODAL),
    setCurrentModal: setCurrentModal,
    onDeleteAccount: () => {
      dispatch(deleteCaregiverById(caregiver?.customer_id || 0));
    },
  };

  const handleDeleteUserClick = () => {
    const isFacilityAdmin =
      caregiver?.facility_role_id === FACILITY_ROLE.FACILITY_ADMIN;
    const modalType =
      isFacilityAdmin &&
      isSameCustomer && // checks if the user is the only admin in the facility when user tries delete current account
      dashboardUserInfo?.sole_facility_admin
        ? AccountDelModalTypes.ONLY_ADMIN_WARNING
        : AccountDelModalTypes.CONFIRM_DELETION_MODAL;

    setCurrentModal(modalType);
  };

  useEffect(() => {
    dispatch(getCaregiverById(Number(id)));
  }, [dispatch, id]);

  if (!caregiver || isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Box display="flex" flexDirection="column" bgcolor={lightBackground1} p={2}>
      <Box>
        <Button
          variant="text"
          color="secondary"
          onClick={handleBackClick}
          data-testid="back-button"
        >
          Back
        </Button>
      </Box>
      <Box py={2} display="flex" justifyContent="space-between">
        <Box>
          <Typography sx={headingStyle}>{caregiver?.combined_name}</Typography>
          <Chip label={getFacilityRoleById(caregiver.facility_role_id)} />
        </Box>
        <Box display="flex" gap={2}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleChangeRoleClick}
            data-testid="change-role-button"
          >
            Change role
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={handleDeleteUserClick}
            data-testid="delete-user-button"
          >
            Delete user
          </Button>
        </Box>
      </Box>
      <Box>
        <Box display="flex" alignItems="baseline" pb={2}>
          <Typography sx={headLineStyle} pr={2}>
            Contact details
          </Typography>
          <Button
            type="submit"
            variant="text"
            color="secondary"
            onClick={isEditing ? handleSaveClick : handleEditClick}
            sx={{ minWidth: 'auto', width: '80px' }}
          >
            {isEditing ? 'Save' : 'Edit'}
          </Button>
          {isEditing && (
            <Button
              variant="text"
              color="secondary"
              onClick={() => {
                setIsEditing(false);
              }}
              sx={{ minWidth: 'auto', width: '80px' }}
            >
              Cancel
            </Button>
          )}
        </Box>

        <Typography sx={headLineStyle}>Email</Typography>
        <Typography sx={contentStyle}>{caregiver?.email}</Typography>

        <Typography sx={headLineStyle}>Phone</Typography>
        {isEditing ? (
          <Formik
            innerRef={formikRef}
            initialValues={{
              phone: caregiver.phone || '',
            }}
            validationSchema={validationSchema}
            onSubmit={() => {}}
          >
            {({ handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <Box pb={1} sx={{ width: '50%' }}>
                  <Field
                    name="phone"
                    format="(###) ###-####"
                    placeholder="(123)-456-7890"
                    component={MaskedInput}
                    variant="standard"
                    size="small"
                    sx={{
                      fontSize: 18,
                      fontWeight: 'bold',
                      mb: 1,
                      color: 'red',
                    }}
                  />
                </Box>
              </Form>
            )}
          </Formik>
        ) : (
          <>
            <Typography sx={contentStyle}>
              {caregiver?.phone || 'N/A'}
            </Typography>
          </>
        )}
      </Box>

      {isChangeRoleModalOpen && (
        <ChangeRoleModal
          onClose={() => setChangeRoleModalOpen(false)}
          onSubmit={handleRoleChangeSubmit}
          initialValues={{ role: caregiver.facility_role_id }}
        />
      )}

      {currentModal && dashboardUserInfo && (
        <DeleteAccountModals
          currentModal={currentModal}
          handlers={handlers}
          dashboardUserInfo={dashboardUserInfo}
          isOtherUser={!isSameCustomer}
        />
      )}
      {isUserDeletedModalOpen && (
        <UserDeletedModal
          heading={isSameCustomer ? 'Delete account' : 'Delete user'}
          modalTitle={isSameCustomer ? 'Delete account' : 'User deleted'}
          modalContent={isSameCustomer}
          onClose={handleCloseUserDeletedModal}
        />
      )}
    </Box>
  );
};

export default CaregiverProfile;

import { Avatar, Box, Popover, Typography } from '@mui/material';
import React from 'react';
import { topBarBg } from '../../../mainTheme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/pro-light-svg-icons';
import { DashboardUser } from '../../../store/dashboardUser/dashboardUserTypes';

const textStyle = {
  display: 'flex',
  height: '32px',
  padding: '6px 76px 6px 16px',
  alignItems: 'center',
  alignSelf: 'stretch',
  color: 'var(--Font-F2---Title, #2A2A30)',
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '20px',
  letterSpacing: '0.2px',
};

interface CustomerCareTopbarProps {
  handleClickProfile: (event: React.MouseEvent<HTMLElement>) => void;
  profileAnchorEl: HTMLElement | null;
  handleClose: () => void;
  open: boolean;
  handleSettingsClick: () => void;
  handleLogout: () => void;
  dashboardUserInfo: DashboardUser | null;
  currentTitle: string;
  currentDescription: string;
}

const CustomerCareTopbar: React.FC<CustomerCareTopbarProps> = ({
  handleClickProfile,
  profileAnchorEl,
  handleClose,
  open,
  handleSettingsClick,
  handleLogout,
  dashboardUserInfo,
  currentTitle,
  currentDescription,
}) => {
  return (
    <Box
      bgcolor={topBarBg}
      height="72px"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      position="fixed"
      top={0}
      left={0}
      right={0}
      zIndex={1000}
    >
      <Box ml={32}>
        <Typography
          sx={{
            color: 'var(--Text100, #1B1B1B)',
            fontSize: '22px',
            fontWeight: 500,
            lineHeight: '30px',
            letterSpacing: '0.36px',
          }}
        >
          {currentTitle}
        </Typography>
        <Typography
          sx={{
            color: 'var(--Grey100, #7F7F7F)',
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '20px',
            letterSpacing: '0.2px',
          }}
        >
          {currentDescription}
        </Typography>
      </Box>
      <Box p={2} display="flex">
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            border: '1px solid rgba(255, 255, 255, 0.3)',
            borderRadius: '4px',
            padding: '4px 8px',
          }}
          onClick={handleClickProfile}
        >
          <Avatar
            sx={{
              width: 32,
              height: 32,
              mr: 1,
              bgcolor: '#555',
            }}
          >
            {dashboardUserInfo?.first_name?.charAt(0) ?? 'U'}
          </Avatar>

          <Typography
            sx={{
              mr: 1,
              color: 'var(--Text100, #1B1B1B)',
              fontSize: '18px',
              fontWeight: 500,
              lineHeight: '24px',
              letterSpacing: '0.2px',
            }}
          >
            {`${dashboardUserInfo?.first_name ?? ''} ${
              dashboardUserInfo?.last_name ?? ''
            }`.trim()}
          </Typography>
          <FontAwesomeIcon
            icon={faAngleDown}
            style={{ color: '#737373', fontSize: '28px' }}
          />
        </Box>
        <Popover
          id="simple-popover"
          open={open}
          anchorEl={profileAnchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          PaperProps={{
            style: {
              borderRadius: '8px',
              boxShadow:
                '0px 1px 4px 0px rgba(0, 0, 0, 0.06), 0px 6px 14px 0px rgba(0, 0, 0, 0.08), 0px 4px 10px 0px rgba(0, 0, 0, 0.04)',
            },
          }}
        >
          <Box p={2} display="flex" flexDirection="column" borderRadius="1px">
            <Typography sx={textStyle}>Profile</Typography>
            <Box
              onClick={handleSettingsClick}
              sx={{
                ...textStyle,
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
              }}
            >
              User settings
            </Box>
            <Box
              onClick={handleLogout}
              sx={{
                ...textStyle,
                color: 'var(--Font-F2---Title, #865E9C)',
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
              }}
            >
              Log out
            </Box>
          </Box>
        </Popover>
      </Box>
    </Box>
  );
};

export default CustomerCareTopbar;

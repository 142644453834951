import { Box } from '@mui/material';
import React from 'react';
import ComingSoonCard from '../../../Dashboard/detailPanel/ComingSoonCard ';

const ActionsTab = () => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" p={5}>
      <ComingSoonCard />
    </Box>
  );
};

export default ActionsTab;

import { Reducer } from 'redux';
import {
  MQTTAction,
  MQTTConnectionStore,
  MQTTStoreActionTypes,
} from './mqttTypes';

const initialState: MQTTConnectionStore = {
  connected: false,
};

const mqttReducer: Reducer<MQTTConnectionStore, MQTTAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case MQTTStoreActionTypes.MQTT_CONNECTED: {
      console.log('setting connected = true');
      let newState = {
        ...state,
        connected: true,
      };
      return newState;
    }
    default:
      return state;
  }
};

export default mqttReducer;

import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, Tabs, Tab } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useHistory, useParams } from 'react-router-dom';
import HubDetailsTab from './tabs/HubDetailsTab';
import HubHistoryTab from './tabs/HubHistoryTab';
import SeamDetectionImagesTab from './tabs/SeamDetectionImagesTab';
import ActionsTab from './tabs/ActionsTab';
import HubNotesTab from './tabs/HubNotesTab';
import {
  faCircleExclamation,
  faTriangleExclamation,
  faCircleCheck,
} from '@fortawesome/pro-solid-svg-icons';
import StatusChip from '../../Shared/StatusChip';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { fetchDeviceById } from '../../../store/devices/actionCreators';
import LoadingSpinner from '../../Shared/LoadingSpinner';
import { Status } from '../../../shared/constants';
import { capitalizeFirstLetter } from '../../../shared/utils';

const StatusConfig = {
  [Status.Online]: {
    icon: faCircleCheck,
    color: '#2D9D78',
    text: Status.Online,
    backgroundColor: 'var(--Semantics-S2---Success-Bg, #ECF8F0)',
  },
  [Status.Offline]: {
    icon: faTriangleExclamation,
    color: '#E94242',
    text: Status.Offline,
    backgroundColor: 'var(--Semantics-S11---Error-Bg, #FCF0EE)',
  },
  [Status.Unassigned]: {
    icon: faCircleExclamation,
    color: '#FCCD32',
    text: Status.Unassigned,
    backgroundColor: '#FFFAEB',
  },
} as const;

const getStatusConfig = (status: string | undefined, isUnassigned: boolean) => {
  if (isUnassigned) {
    return StatusConfig[Status.Unassigned];
  }
  if (status) {
    const normalizedStatus =
      status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
    return (
      StatusConfig[normalizedStatus as keyof typeof StatusConfig] ||
      StatusConfig[Status.Offline]
    );
  }
  // Default to offline if no status
  return StatusConfig[Status.Offline];
};

const CareHubDetails = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();

  const { selectedDevice, isLoading } = useSelector(
    (state: RootState) => state.devices
  );

  const isUnassigned = selectedDevice?.customer_id === null;
  const statusConfig = getStatusConfig(
    selectedDevice?.device_status,
    isUnassigned
  );

  useEffect(() => {
    if (id) {
      dispatch(fetchDeviceById(Number(id)));
    }
  }, [id, dispatch]);

  const tabs = [
    {
      label: 'Hub details',
      component: () => <HubDetailsTab selectedDevice={selectedDevice} />,
    },
    {
      label: 'Hub history',
      component: HubHistoryTab,
    },
    {
      label: 'Seam detection images',
      component: SeamDetectionImagesTab,
    },
    {
      label: 'Actions',
      component: ActionsTab,
    },
    {
      label: 'Hub notes',
      component: HubNotesTab,
    },
  ];

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const TabPanel = (props: {
    children?: React.ReactNode;
    index: number;
    value: number;
  }) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`hub-details-tabpanel-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </div>
    );
  };

  if (isLoading || !selectedDevice) {
    return <LoadingSpinner />;
  }

  return (
    <Box sx={{ p: 2 }}>
      <Button
        startIcon={<ArrowBackIcon />}
        onClick={() => {
          history.goBack();
        }}
        sx={{
          border: '1px solid #EFEFF1',
          borderRadius: '10px',
          width: '60px',
          height: '40px',
          '&:hover': {
            border: '1px solid #EFEFF1',
          },
        }}
      >
        Back
      </Button>
      <Box py={2} display="flex" flexDirection="column" gap="8px">
        <Box display="flex" justifyContent="space-between">
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '22px',
              lineHeight: '30px',
              letterSpacing: '0.36px',
              color: isUnassigned ? '#000000' : '#865E9C',
            }}
          >
            {/* TODO : hyperlink the name if the profile view and url is available to develop */}
            {isUnassigned
              ? 'Unassigned'
              : selectedDevice?.first_name || selectedDevice?.last_name
              ? `${selectedDevice?.first_name} ${selectedDevice?.last_name}`
              : 'N/A'}
          </Typography>
          <StatusChip
            icon={statusConfig.icon}
            iconColor={statusConfig.color}
            labelText={statusConfig.text}
            conditionalValue={true}
            backgroundColor={statusConfig.backgroundColor}
          />
        </Box>
        {!isUnassigned && (
          <>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: '14px',
                lineHeight: '20px',
                letterSpacing: '0.2px',
                color: '#585863',
              }}
            >
              {capitalizeFirstLetter(selectedDevice?.gender)} · Age{' '}
              {selectedDevice?.age || 'N/A'}
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: '14px',
                lineHeight: '20px',
                letterSpacing: '0.2px',
                color: '#865E9C',
              }}
            >
              {selectedDevice?.email || 'N/A'} ·{' '}
              {selectedDevice?.phone || 'N/A'}
            </Typography>
          </>
        )}
        {isUnassigned && (
          <Button
            variant="contained"
            color="secondary"
            sx={{
              marginTop: 2,
              width: '132px',
              height: '40px',
            }}
          >
            Assign Hub
          </Button>
        )}
      </Box>

      <Box sx={{ borderBottom: 1, borderColor: '#EFEFF1' }}>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          aria-label="hub details tabs"
          variant="scrollable"
          scrollButtons="auto"
          sx={{
            '& .MuiTab-root': {
              fontWeight: 700,
              fontSize: '14px',
              lineHeight: '20px',
              letterSpacing: '0.2px',
              color: '#585863',
              textTransform: 'none',
            },
            '& .Mui-selected': {
              color: '#2A2A30 !important',
            },
            '& .MuiTabs-indicator': {
              backgroundColor: '#6BCABA',
            },
          }}
        >
          {tabs.map((tab, index) => (
            <Tab key={index} label={tab.label} />
          ))}
        </Tabs>
      </Box>
      {tabs.map((tab, index) => (
        <TabPanel key={index} value={selectedTab} index={index}>
          <tab.component />
        </TabPanel>
      ))}
    </Box>
  );
};

export default CareHubDetails;

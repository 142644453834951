import React from 'react';
import { styled, Box, TablePaginationProps, Button } from '@mui/material';
import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  gridPageSizeSelector,
  gridRowCountSelector,
  GridRowsProp,
  useGridApiContext,
  useGridSelector,
  GridColDef,
} from '@mui/x-data-grid';
import MuiPagination from '@mui/material/Pagination';

// Styled DataGrid
const StyledDataGrid = styled(DataGrid)({
  borderRadius: '16px',
  overflow: 'hidden',
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: '#F6F6F9',
    color: '#2A2A30',
  },
  '& .MuiDataGrid-cell': {
    color: '#2A2A30',
  },
  // Style for bolding the sorted column header
  '& .MuiDataGrid-columnHeader--sorted .MuiDataGrid-columnHeaderTitle': {
    fontWeight: 700,
    fontSize: '18px',
  },
});

function CustomPagination(props: any) {
  const apiRef = useGridApiContext();

  const handlePageChange = (_event: any, newPage: number) => {
    apiRef.current.setPage(newPage);
  };

  return <Pagination {...props} onPageChange={handlePageChange} />;
}

function Pagination({
  className,
}: Pick<TablePaginationProps, 'page' | 'onPageChange' | 'className'>) {
  const apiRef = useGridApiContext();

  const handlePageChange = (_event: any, newPage: number) => {
    apiRef.current.setPage(newPage);
  };

  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  const pageSize = useGridSelector(apiRef, gridPageSizeSelector);
  const rowCount = useGridSelector(apiRef, gridRowCountSelector);
  const currentPage = useGridSelector(apiRef, gridPageSelector);
  const currentPageSize = pageSize ?? 10;

  // Calculate the start and end rows for the current page
  const startRow = currentPage * currentPageSize + 1;
  const endRow = Math.min((currentPage + 1) * currentPageSize, rowCount);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
      }}
    >
      <Box sx={{ marginLeft: '16px' }}>
        {`${startRow}–${endRow} of ${rowCount} results`}
      </Box>

      {/* Custom Pagination  */}
      <Box
        sx={{
          border: '1px solid #D4D4D9',
          borderRadius: '16px',
        }}
        mr={2}
      >
        <MuiPagination
          color="secondary"
          className={className}
          count={pageCount}
          page={currentPage + 1}
          onChange={(_, newPage) => {
            handlePageChange(null, newPage - 1); // Pass `null` for the event, as it's not required
          }}
          sx={{
            '& .MuiPaginationItem-root': {
              borderRadius: '8px',
            },
          }}
        />
      </Box>
    </Box>
  );
}

// Reusable DashboardDetailsTable Component
interface DataGridTableProps {
  rows: GridRowsProp;
  columns: GridColDef[];
  height?: string;
  maxHeight?: string;
  pageSize?: number;
  loading?: boolean;
  minHeight?: string;
  hideFooter?: boolean;
  onViewAll?: () => void;
  defaultSortField: string;
  defaultSortDirection: 'asc' | 'desc';
  hideViewAllButton?: boolean;
}

const CustomerCareDTable: React.FC<DataGridTableProps> = ({
  rows,
  columns,
  height = '100%',
  maxHeight = '64vh',
  pageSize,
  loading = false,
  minHeight,
  hideFooter,
  onViewAll,
  defaultSortField,
  defaultSortDirection,
  hideViewAllButton,
}) => {
  // Add sortable property to the columns you want to be sortable
  const columnsWithSorting = columns.map((col) => ({
    ...col,
    sortable: true,
  }));

  return (
    <Box height={height} maxHeight={maxHeight} py={2} minHeight={minHeight}>
      <StyledDataGrid
        rows={rows}
        columns={columnsWithSorting}
        hideFooterSelectedRowCount
        loading={loading}
        slots={{
          pagination: CustomPagination,
        }}
        initialState={{
          pagination: {
            paginationModel: { pageSize, page: 0 },
          },
          sorting: {
            sortModel: [
              {
                field: defaultSortField,
                sort: defaultSortDirection,
              },
            ],
          },
        }}
        hideFooter={hideFooter}
      />
      {!hideViewAllButton && (
        <Button
          sx={{ mt: 1 }}
          variant="outlined"
          color="secondary"
          onClick={onViewAll}
        >
          View all
        </Button>
      )}
    </Box>
  );
};

export default CustomerCareDTable;

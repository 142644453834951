import React from 'react';
import { Box, Typography } from '@mui/material';
interface DashboardTableLegendProps {
  tableLegendData: { color: string; name: string }[];
}

const DashboardTableLegend: React.FC<DashboardTableLegendProps> = ({
  tableLegendData,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        gap: 4,
        fontSize: '0.75rem',
      }}
    >
      {tableLegendData?.map((entry: any, index: number) => (
        <Box
          key={`legend-${index}`}
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
          }}
        >
          <Box
            sx={{
              width: 12,
              height: 12,
              borderRadius: '50%',
              backgroundColor: entry?.color,
            }}
          />
          <Typography
            sx={{
              color: '#585863',
              fontSize: '14px',
              fontWeight: 500,
              lineHeight: '16px',
            }}
          >
            {entry?.name}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default DashboardTableLegend;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { UserRoles } from '../../shared/constants';
import LoadingSpinner from '../Shared/LoadingSpinner';
import { isNull } from 'lodash';

import { getFacilityRoleById, getPrivilegeById } from '../../shared/utils';
import { Helmet } from 'react-helmet';
import logo from '../../images/omcareBigLogo.png';
import CustomerCareDashboard from './CustomerCareDashboard';
import FacilityAdminDashboard from './FacilityAdminDashboard';
import { getDashboardUser } from '../../store/dashboardUser/actionCreators';

const stateSelector = (state: RootState) => {
  const { dashboardUser } = state;
  const { dashboardUserInfo } = dashboardUser;

  return {
    dashboardUserInfo,
    isLoading: dashboardUser.isLoading,
  };
};

const HomePage = () => {
  const dispatch = useDispatch();
  const { dashboardUserInfo, isLoading } = useSelector(stateSelector);

  useEffect(() => {
    if (isNull(dashboardUserInfo)) {
      dispatch(getDashboardUser());
    }
  }, [dashboardUserInfo, dispatch]);

  if (isLoading || isNull(dashboardUserInfo)) {
    return <LoadingSpinner />;
  }
  // Check for Customer Care privilege
  if (dashboardUserInfo?.privilege_id) {
    const userRole = getPrivilegeById(
      dashboardUserInfo.privilege_id
    ) as UserRoles;
    if (userRole === UserRoles.CUSTOMER_CARE) {
      return <CustomerCareDashboard />;
    }
  }

  if (
    // Temporary restrict screen for non-facility users
    isNull(dashboardUserInfo.facility_name) ||
    (dashboardUserInfo.facility_name &&
      !['Facility admin', 'Scheduler'].includes(
        getFacilityRoleById(dashboardUserInfo.facility_role_id ?? 0)
      ))
  ) {
    return (
      <div style={{ textAlign: 'center' }}>
        <Helmet>
          <title>Ōmcare - Dashboard</title>
        </Helmet>
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
        </header>
      </div>
    );
  }
  return <FacilityAdminDashboard />;
};

export default HomePage;

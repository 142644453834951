import { RootState } from '../../store';

export interface MQTTConnectionStore {
  connected: boolean;
}

export interface MQTTStore {
  getState: () => RootState;
  dispatch: any;
  host?: string;
}

interface MQTTStoreActionPayload {
  host: string;
  token: string;
  topic: string;
  msg: any;
}

export interface MQTTStoreAction {
  type: MQTTStoreActionTypes;
  payload: MQTTStoreActionPayload;
}

export interface MQTTConnectionEvent {
  clientId: string;
  timestamp: number;
  eventType: 'connected' | 'disconnected';
  sessionIdentifier: string;
  principalIdentifier: string;
  ipAddress: string;
  versionNumber: number;
}

export enum MQTTStoreActionTypes {
  MQTT_CONNECTED = 'MQTT_CONNECTED',
  MQTT_CONNECT = 'MQTT_CONNECT',
  MQTT_DISCONNECT = 'MQTT_DISCONNECT',
  MQTT_DISCONNECTED = 'MQTT_DISCONNECTED',
  MQTT_PUBLISH = 'MQTT_PUBLISH',
}

export type MQTTConnect = {
  type: MQTTStoreActionTypes.MQTT_CONNECT;
  payload: { host: string; token: string };
};

export type MQTTConnected = {
  type: MQTTStoreActionTypes.MQTT_CONNECTED;
};

export type MQTTDisconnect = {
  type: MQTTStoreActionTypes.MQTT_DISCONNECT;
};

export type MQTTDisconnected = {
  type: MQTTStoreActionTypes.MQTT_DISCONNECTED;
};

export type MQTTPublish = {
  type: MQTTStoreActionTypes.MQTT_PUBLISH;
  payload: { topic: string; msg: any };
};

export type MQTTAction =
  | MQTTConnect
  | MQTTConnected
  | MQTTDisconnect
  | MQTTDisconnected
  | MQTTPublish;

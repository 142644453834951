import axios from 'axios';
import { toast } from 'react-toastify';
import { Dispatch } from 'redux';
import { restHost } from '../../apiConfig';
import { formatUrl, getCookie } from '../../shared/utils';
import {
  OverviewStatsResponse,
  OverviewStatsActionTypes,
} from './overviewStatsTypes';

export const fetchStatsSuccess = (data: OverviewStatsResponse) => ({
  type: OverviewStatsActionTypes.FETCH_STATS_SUCCESS,
  payload: data,
});

export const fetchStatsFailure = (error: any) => ({
  type: OverviewStatsActionTypes.FETCH_STATS_FAILURE,
  payload: error,
});

export const setLoading = (isLoading: boolean) => ({
  type: OverviewStatsActionTypes.SET_LOADING,
  payload: isLoading,
});

export const fetchOverviewStats = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await axios.get(
        formatUrl(restHost, 'eng/overview_stats'),
        {
          headers: {
            Authorization: `Bearer ${getCookie('access_token')}`,
          },
        }
      );

      dispatch(fetchStatsSuccess(response.data));
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.message || 'Failed to fetch overview stats';
      toast.error(errorMessage);
      dispatch(fetchStatsFailure(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

interface StatsCommon {
  total: number;
}

interface HubStats extends StatsCommon {
  online: number;
  offline: number;
  unassigned: number;
}

interface AdherenceStats {
  non_adherent: number;
  unknown: number;
  adherent: number;
}

interface SoftwareStats {
  up_to_date: number;
  need_updating: number;
  unknown: number;
}

interface IssuesStats extends StatsCommon {
  high_priority: number;
  low_priority: number;
}

interface PatientSurveyStats {
  very_unhappy: number;
  somewhat_unhappy: number;
  somewhat_happy: number;
  very_happy: number;
}

interface StatsCategory {
  hubs: HubStats;
  adherence: AdherenceStats;
  software: SoftwareStats;
  issues: IssuesStats;
  patient_survey: PatientSurveyStats;
}

export interface OverviewStatsResponse {
  stable_channel_software_version: string;
  facilities: StatsCategory;
  consumers: StatsCategory;
  all: StatsCategory;
}

export interface OverviewStatsStore {
  stats: OverviewStatsResponse | null;
  error: any | null;
  isLoading: boolean;
}

export enum OverviewStatsActionTypes {
  FETCH_STATS_SUCCESS = 'FETCH_STATS_SUCCESS',
  FETCH_STATS_FAILURE = 'FETCH_STATS_FAILURE',
  SET_LOADING = 'SET_LOADING',
}

export type OverviewStatsAction =
  | {
      type: OverviewStatsActionTypes.FETCH_STATS_SUCCESS;
      payload: OverviewStatsResponse;
    }
  | { type: OverviewStatsActionTypes.FETCH_STATS_FAILURE; payload: any }
  | { type: OverviewStatsActionTypes.SET_LOADING; payload: boolean };

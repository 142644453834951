import React, { useEffect, useState, useCallback } from 'react';
import { Drawer, List, Typography, Box, Chip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useHistory, useLocation } from 'react-router-dom';
import SidebarHeader from './components/SidebarHeader';
import SidebarMenuItem from './components/SidebarMenuItem';
import UIToggleSwitch from '../UIToggleSwitch/UIToggleSwitch';
import { RootState } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import { isNull } from 'lodash';
import { getDashboardUser } from '../../store/dashboardUser/actionCreators';
import { getFacilityRoleById, getPrivilegeById } from '../utils';
import { UserRoles } from '../constants';
import LoadingSpinner from '../../components/Shared/LoadingSpinner';
import {
  bottomMenuConfig,
  mainMenuConfig,
} from './components/SidebarMenuConfig';
import DataSyncStatus from './components/DataSyncStatus';

const drawerWidth = 240;
const collapsedWidth = 64;

const stateSelector = (state: RootState) => {
  const { dashboardUser } = state;
  const { dashboardUserInfo } = dashboardUser;
  return {
    dashboardUserInfo,
    isLoading: dashboardUser.isLoading,
  };
};

const Sidebar: React.FC = () => {
  const { dashboardUserInfo, isLoading } = useSelector(stateSelector);
  const dispatch = useDispatch();
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const currentRole = dashboardUserInfo?.facility_role_id
    ? (getFacilityRoleById(
        dashboardUserInfo.facility_role_id ?? 0
      ) as UserRoles)
    : (getPrivilegeById(dashboardUserInfo?.privilege_id ?? 0) as UserRoles);

  const [open, setOpen] = useState(true);
  const [selectedMenu, setSelectedMenu] = useState<string>(location.pathname);

  const handleDrawerToggle = useCallback(() => {
    setOpen((prevOpen) => !prevOpen);
  }, []);

  useEffect(() => {
    const unlisten = history.listen((location) => {
      setSelectedMenu(location.pathname);
    });
    return () => {
      unlisten();
    };
  }, [history]);

  useEffect(() => {
    if (isNull(dashboardUserInfo)) {
      dispatch(getDashboardUser());
    }
  }, [dashboardUserInfo, dispatch]);

  const menuConfig =
    mainMenuConfig[currentRole as keyof typeof mainMenuConfig] ||
    mainMenuConfig[UserRoles.FACILITY_ADMIN];
  const { heading, items } = menuConfig;

  return (
    <Drawer
      variant="permanent"
      open={open}
      sx={{
        width: open ? drawerWidth : collapsedWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: open ? drawerWidth : collapsedWidth,
          boxSizing: 'border-box',
          transition: theme.transitions.create(['width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        },
      }}
    >
      <div>
        <SidebarHeader open={open} handleDrawerToggle={handleDrawerToggle} />
        {!isLoading ? (
          <List>
            {items.slice(0, menuConfig.topSlice).map((item) => (
              <Box px="16px" key={item.text}>
                <SidebarMenuItem
                  icon={item.icon}
                  text={item.text}
                  to={item.to}
                  open={open}
                  selected={
                    item.to === '/'
                      ? selectedMenu === item.to
                      : selectedMenu.startsWith(item.to)
                  }
                />
              </Box>
            ))}

            {open && (
              <Typography variant="subtitle1" sx={{ margin: theme.spacing(2) }}>
                {heading}
              </Typography>
            )}

            <List component="div" disablePadding>
              {items.slice(menuConfig.topSlice).map((item) => (
                <Box px="16px" key={item.text}>
                  <SidebarMenuItem
                    icon={item.icon}
                    text={item.text}
                    to={item.to}
                    open={open}
                    selected={location.pathname.startsWith(item.to)}
                  />
                </Box>
              ))}
            </List>
          </List>
        ) : (
          <LoadingSpinner />
        )}
      </div>
      <Box p={2}>
        {bottomMenuConfig.visibleFor.includes(currentRole) && (
          <Box mb={2} display="flex" alignItems="center">
            <SidebarMenuItem
              icon={bottomMenuConfig.icon}
              text={bottomMenuConfig.text}
              to={bottomMenuConfig.to}
              open={open}
              selected={location.pathname.startsWith(bottomMenuConfig.to)}
            />
            <Chip
              label={bottomMenuConfig.alertCount}
              sx={{
                backgroundColor: '#FCCD32',
              }}
            />
          </Box>
        )}
        <DataSyncStatus currentRole={currentRole} />
        <UIToggleSwitch />
      </Box>
    </Drawer>
  );
};

export default React.memo(Sidebar);

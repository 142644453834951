import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ReactComponent as NoDataCardIcon } from '../../../images/icons/no_data_card_icon.svg';

const NoDataCard = () => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box sx={{ mb: 2, color: 'text.secondary' }}>
        <NoDataCardIcon />
      </Box>
      <Typography
        variant="h6"
        gutterBottom
        sx={{
          color: 'var(--Font-F1---Heading, #250E62)',
          fontSize: '18px',
          fontWeight: 500,
          lineHeight: '24px',
          letterSpacing: '0.2px',
        }}
      >
        No Data Available
      </Typography>
      <Typography
        variant="body2"
        sx={{
          color: 'var(--Font-F3---Body, #585863)',
          fontSize: '14px',
          fontWeight: 500,
          lineHeight: '20px',
          letterSpacing: '0.2px',
        }}
      >
        There is no data available for this selection at the moment.
      </Typography>
    </Box>
  );
};

export default NoDataCard;

import MaterialTable, { Column, Options } from '@material-table/core';
import { Button, Container, Typography } from '@mui/material';
import isNull from 'lodash/isNull';
import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { sortByFullName, TableOptions } from '../../shared/tableUtils';
import { RootState } from '../../store';
import {
  createTechnicalSupportUsers,
  deleteUser,
  getCustomerList,
  setCreateUserSuccessful,
  setDeleteUserSuccessful,
} from '../../store/customers/actionCreators';
import { Owner } from '../../store/customers/customerTypes';
import { HeaderWrapper } from '../Shared/HeaderWrapper';
import LoadingSpinner from '../Shared/LoadingSpinner';
import { Wrapper } from '../Shared/Wrapper';
import DeleteIcon from '@mui/icons-material/Delete';
import { DashboardUser } from '../../store/dashboardUser/dashboardUserTypes';
import NewModal from '../Shared/NewModal';
import CreateNewCustomerForm, {
  NewCustomerFormValues,
} from './CreateNewCustomerForm';
import { FormikProps } from 'formik';
import DeleteUserModal from '../Caregivers/components/DeleteUserModal';

const Engineers = () => {
  const {
    careGivers,
    connected,
    isLoading,
    dashboardUserInfo,
    createUserSuccessful,
    deleteUserSuccessful,
  } = useSelector<
    RootState,
    {
      careGivers: Owner[] | null;
      connected: boolean;
      isLoading: boolean;
      dashboardUserInfo: DashboardUser | null;
      createUserSuccessful: boolean;
      deleteUserSuccessful: boolean;
    }
  >((state) => {
    const { customers, mqtt, dashboardUser } = state;
    const { dashboardUserInfo } = dashboardUser;
    const {
      careGivers,
      isLoading,
      createUserSuccessful,
      deleteUserSuccessful,
    } = customers;
    const { connected } = mqtt;
    return {
      careGivers,
      connected,
      isLoading,
      dashboardUserInfo,
      createUserSuccessful,
      deleteUserSuccessful,
    };
  });

  const dispatch = useDispatch();

  const [isAddCustomerModalOpen, setIsAddEngineerModalOpen] = useState(false);
  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    userId: null as number | null,
  });
  const formikRef = useRef<FormikProps<NewCustomerFormValues>>(null);

  const onSubmit = (values: NewCustomerFormValues) => {
    if (formikRef.current) {
      const submissionData = {
        ...values,
        privilege_id: dashboardUserInfo?.privilege_id,
      };
      dispatch(createTechnicalSupportUsers(submissionData, 'engineer'));
    }
  };

  const handleDelete = (customerId: number) => {
    setDeleteModal({
      isOpen: true,
      userId: customerId,
    });
  };

  const handleCloseModal = () => {
    setDeleteModal({
      isOpen: false,
      userId: null,
    });
  };

  const handleConfirmDelete = () => {
    if (deleteModal.userId) {
      dispatch(deleteUser(deleteModal.userId));
    }
  };

  useEffect(() => {
    if (createUserSuccessful) {
      setIsAddEngineerModalOpen(false);
      dispatch(setCreateUserSuccessful(false));
      dispatch(getCustomerList());
    }
    if (deleteUserSuccessful) {
      handleCloseModal();
      dispatch(setDeleteUserSuccessful(false));
      dispatch(getCustomerList());
    }
  }, [dispatch, createUserSuccessful, deleteUserSuccessful]);

  useEffect(() => {
    if (!careGivers) {
      dispatch(getCustomerList());
    }
  }, [connected, careGivers, dispatch]);

  if (isNull(careGivers) || isLoading) {
    return <LoadingSpinner />;
  }

  const materialTableColumns: Column<Owner>[] = [
    {
      title: 'Name',
      field: 'name',
      customSort: sortByFullName,
    },
    {
      title: 'Username',
      field: 'email',
    },
    {
      title: 'Date of birth',
      field: 'date_of_birth',
      type: 'date',
    },
    {
      title: 'Actions',
      field: 'actions',
      sorting: false,
      render: (rowData) =>
        rowData.customer_id !== dashboardUserInfo?.customer_id && (
          <DeleteIcon
            style={{ cursor: 'pointer' }}
            onClick={() => handleDelete(rowData.customer_id)}
          />
        ),
    },
  ];

  if (isNull(careGivers)) {
    return <LoadingSpinner />;
  }

  return (
    <Container maxWidth="lg">
      <Helmet>
        <title>Ōmcare - Engineers</title>
      </Helmet>
      <Wrapper>
        <HeaderWrapper>
          <Typography variant={'h1'}>Engineers list</Typography>
          <div>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setIsAddEngineerModalOpen(true)}
            >
              Add new
            </Button>
          </div>
        </HeaderWrapper>
        <MaterialTable
          columns={materialTableColumns}
          data={careGivers.sort(sortByFullName)}
          options={{
            idSynonym: 'customer_id',
            pageSize: 150,
            pageSizeOptions: [150, 250, 350],
            ...(TableOptions as Partial<Options<Owner>>),
          }}
        />
      </Wrapper>
      {isAddCustomerModalOpen && (
        <NewModal
          showCloseX
          title="Add new engineer"
          onClose={() => {
            setIsAddEngineerModalOpen(false);
          }}
          actions={{
            primaryAction: {
              onClick: () => {
                if (formikRef.current) {
                  formikRef.current.submitForm();
                }
              },
            },
          }}
          backdropBlur
          isLoading={isLoading}
        >
          <CreateNewCustomerForm formRef={formikRef} onSubmit={onSubmit} />
        </NewModal>
      )}
      {deleteModal.isOpen && (
        <DeleteUserModal
          onClose={handleCloseModal}
          onDelete={handleConfirmDelete}
        />
      )}
    </Container>
  );
};

export default Engineers;

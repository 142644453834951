import {
  Button,
  Container,
  Link,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Table,
  Paper,
  Box,
  Typography,
  Chip,
} from '@mui/material';
import isNull from 'lodash/isNull';
import MaterialTable, { Column, Options } from '@material-table/core';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { TableOptions } from '../../shared/tableUtils';
import { RootState } from '../../store';
import {
  editSoftwareVersion,
  getSoftwareVersions,
} from '../../store/software/actionCreators';
import {
  SoftwareVersion,
  SoftwareVersionRequestV2,
} from '../../store/software/softwareTypes';
import LoadingSpinner from '../Shared/LoadingSpinner';
import { HeaderWrapper } from '../Shared/HeaderWrapper';
import LTSConfirmationDialog from './components/LTSConfirmationDialog';

const stateSelector = (state: RootState) => {
  const { software } = state;
  const { softwareVersions } = software;
  return {
    softwareVersions,
  };
};

function SoftwareVersions() {
  const history = useHistory();

  const columns: Column<SoftwareVersion>[] = [
    { title: 'ID', field: 'id', defaultSort: 'desc' },
    {
      title: 'Version',
      field: 'version',
      render: function RenderVersion(rowData) {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <span>{rowData.version}</span>
            {rowData.is_lts && (
              <Chip
                label="LTS"
                size="small"
                color="secondary"
                sx={{
                  backgroundColor: '#f3e8ff',
                  color: '#6b21a8',
                  height: '20px',
                  '& .MuiChip-label': {
                    fontSize: '0.75rem',
                    padding: '0 8px',
                  },
                }}
              />
            )}
          </Box>
        );
      },
    },
    {
      title: 'Created at',
      field: 'created_at',
      type: 'datetime',
      customSort: (a, b) => Date.parse(a.created_at) - Date.parse(b.created_at),
    },
    {
      title: 'Commit',
      field: '',
      render: function RenderLink(rowData) {
        if (!rowData.commit_url) {
          return null;
        }

        return (
          <Link href={rowData.commit_url.href}>
            {rowData.semver?.build.join('')}
          </Link>
        );
      },
    },
    {
      title: 'Actions',
      field: '',
      sorting: false,
      render: function EditButton(rowData) {
        const redirectToSoftwareVersionDetail = (id: number) => {
          history.push(`/softwareVersion/${id}`);
        };
        return (
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => redirectToSoftwareVersionDetail(rowData.id)}
            >
              Edit
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => handleSetLTS(rowData)}
              disabled={rowData.is_lts}
            >
              Set as LTS
            </Button>
          </Box>
        );
      },
    },
  ];

  const { softwareVersions } = useSelector<
    RootState,
    ReturnType<typeof stateSelector>
  >(stateSelector);

  const dispatch = useDispatch();

  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [selectedVersion, setSelectedVersion] =
    useState<SoftwareVersion | null>(null);

  const handleSetLTS = (version: SoftwareVersion) => {
    setSelectedVersion(version);
    setIsConfirmOpen(true);
  };

  const getCurrentLTSVersion = () => {
    if (!softwareVersions) return null;
    return softwareVersions.find((version: SoftwareVersion) => version.is_lts);
  };

  const confirmLTS = async () => {
    if (!selectedVersion) return;
    const payload: Partial<SoftwareVersionRequestV2> = {
      id: selectedVersion.id,
      version: selectedVersion.version_raw,
      base_version_id: selectedVersion.base_version_id,
      documentation_url: selectedVersion.documentation_url
        ? selectedVersion.documentation_url
        : '',
      full_url: selectedVersion.full_url,
      patch_url: selectedVersion.patch_url
        ? selectedVersion.patch_url
        : undefined,
      software_product_id: 1,
      set_lts_version: true,
    };
    await dispatch(editSoftwareVersion(payload));
    setIsConfirmOpen(false);
  };

  useEffect(() => {
    if (isNull(softwareVersions)) {
      dispatch(getSoftwareVersions());
    }
  }, [softwareVersions, dispatch]);

  // const handleAddNew = () => {
  //   history.push('/softwareVersion/create');
  // };

  if (isNull(softwareVersions)) {
    return <LoadingSpinner />;
  }
  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>Ōmcare - Software Versions</title>
      </Helmet>
      <div style={{ marginBottom: 20 }}>
        <HeaderWrapper>
          {/* <Button variant="contained" color="secondary" onClick={handleAddNew}>
            Add new software version
          </Button> */}
          <Box
            sx={{
              backgroundColor: '#f3e8ff',
              p: 2,
              borderRadius: 1,
              mb: 2,
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <Typography variant="body1" sx={{ fontWeight: 500 }}>
              Current LTS Version:
            </Typography>
            {getCurrentLTSVersion() ? (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Typography variant="body1">
                  {getCurrentLTSVersion()?.version}
                </Typography>
                <Chip
                  label="LTS"
                  size="small"
                  color="secondary"
                  sx={{
                    backgroundColor: '#6b21a8',
                    color: 'white',
                    fontWeight: 500,
                  }}
                />
              </Box>
            ) : (
              <Typography variant="body1" color="text.secondary">
                No LTS version set
              </Typography>
            )}
          </Box>
        </HeaderWrapper>
        <MaterialTable
          detailPanel={(rowData) => {
            return (
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Documentation URL</TableCell>
                      <TableCell>
                        <Link href={rowData.rowData.documentation_url || ''}>
                          {rowData.rowData.documentation_url || ''}
                        </Link>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow
                      key={rowData.rowData.id}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        Full URL
                      </TableCell>
                      <TableCell>
                        <Link href={rowData.rowData.full_url || ''}>
                          {rowData.rowData.full_url || ''}
                        </Link>
                      </TableCell>
                      {/* <TableCell align="right">{row.fat}</TableCell>
                      <TableCell align="right">{row.carbs}</TableCell>
                      <TableCell align="right">{row.protein}</TableCell> */}
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            );
          }}
          columns={columns}
          data={softwareVersions}
          options={{
            pageSize: 25,
            pageSizeOptions: [25, 50, 100],
            ...(TableOptions as Partial<Options<SoftwareVersion>>),
          }}
        />
      </div>
      {isConfirmOpen && (
        <LTSConfirmationDialog
          isConfirmOpen={isConfirmOpen}
          setIsConfirmOpen={setIsConfirmOpen}
          selectedVersion={selectedVersion}
          onConfirm={confirmLTS}
        />
      )}
    </Container>
  );
}

export default SoftwareVersions;

import { Field, Form, Formik, FormikProps } from 'formik';
import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { unassignDevice } from '../../store/devices/actionCreators';
import { setLoadState } from '../../store/software/actionCreators';
import Modal from './Modal';
import { TextInputFormField } from './TextInputFormField';

interface UnassignDeviceProps {
  deviceId: number;
  onSuccess(): void;
  onCancel(): void;
}

const UnassignDevice = (props: UnassignDeviceProps) => {
  const { deviceId, onSuccess, onCancel } = props;

  const dispatch = useDispatch();

  const formikRef = useRef<FormikProps<{ note: string }>>(null);

  const schema = yup.object({
    note: yup
      .string()
      .required('This field is required.')
      .matches(
        /^unassign$/i,
        'This field must only contain the word "unassign".'
      ),
  });

  const onSubmit = () => {
    dispatch(setLoadState(true));
    dispatch(unassignDevice(deviceId));
    dispatch(setLoadState(false));
    onSuccess();
  };

  interface FormValues {
    note: string;
  }

  const initialValues: FormValues = { note: '' };

  return (
    <Modal
      onClose={onCancel}
      actions={{
        primaryAction: {
          onClick: () => {
            formikRef.current && formikRef.current.submitForm();
          },
          text: 'Unassign',
        },
      }}
    >
      <div style={{ marginBottom: 10 }}>
        <span style={{ fontWeight: 'bold' }}>Warning: </span>
        Unassigning an Ōmcare Home Health Hub from an owner will erase all data
        on that device. Please type &quot;unassign&quot; to confirm you want to
        continue.
      </div>
      <Formik
        innerRef={formikRef}
        validationSchema={schema}
        onSubmit={onSubmit}
        initialValues={initialValues}
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Field
              name="note"
              label='Please type "unassign"'
              placeholder="unassign"
              component={TextInputFormField}
              autoComplete="off"
            />
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default UnassignDevice;

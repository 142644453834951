import React, { useEffect } from 'react';
import { RootState } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import { isNull } from 'lodash';
import { getDashboardUser } from '../../store/dashboardUser/actionCreators';
import LoadingSpinner from '../Shared/LoadingSpinner';
import { getFacilityRoleById, getPrivilegeById } from '../../shared/utils';
import { UserRoles } from '../../shared/constants';
import FacilityAdminHubIndex from './components/FacilityAdminHub';
import CustomerHubOverview from './components/CustomerHubOverview';

const stateSelector = (state: RootState) => {
  const { dashboardUser } = state;
  const { dashboardUserInfo, isLoading } = dashboardUser;
  return {
    dashboardUserInfo,
    isLoading,
  };
};

const HubIndex: React.FC = () => {
  const dispatch = useDispatch();

  const { dashboardUserInfo, isLoading: isDashboardUserLoading } = useSelector<
    RootState,
    ReturnType<typeof stateSelector>
  >(stateSelector);

  useEffect(() => {
    if (isNull(dashboardUserInfo)) {
      dispatch(getDashboardUser());
    }
  }, [dashboardUserInfo, dispatch]);

  if (isDashboardUserLoading) {
    return <LoadingSpinner />;
  }

  const currentRole = dashboardUserInfo?.facility_role_id
    ? getFacilityRoleById(dashboardUserInfo.facility_role_id)
    : dashboardUserInfo?.privilege_id
    ? getPrivilegeById(dashboardUserInfo.privilege_id)
    : null;

  if (!currentRole) {
    return <div>Unauthorized: Invalid role</div>;
  }

  const renderHubByRole = () => {
    switch (currentRole) {
      case UserRoles.FACILITY_ADMIN:
        return <FacilityAdminHubIndex />;
      case UserRoles.CUSTOMER_CARE:
        return <CustomerHubOverview />;
      default:
        return <div>Unauthorized: Unknown role</div>;
    }
  };

  return renderHubByRole();
};

export default HubIndex;

import React from 'react';
import { Box, Typography, LinearProgress } from '@mui/material';
import BaseCard from './BaseCard';
import { ReactComponent as SoftwareIcon } from '../../../images/icons/software_icon.svg';

interface SoftwareCardProps {
  isSelected?: boolean;
  onClick?: () => void;
  data: {
    version: string;
    upToDate: number;
    needsUpdate: number;
  };
}

export const SoftwareCard: React.FC<SoftwareCardProps> = ({
  isSelected,
  onClick,
  data,
}) => {
  const total = data.upToDate + data.needsUpdate;
  const isNoData = total === 0;
  const progress = (data.upToDate / total) * 100;

  return (
    <BaseCard
      title="Software"
      isSelected={isSelected}
      onClick={onClick}
      icon={<SoftwareIcon />}
    >
      <Box sx={{ mb: 3 }}>
        <Typography
          sx={{
            color: '#585863',
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '20px',
          }}
        >
          Version: {data.version}
        </Typography>
      </Box>

      <Box sx={{ width: '100%', mb: 2 }}>
        <LinearProgress
          variant="determinate"
          value={progress}
          sx={{
            height: 8,
            borderRadius: 4,
            backgroundColor: isNoData ? '#9999A4' : '#ff1744', // Red for needs update
            '& .MuiLinearProgress-bar': {
              backgroundColor: isNoData ? '#9999A4' : '#4caf50', // Green for up-to-date
              borderRadius: 4,
            },
          }}
        />
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-evenly',
          alignItems: 'center',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Box
            sx={{
              width: 10,
              height: 10,
              borderRadius: '50%',
              bgcolor: '#4caf50',
            }}
          />
          <Typography
            sx={{
              color: '#585863',
              fontSize: '14px',
              fontWeight: 500,
              lineHeight: '16px',
            }}
          >
            up-to-date{' '}
            <span style={{ fontWeight: 'bold', color: '#404040' }}>
              {data.upToDate}
            </span>
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Box
            sx={{
              width: 10,
              height: 10,
              borderRadius: '50%',
              bgcolor: '#ff1744',
            }}
          />
          <Typography
            sx={{
              color: '#585863',
              fontSize: '14px',
              fontWeight: 500,
              lineHeight: '16px',
            }}
          >
            need updating{' '}
            <span style={{ fontWeight: 'bold', color: '#404040' }}>
              {data.needsUpdate}
            </span>{' '}
          </Typography>
        </Box>
      </Box>
    </BaseCard>
  );
};

import axios from 'axios';
import { toast } from 'react-toastify';
import { Dispatch } from 'redux';
import { mqttDisconnect } from '../mqtt/actionCreators';
import { restHost } from '../../apiConfig';
import {
  jsonToFormUrlEncoded,
  setCookie,
  getCookie,
  deleteToken,
  formatUrl,
  redirectToLogin,
} from '../../shared/utils';
import { AUTH_MESSAGES } from '../../shared/constants';
import {
  AccessTokenInterface,
  AuthStoreActionTypes,
  ChangePasswordInterface,
} from './authTypes';

export const apiError = (error: any) => ({
  type: AuthStoreActionTypes.AUTH_API_ERR,
  payload: { error: error },
});

export const setLoadState = (loadState: boolean) => ({
  type: AuthStoreActionTypes.AUTH_SET_LOAD_STATE,
  payload: { isLoading: loadState },
});

export const logOut = (refresh_token: string) => {
  let params = jsonToFormUrlEncoded({ refresh_token: refresh_token });
  return (dispatch: Dispatch) => {
    dispatch(setLoadState(true));
    return axios
      .post(formatUrl(restHost, 'auth/logout'), params, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${getCookie('access_token')}`,
        },
      })
      .catch((err) => {
        console.error(err.response?.data?.message);
      })
      .finally(() => {
        deleteToken();
        dispatch(mqttDisconnect());
        dispatch(setLoadState(false));
      });
  };
};

export const successChangeUserPassword = (
  accessToken: AccessTokenInterface
) => ({
  type: AuthStoreActionTypes.SUCCESS_CHANGE_USER_PASSWORD,
  payload: { accessToken: accessToken },
});

export const changeUserPassword = (data: ChangePasswordInterface) => {
  let params = jsonToFormUrlEncoded(data);
  return (dispatch: Dispatch) => {
    dispatch(setLoadState(true));
    return axios
      .post(formatUrl(restHost, 'auth/changePassword'), params, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${getCookie('access_token')}`,
        },
      })
      .then((res: any) => {
        if (res.response) {
          let message;
          if (
            res.response.status === 400 &&
            res.response.data === 'Incorrect old password.'
          ) {
            message = 'Incorrect current password.';
          } else if (
            res.response.status === 500 ||
            res.response.status === 503
          ) {
            message = 'System down, please try again';
          }
          toast.error(message);
          dispatch(setLoadState(false));
          return;
        }
        dispatch(successChangeUserPassword(res.data));
        toast.success(
          "Password changed successfully. You'll be returned to the login page to log back in",
          {
            autoClose: 3000,
            onClose: () => {
              deleteToken();
            },
          }
        );
      })
      .catch((err) => {
        if (err.response.status === 401) {
          redirectToLogin();
        }
        toast.error('Error encountered while changing password');
        dispatch(apiError(err));
        dispatch(setLoadState(false));
      });
  };
};

export const resetCompleteAccessToken = () => ({
  type: AuthStoreActionTypes.RESET_COMPLETE_ACCESS_TOKEN,
});

export const loginSuccess = (accessToken: AccessTokenInterface) => ({
  type: AuthStoreActionTypes.LOGIN_SUCCESS,
  payload: { accessToken: accessToken },
});

export const loginFailureMessage = (message: string) => ({
  type: AuthStoreActionTypes.LOGIN_FAILURE,
  payload: { message },
});

export const mfaRequired = (
  sessionToken: string,
  username: string,
  message: string,
  mfaPhoneNumber?: string
) => ({
  type: AuthStoreActionTypes.MFA_REQUIRED,
  payload: { sessionToken, username, message, mfaPhoneNumber },
});

export const login = (
  email: string,
  password?: string | null,
  new_password?: string,
  mfaToken?: string,
  sessionToken?: string
) => {
  const params = {
    username: email,
    password,
    new_password,
    mfa_token: mfaToken,
    session_token: sessionToken,
  };
  const url = mfaToken
    ? 'auth/login?mfa=true'
    : new_password
    ? 'auth/login?new_password_challenge=true'
    : 'auth/login';

  return (dispatch: Dispatch) => {
    dispatch(setLoadState(true));
    return axios
      .post(formatUrl(restHost, url), params, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        if (
          res.status === 202 &&
          res.data.challenge === AUTH_MESSAGES.EMAIL_VERIFICATION_REQUIRED
        ) {
          dispatch(
            loginFailureMessage(AUTH_MESSAGES.EMAIL_VERIFICATION_REQUIRED)
          );
          dispatch(setLoadState(false));
          return;
        } else if (
          res.status === 202 &&
          res.data.challenge === AUTH_MESSAGES.NEW_PASSWORD_REQUIRED
        ) {
          dispatch(loginFailureMessage(AUTH_MESSAGES.NEW_PASSWORD_REQUIRED));
          dispatch(setLoadState(false));
          return;
        } else if (
          res.status === 202 &&
          [
            AUTH_MESSAGES.MFA_REQUIRED,
            AUTH_MESSAGES.PHONE_MFA_SETUP_REQUIRED,
          ].includes(res.data.challenge)
        ) {
          const { session_token, challenge, phone_mfa } = res.data;
          dispatch(mfaRequired(session_token, email, challenge, phone_mfa));
          dispatch(setLoadState(false));
          return;
        } else if (
          (res as any).response &&
          (res as any).response.status === 400 &&
          (res as any).response.data === 'Incorrect email or password.'
        ) {
          toast.error((res as any).response.data);
          dispatch(setLoadState(false));
          return;
        } else if (
          (res as any).response &&
          (res as any).response.status === 400 &&
          (res as any).response.data.includes(
            'CodeMismatchException: The MFA code does not match the auth session'
          )
        ) {
          toast.error(
            'The Verification code does not match. Please try again.',
            { autoClose: 3000 }
          );
          dispatch(setLoadState(false));
          return;
        } else if (
          (res as any).response &&
          (res as any).response.status === 400 &&
          (res as any).response.data.includes(
            'NotAuthorizedException: The auth session has expired. Try logging in again.'
          )
        ) {
          toast.error(
            'Session expired. Please request a new authentication code to continue.',
            { autoClose: 3000 }
          );
          dispatch(loginFailureMessage(''));
          dispatch(setLoadState(false));
          return;
        } else if (
          (res as any).response &&
          (res as any).response.status === 500 &&
          (res as any).response.data.includes('Password attempts exceeded')
        ) {
          toast.error('Too many password attempts. Please try again later');
          dispatch(setLoadState(false));
          return;
        }
        dispatch(loginSuccess(res.data));
        setCookie('access_token', res.data.access_token, res.data.expires_in);
        setCookie('id_token', res.data.id_token, res.data.expires_in);
        setCookie('refresh_token', res.data.refresh_token);
        setCookie('token_type', res.data.token_type);
        toast.success('Login successful');
        dispatch(setLoadState(false));
        window.location.href = '/';
      })
      .catch((err) => {
        toast.error('Error encountered during login');
        dispatch(apiError(err));
        dispatch(setLoadState(false));
        dispatch(loginFailureMessage(''));
      });
  };
};

export const resetPasswordRequest = (email: string) => {
  return (dispatch: Dispatch) => {
    dispatch(setLoadState(true));
    return axios
      .get(formatUrl(restHost, 'auth/forgetPasswordReq'), {
        params: { email: email },
      })
      .then((res) => {
        dispatch({
          type: AuthStoreActionTypes.RESET_PASSWORD_REQUEST,
          payload: { email, message: res.data.message },
        });
        toast.success(
          "A password reset email has been sent. If you didn't receive it, please check your spam folder or try again"
        );
        dispatch(setLoadState(false));
      })
      .catch((err) => {
        const message = 'Failed to send password reset request';
        toast.error(message);
        dispatch(apiError(err));
        dispatch(setLoadState(false));
      });
  };
};

export const updateValidationTokenRequestMessage = (
  email: string,
  message: string
) => ({
  type: AuthStoreActionTypes.VALIDATE_TOKEN_REQUEST,
  payload: { email, message },
});

export const validateTokenRequest = (token: string, email: string) => {
  return (dispatch: Dispatch) => {
    dispatch(setLoadState(true));
    return axios
      .get(formatUrl(restHost, 'auth/validateToken'), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch(updateValidationTokenRequestMessage(email, res.data.message));
        dispatch(setLoadState(false));
      })
      .catch(() => {
        const message = AUTH_MESSAGES.FAILED_TOKEN_VALIDATION;
        dispatch(updateValidationTokenRequestMessage(email, message));
        dispatch(apiError(message));
        dispatch(setLoadState(false));
      });
  };
};

export const updateResetPasswordMessage = (email: string, message: string) => ({
  type: AuthStoreActionTypes.RESET_PASSWORD,
  payload: { email, message },
});

export const resetPassword = (
  email: string,
  new_password: string,
  token: string
) => {
  return (dispatch: Dispatch) => {
    dispatch(setLoadState(true));
    return axios
      .post(
        formatUrl(restHost, 'auth/resetPassword'),
        {
          token,
          email,
          new_password,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then((res: any) => {
        if (
          res.response &&
          res.response.status === 400 &&
          [
            'Verification token has expired',
            'Invalid verification token',
          ].includes(res.response.data)
        ) {
          const message = AUTH_MESSAGES.FAILED_TOKEN_VALIDATION;
          dispatch(updateResetPasswordMessage(email, message));
          dispatch(setLoadState(false));
          return;
        } else if (
          res.response &&
          res.response.status === 500 &&
          res.response.data.includes(AUTH_MESSAGES.LIMIT_EXCEEDED)
        ) {
          const message = AUTH_MESSAGES.LIMIT_EXCEEDED;
          dispatch(updateResetPasswordMessage(email, message));
          dispatch(setLoadState(false));
          return;
        }
        dispatch(updateResetPasswordMessage(email, res.data.message));
        toast.success('Password reset completed successfully', {
          autoClose: 3000,
          onClose: () => {
            redirectToLogin();
          },
        });
      })
      .catch((err) => {
        const message = 'Failed to reset password';
        toast.error(message);
        dispatch(apiError(err));
        dispatch(setLoadState(false));
      });
  };
};

export const updateVerifyEmailMessage = (email: string, message: string) => ({
  type: AuthStoreActionTypes.VALIDATE_EMAIL_REQUEST,
  payload: { email, message },
});

export const validateEmailRequest = (token: string, email: string) => {
  return (dispatch: Dispatch) => {
    dispatch(setLoadState(true));
    return axios
      .post(formatUrl(restHost, 'register/verify'), {
        email,
        token,
      })
      .then((response: any) => {
        if (response.data && response.status === 200) {
          dispatch(
            updateVerifyEmailMessage(
              email,
              AUTH_MESSAGES.EMAIL_VERIFICATION_SUCCESS
            )
          );
        } else if (
          response.response &&
          response.response.status === 500 &&
          response.response.data.includes(
            'NotAuthorizedException: User cannot be confirmed.'
          )
        ) {
          dispatch(
            updateVerifyEmailMessage(
              email,
              AUTH_MESSAGES.EMAIL_ALREADY_VERIFIED
            )
          );
        } else {
          throw new Error(response.data.message || 'Unknown error');
        }
        dispatch(setLoadState(false));
      })
      .catch(() => {
        const message = AUTH_MESSAGES.FAILED_TOKEN_VALIDATION;
        dispatch(updateVerifyEmailMessage(email, message));
        dispatch(apiError(message));
        dispatch(setLoadState(false));
      });
  };
};

export const emailVerificationRequest = (email: string) => {
  return (dispatch: Dispatch) => {
    dispatch(setLoadState(true));
    return axios
      .get(formatUrl(restHost, 'register/verificationReq'), {
        params: { email },
      })
      .then((_) => {
        toast.success(
          "A new email to verify your account has been sent. If you didn't receive it, please check your spam folder or try again",
          {
            onClose: () => {
              redirectToLogin();
            },
          }
        );
      })
      .catch((err) => {
        const message = 'Failed to send email verification request';
        toast.error(message);
        dispatch(apiError(err));
        dispatch(setLoadState(false));
      });
  };
};

export const phoneOTPValidation = (phoneNumber: string, valid: boolean) => ({
  type: AuthStoreActionTypes.VALIDATE_PHONE_OTP,
  payload: { phoneNumber, valid },
});

export const requestPhoneOTP = (phoneNumber: string, sessionToken: string) => {
  return (dispatch: Dispatch) => {
    dispatch(setLoadState(true));
    return axios
      .post(formatUrl(restHost, 'auth/phone/otp?action=request'), {
        phone_number: phoneNumber,
        session_token: sessionToken,
      })
      .then((res) => {
        if ((res as any).response && (res as any).response.status === 500) {
          throw new Error('Internal server error');
        }
        toast.success('Confirmation code sent successfully', {
          autoClose: 3000,
        });
        dispatch(setLoadState(false));
      })
      .catch((err) => {
        toast.error('Failed to send phone confirmation code. Try again');
        dispatch(apiError(err));
        dispatch(setLoadState(false));
      });
  };
};

export const validatePhoneOTP = (
  phoneNumber: string,
  otpToken: string,
  sessionToken: string
) => {
  return (dispatch: Dispatch) => {
    dispatch(setLoadState(true));
    return axios
      .post(formatUrl(restHost, 'auth/phone/otp?action=validate'), {
        otp_token: otpToken,
        session_token: sessionToken,
      })
      .then((response) => {
        const { valid } = response.data;
        if (valid) {
          toast.success('Confirmation code validated successfully', {
            autoClose: 3000,
          });
        } else {
          toast.error('The confirmation code is invalid. Try again');
        }
        dispatch(phoneOTPValidation(phoneNumber, valid));
        dispatch(setLoadState(false));
      })
      .catch((err) => {
        toast.error('Failed to validate confirmation code');
        dispatch(apiError(err));
        dispatch(phoneOTPValidation(phoneNumber, false));
        dispatch(setLoadState(false));
      });
  };
};

export const completePhoneMFASetup = (
  username: string,
  mfaToken: string,
  sessionToken: string,
  phoneNumber: string
) => {
  const params = {
    username,
    mfa_token: mfaToken,
    session_token: sessionToken,
    phone_number: phoneNumber,
  };

  return (dispatch: Dispatch) => {
    dispatch(setLoadState(true));
    return axios
      .put(formatUrl(restHost, 'auth/phone/setup_mfa'), params, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        if (
          (res as any).response &&
          (res as any).response.status === 400 &&
          (res as any).response.data.includes(
            'CodeMismatchException: The MFA code does not match the auth session'
          )
        ) {
          toast.error(
            'The email verification code does not match. Please try again.',
            { autoClose: 3000 }
          );
          dispatch(setLoadState(false));
          return;
        } else if (
          (res as any).response &&
          (res as any).response.status === 400 &&
          (res as any).response.data.includes(
            'NotAuthorizedException: The auth session has expired. Try logging in again.'
          )
        ) {
          toast.error(
            'Session expired. Please request a new verification code to continue.',
            { autoClose: 3000 }
          );
          dispatch(setLoadState(false));
          return;
        }
        toast.success('Phone MFA setup completed successfully', {
          autoClose: 3000,
          onClose: () => {
            redirectToLogin();
          },
        });
      })
      .catch((err) => {
        toast.error('Error encountered completing MFA setup');
        dispatch(apiError(err));
        dispatch(setLoadState(false));
      });
  };
};

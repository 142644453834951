import React from 'react';
import {
  faUser,
  faTriangleExclamation,
  faBuildings,
  faBadgeCheck,
  faBell,
  faHeartPulse,
} from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HomeIcon from '../../../images/icons/HomeIcon';
import CareGiverIcon from '../../../images/icons/CareGiverIcon';
import HubIcon from '../../../images/icons/HubIcon';
import { UserRoles } from '../../constants';
import { SvgIconProps } from '@mui/material';
import { ReactComponent as CapsuleIcon } from '../../../images/icons/capsule.svg';
import { ReactComponent as SoftwareIcon } from '../../../images/icons/software_icon.svg';
import { ReactComponent as PharmaciesIcon } from '../../../images/icons/pharmacies_icon.svg';

export interface MenuItem {
  icon: React.ReactElement<SvgIconProps>;
  text: string;
  to: string;
}

export interface BottomMenuItem extends MenuItem {
  visibleFor: UserRoles[];
  alertCount?: number;
}

export const mainMenuConfig = {
  [UserRoles.FACILITY_ADMIN]: {
    heading: 'People',
    topSlice: 2,
    items: [
      { icon: <HomeIcon />, text: 'Home', to: '/' },
      { icon: <HubIcon />, text: 'Hubs', to: '/v2/hubs' },
      {
        icon: <FontAwesomeIcon icon={faUser} size="lg" />,
        text: 'Hub owners',
        to: '/v2/owners',
      },
      { icon: <CareGiverIcon />, text: 'Caregivers', to: '/v2/caregivers' },
    ],
  },
  [UserRoles.CUSTOMER_CARE]: {
    heading: 'Users',
    topSlice: 7,
    items: [
      { icon: <HomeIcon />, text: 'Overview', to: '/' },
      {
        icon: <CapsuleIcon />,
        text: 'Adherence',
        to: '/v2/adherence',
      },
      { icon: <HubIcon />, text: 'Hubs', to: '/v2/hubs' },
      {
        icon: <FontAwesomeIcon icon={faTriangleExclamation} size="lg" />,
        text: 'Issues',
        to: '/v2/issues',
      },
      {
        icon: <FontAwesomeIcon icon={faBadgeCheck} size="lg" />,
        text: 'Patient survey',
        to: '/v2/patient-survey',
      },
      {
        icon: <SoftwareIcon />,
        text: 'Software',
        to: '/v2/software',
      },
      {
        icon: <FontAwesomeIcon icon={faHeartPulse} size="lg" />,
        text: 'Vitals',
        to: '/vitals',
      },
      {
        icon: <CareGiverIcon />,
        text: 'Caregivers',
        to: '/v2/caregiver',
      },
      {
        icon: (
          <FontAwesomeIcon icon={faBuildings} size="lg" flip="horizontal" />
        ),
        text: 'Facilities',
        to: '/v2/facilities',
      },
      {
        icon: <FontAwesomeIcon icon={faUser} size="lg" />,
        text: 'Patients',
        to: '/v2/patients',
      },
      {
        icon: <PharmaciesIcon />,
        text: 'Pharmacies',
        to: '/v2/pharmacies',
      },
    ],
  },
};

export const bottomMenuConfig: BottomMenuItem = {
  icon: <FontAwesomeIcon icon={faBell} size="lg" />,
  text: 'Alerts',
  to: '/alerts',
  visibleFor: [], // currently not used
  alertCount: 4,
};

import { Field, Form, Formik, FormikProps } from 'formik';
import React, { useMemo } from 'react';
import { TextInputFormField } from '../../Shared/TextInputFormField';
import MaskedInput from '../../Shared/MaskedInput';
import SelectFormField from '../../Shared/SelectFormField';
import { US_STATES, GENDER } from '../../../shared/constants';
import * as AnserInputValidation from '../../../shared/inputValidation';
import { Box } from '@mui/material';
import * as yup from 'yup';

const schema = yup.object({
  first_name: yup
    .string()
    .matches(
      /^[A-Za-z]+$/,
      'First name must contain only alphabetical characters'
    )
    .required('First name is required'),
  last_name: yup
    .string()
    .matches(
      /^[A-Za-z]+$/,
      'Last name must contain only alphabetical characters'
    )
    .required('Last name is required'),
  phone: yup.string().required('This field is required'),
  gender_id: yup.number().required('Gender is required').nullable(),
  email: yup
    .string()
    .label('Email')
    .email('Please enter a valid email address')
    .required('This field is required'),
  date_of_birth: yup
    .string()
    .required('This field is required')
    .test('match', 'Please fill in valid date of birth', function (value) {
      return value
        ? AnserInputValidation.validateDateOfBirth(
            value.slice(value.length - 4),
            value.substr(0, 2),
            value.substr(3, 5)
          )
          ? true
          : false
        : false;
    }),

  // Commenting out the field below since it is not mandatory for now

  // med_window_time_sec: yup.string().trim().required('This field is required'),

  address1: yup.string().required('This field is required'),

  city: yup.string().required('This field is required'),

  state: yup.string().required('This field is required'),

  zipcode: yup
    .number()
    .typeError('Invalid zip code')
    .required('This field is required')
    .max(99999, 'Invalid zip code')
    .min(10000, 'Invalid zip code'),
});

export interface HubOwnerFormValues {
  first_name: string;
  last_name: string;
  phone: string;
  gender_id: number | null;
  email: string;
  date_of_birth: string;
  med_window_time_sec?: string;
  address1: string;
  city?: string;
  state?: string;
  zipcode?: number | null;
}

interface AddNewHubOwnerProps {
  formRef: React.RefObject<FormikProps<HubOwnerFormValues>>;
  onSubmit: (values: any) => void;
}

const AddNewHubOwner: React.FC<AddNewHubOwnerProps> = ({
  formRef,
  onSubmit,
}) => {
  const genderData = useMemo(() => {
    return [
      { label: 'Female', value: GENDER.FEMALE },
      { label: 'Male', value: GENDER.MALE },
      { label: 'Non binary', value: GENDER.NON_BINARY },
      { label: 'Decline to say', value: GENDER.DECLINE_TO_SAY },
    ];
  }, []);

  const states_list = useMemo(() => {
    if (US_STATES.length < 0) return [];
    return US_STATES.map((item) => {
      return { label: item.name, value: item.id };
    });
  }, []);

  return (
    <Formik
      innerRef={formRef}
      validationSchema={schema}
      onSubmit={onSubmit}
      initialValues={
        {
          first_name: '',
          last_name: '',
          phone: '',
          gender_id: null,
          email: '',
          date_of_birth: '',
          address1: '',
          city: '',
          state: '',
          zipcode: null,
        } as HubOwnerFormValues
      }
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ handleSubmit }) => (
        <Form onSubmit={handleSubmit} data-testid="add-new-hub-owner-form">
          <Box display="flex" flexDirection="column" gap={2}>
            <Box display="flex" gap={2}>
              <Field
                label="First name"
                name="first_name"
                placeholder="First name"
                component={TextInputFormField}
              />
              <Field
                label="Last name"
                name="last_name"
                placeholder="Last name"
                component={TextInputFormField}
              />
            </Box>
            <Field
              label="Email address"
              name="email"
              placeholder="Enter an email address"
              component={TextInputFormField}
            />
            <Box display="flex" gap={2}>
              <Field
                label="Phone number"
                name="phone"
                format="(###) ###-####"
                placeholder="(123)-456-7890"
                component={MaskedInput}
              />
              <Field
                label="How do they identify"
                name="gender_id"
                placeholder="How do they identify"
                component={SelectFormField}
                options={genderData}
                useNewStyles
              />
            </Box>

            <Field
              label="Date of birth"
              name="date_of_birth"
              format="##/##/####"
              mask={['M', 'M', 'D', 'D', 'Y', 'Y', 'Y', 'Y']}
              placeholder="MM/DD/YYYY"
              component={MaskedInput}
            />
            {/* Commenting out the field below since it is not mandatory for now  */}
            {/* <Field
              label="Med window time"
              name="med_window_time_sec"
              placeholder="Time in seconds"
              component={TextInputFormField}
            /> */}

            <Field
              label="Address"
              name="address1"
              placeholder="1234 Main Street"
              component={TextInputFormField}
            />
            <Field
              label="City"
              name="city"
              placeholder="City"
              component={TextInputFormField}
            />

            <Box display="flex" gap={2}>
              <Field
                label="State"
                name="state"
                placeholder="State"
                component={SelectFormField}
                options={states_list}
                useNewStyles
              />
              <Field
                label="Zip code"
                name="zipcode"
                placeholder="Zip code"
                component={TextInputFormField}
              />
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default AddNewHubOwner;

import React, { useCallback, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { UserRoles } from '../../constants';
import { RootState } from '../../../store';
import { fetchHubOwners } from '../../../store/hubOwner/actionCreators';
import {
  setIsRefreshing,
  setLastRefresh,
} from '../../../store/customers/actionCreators';
import { getDeviceList } from '../../../store/devices/actionCreators';
import { fetchOverviewStats } from '../../../store/overviewStats/actionCreators';
import { toast } from 'react-toastify';

const REFRESH_INTERVAL = 1800000; // 30 minutes

//  Component for displaying the timestamp
const LastUpdatedDisplay: React.FC<{ lastRefreshTime: string | null }> = ({
  lastRefreshTime,
}) => {
  const getFormattedLastUpdate = () => {
    if (!lastRefreshTime) return 'N/A';
    return format(new Date(lastRefreshTime), 'yyyy-MM-dd HH:mm:ss');
  };

  return (
    <Box mb={2}>
      <Typography
        sx={{
          color: '#585863',
          fontSize: '14px',
          fontWeight: 500,
          lineHeight: '20px',
          letterSpacing: '0.2px',
        }}
      >
        Last updated:
      </Typography>
      <Typography
        sx={{
          color: '#585863',
          fontSize: '14px',
          fontWeight: 500,
          lineHeight: '20px',
          letterSpacing: '0.2px',
        }}
      >
        {getFormattedLastUpdate()}
      </Typography>
    </Box>
  );
};

// Main component that handles both refresh logic and display
const DataSyncStatus: React.FC<{ currentRole: UserRoles }> = ({
  currentRole,
}) => {
  const dispatch = useDispatch();
  const { lastRefreshTime } = useSelector(
    (state: RootState) => state.customers
  );

  const refreshData = useCallback(async () => {
    // List of API calls to be refreshed
    const refreshEndpoints = [
      fetchHubOwners,
      getDeviceList,
      fetchOverviewStats,
      // Can add more API calls here depending on the requirement
    ];

    if (currentRole !== UserRoles.CUSTOMER_CARE) return;
    dispatch(setIsRefreshing(true));
    try {
      await Promise.all(
        refreshEndpoints.map((endpoint) => dispatch(endpoint()))
      );
      dispatch(setLastRefresh());
      dispatch(setIsRefreshing(false));
    } catch (error) {
      toast.error('Failed to refresh data', {
        autoClose: 3000,
      });
      dispatch(setIsRefreshing(false));
    }
  }, [currentRole, dispatch]);

  // Initial refresh to get the Time on page load
  useEffect(() => {
    if (currentRole === UserRoles.CUSTOMER_CARE && !lastRefreshTime) {
      refreshData();
    }
  }, [currentRole, lastRefreshTime, refreshData]);

  // Periodic refresh Currently set to 30 minutes
  useEffect(() => {
    if (currentRole !== UserRoles.CUSTOMER_CARE) return;
    const intervalId = setInterval(refreshData, REFRESH_INTERVAL);
    return () => clearInterval(intervalId);
  }, [currentRole, refreshData]);

  if (currentRole !== UserRoles.CUSTOMER_CARE) return null;

  return <LastUpdatedDisplay lastRefreshTime={lastRefreshTime} />;
};

export default DataSyncStatus;

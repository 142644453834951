import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import NewModal from '../../Shared/NewModal';
import { ReactComponent as ContactIcon } from '../../../images/icons/contact_icon.svg';
import { dimGrey } from '../../../mainTheme';

interface OverviewPopoverProps {
  onClose: () => void;
  contactName: string | null;
  contactPhone: string | null;
}

const OverviewPopover: React.FC<OverviewPopoverProps> = ({
  onClose,
  contactName,
  contactPhone,
}) => {
  return (
    <NewModal showCloseX title="Contact" onClose={onClose} backdropBlur>
      <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
        <ContactIcon />
        <Typography sx={{ textAlign: 'center' }} component="span">
          {contactName ? (
            <Typography>{contactName}</Typography>
          ) : (
            <Typography>No contact name available</Typography>
          )}
        </Typography>
        <Typography
          sx={{ color: dimGrey, textAlign: 'center' }}
          component="span"
        >
          {contactPhone ? (
            <Typography>{contactPhone}</Typography>
          ) : (
            <Typography>No contact phone available</Typography>
          )}
        </Typography>
        <Button
          fullWidth
          variant="contained"
          color="secondary"
          onClick={onClose}
        >
          OK
        </Button>
      </Box>
    </NewModal>
  );
};

export default OverviewPopover;

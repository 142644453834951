import { Box, Typography } from '@mui/material';
import React from 'react';
import { parseDateToLocal } from '../../../../shared/utils';
import { Device } from '../../../../store/devices/deviceTypes';

const baseStyle = {
  color: '#2A2A30',
  fontWeight: 700,
  lineHeight: '24px',
  letterSpacing: '0.2px',
  fontSize: '16px',
};

const contentStyle = {
  ...baseStyle,
  color: 'var(--Font-F3---Body, #585863)',
  fontWeight: 500,
  paddingBottom: 2,
};

const formatFacilityAddress = (device?: Device | null) => {
  if (!device) return <div>N/A</div>;

  // Show address if they exist
  const addressLine = [device.facility_address].filter(Boolean).join(', ');

  // Build location line with city, state, zip, and country
  const locationParts = [
    [device.facility_city, device.facility_state].filter(Boolean).join(', '),
    device.facility_zip_code,
    device.facility_country,
  ].filter(Boolean);

  const locationLine = locationParts.join(' ');

  const addressLines = [
    device.facility_location,
    addressLine,
    locationLine,
  ].filter(Boolean);

  if (!addressLines.length) return <div>N/A</div>;

  return (
    <>
      {addressLines.map((line, index) => (
        <div key={index}>{line}</div>
      ))}
    </>
  );
};

interface HubDetailsTabProps {
  selectedDevice: Device | null;
}

const HubDetailsTab: React.FC<HubDetailsTabProps> = ({ selectedDevice }) => {
  const isUnassigned = selectedDevice?.customer_id === null;

  return (
    <Box py={2}>
      <Typography sx={baseStyle}>Serial number</Typography>
      <Typography sx={contentStyle}>{selectedDevice?.serial_number}</Typography>
      {selectedDevice?.device_status === 'offline' && !isUnassigned ? (
        <>
          <Typography sx={baseStyle}>Last online</Typography>
          <Typography sx={contentStyle}>
            {selectedDevice?.last_seen_online
              ? parseDateToLocal(
                  selectedDevice?.last_seen_online,
                  'yyyy-MM-dd HH:mm:ss'
                )
              : 'N/A'}
          </Typography>
        </>
      ) : (
        <>
          <Typography sx={baseStyle}>Model number</Typography>
          <Typography sx={contentStyle}>
            {selectedDevice?.model_number ?? 'N/A'}
          </Typography>
        </>
      )}
      <Typography sx={baseStyle}>Hardware version</Typography>
      <Typography sx={contentStyle}>
        {selectedDevice?.hardware_version ?? 'N/A'}
      </Typography>
      <Typography sx={baseStyle}>Software version</Typography>
      <Typography sx={contentStyle}>
        {selectedDevice?.software_version ?? 'N/A'}
      </Typography>
      <Typography sx={baseStyle}>Facility information</Typography>
      <Typography
        sx={{
          ...contentStyle,
          '& > div': {
            lineHeight: '24px',
            marginBottom: '4px',
          },
          '& > div:last-child': {
            marginBottom: 0,
          },
        }}
      >
        {formatFacilityAddress(selectedDevice)}
      </Typography>
      <Typography sx={baseStyle}>Activation date</Typography>
      <Typography sx={contentStyle}>
        {selectedDevice?.created_at
          ? parseDateToLocal(selectedDevice?.created_at, 'MMMM d, yyyy')
          : 'N/A'}
      </Typography>
    </Box>
  );
};

export default HubDetailsTab;

import React from 'react';
import { PieChart, Pie, Cell } from 'recharts';
import BaseCard from './BaseCard';
import { ReactComponent as CapsuleIcon } from '../../../images/icons/capsule.svg';
import { CustomLegend } from './CustomLegend';
import { Box } from '@mui/material';

interface AdherenceCardProps {
  isSelected?: boolean;
  onClick?: () => void;
  data: {
    adherent: number;
    nonAdherent: number;
    unknown: number;
  };
}

const getAdherentColor = (percentage: number): string => {
  if (percentage >= 95) {
    return '#B5E3C2'; // Green for 95-100%
  } else if (percentage >= 85) {
    return '#FDE69B'; // Yellow for 85-94%
  } else {
    return '#F0B9B2'; // Red for 84% and below
  }
};

const ADHERENCE_CONFIG = {
  categories: [
    { name: 'non-adherent', key: 'nonAdherent', color: '#DB5543' },
    { name: 'unknown', key: 'unknown', color: '#FCCD32' },
    { name: 'adherent', key: 'adherent', color: '#42AF60' },
  ],
  noData: {
    color: '#9999A4',
    value: 1, // 1% to show the pie chart
  },
} as const;

export const AdherenceCard: React.FC<AdherenceCardProps> = ({
  isSelected,
  onClick,
  data,
}) => {
  const total = data.adherent + data.nonAdherent + data.unknown;
  const isNoData = total === 0;

  // Step 1: Compute initial percentages
  let percentages = {
    adherent: (data.adherent / total) * 100,
    nonAdherent: (data.nonAdherent / total) * 100,
    unknown: (data.unknown / total) * 100,
  };

  // Step 2: Round the values
  let roundedPercentages = {
    adherent: Math.round(percentages.adherent),
    nonAdherent: Math.round(percentages.nonAdherent),
    unknown: Math.round(percentages.unknown),
  };

  // Step 3: Adjust the sum to ensure it equals 100%
  let sum =
    roundedPercentages.adherent +
    roundedPercentages.nonAdherent +
    roundedPercentages.unknown;
  let diff = 100 - sum;

  // If there's a difference, adjust the largest category
  if (diff !== 0) {
    let maxKey = Object.keys(roundedPercentages).reduce((a, b) =>
      roundedPercentages[a as keyof typeof roundedPercentages] >
      roundedPercentages[b as keyof typeof roundedPercentages]
        ? a
        : b
    ) as keyof typeof roundedPercentages;
    roundedPercentages[maxKey] += diff; // Adjust to fix rounding error
  }

  const chartData = ADHERENCE_CONFIG.categories.map((category) => ({
    name: category.name,
    value: roundedPercentages[category.key as keyof typeof roundedPercentages],
    color: category.color,
  }));

  if (isNoData) {
    chartData[2].value = ADHERENCE_CONFIG.noData.value; // add dummy data to show the pie chart
  }

  return (
    <BaseCard
      title="Adherence"
      isSelected={isSelected}
      onClick={onClick}
      icon={<CapsuleIcon />}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <CustomLegend
          payload={chartData.map((entry) => ({
            name: entry.name,
            color: entry.color,
            value: isNoData ? 'N/A' : `${entry.value}%`,
          }))}
        />
        <PieChart width={112} height={112} style={{ flexShrink: 0 }}>
          <circle
            cx="56"
            cy="56"
            r="25"
            fill={
              isNoData
                ? ADHERENCE_CONFIG.noData.color
                : getAdherentColor(roundedPercentages.adherent)
            }
          />
          <Pie
            data={chartData}
            cx="50%"
            cy="50%"
            innerRadius={30}
            outerRadius={55}
            dataKey="value"
            startAngle={15}
            endAngle={450}
          >
            {chartData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={isNoData ? ADHERENCE_CONFIG.noData.color : entry.color}
              />
            ))}
          </Pie>
          <text
            x="50%"
            y="50%"
            textAnchor="middle"
            dominantBaseline="middle"
            fontSize={16}
            fill={isNoData ? '#FFFFFF' : '#000000'} // White text if no data
          >
            {isNoData ? 'N/A' : `${roundedPercentages.adherent}%`}
          </text>
        </PieChart>
      </Box>
    </BaseCard>
  );
};

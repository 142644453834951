import { faEye, faEyeSlash } from '@fortawesome/pro-light-svg-icons';
import { Button, Container, Grid, Typography } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { CARETEAM_ROLE } from '../../../shared/constants';
import * as AnserInputValidation from '../../../shared/inputValidation';
import { addNewPrimaryContact } from '../../../store/customers/actionCreators';
import { Owner } from '../../../store/customers/customerTypes';
import {
  IconAdornment,
  TextInputFormField,
} from '../../Shared/TextInputFormField';

const schema = yup.object({
  first_name: yup.string().trim().required('This field is required'),
  last_name: yup.string().trim().required('This field is required'),
  email: yup
    .string()
    .email('Please enter a valid email address')
    .required('This field is required'),
  password: yup
    .string()
    .test('match', AnserInputValidation.passwordRequirements, function (value) {
      return AnserInputValidation.validatePassword(value ?? '');
    }),
  password2: yup
    .string()
    .test('match', 'Passwords do not match', function (value) {
      const { password } = this.parent;
      return password === value;
    }),
});

const CreatePrimaryContact = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  let { customer_id = '' } = useParams<{ customer_id: string }>();

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword((previousState) => !previousState);
  };
  const handleCancel = () => {
    history.push(`/customers/${customer_id}`);
  };

  const handleOnSubmit = (data: yup.InferType<typeof schema>) => {
    const customerId = Number(customer_id);
    if (data) {
      const payload: Partial<
        Owner & {
          customer_careteam_role: number;
          owner_customer_id: number;
          password2?: string;
        }
      > = { ...data };
      payload.customer_careteam_role = CARETEAM_ROLE.FAMILY_ADMIN;
      payload.customer_id = customerId;
      delete payload.password2;
      dispatch(addNewPrimaryContact(payload as Owner));
      history.push(`/customers/${customer_id}`);
    }
  };

  return (
    <Container maxWidth="lg">
      <Helmet>
        <title>Ōmcare - Customers - Create New Primary Contact</title>
      </Helmet>

      <Typography variant={'h1'}>Create new primary contact</Typography>
      <Formik
        validationSchema={schema}
        onSubmit={handleOnSubmit}
        initialValues={{
          first_name: '',
          last_name: '',
          email: '',
          password: '',
          password2: '',
        }}
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Grid container alignItems="flex-start" spacing={2}>
              <Grid item xs={6}>
                <Field
                  label="First name"
                  name="first_name"
                  placeholder="First name"
                  component={TextInputFormField}
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  label="Last name"
                  name="last_name"
                  placeholder="Last name"
                  component={TextInputFormField}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  label="Email address"
                  name="email"
                  placeholder="Email address"
                  component={TextInputFormField}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  label="Password"
                  name="password"
                  placeholder="Password"
                  type={showPassword ? 'text' : 'password'}
                  component={TextInputFormField}
                  InputProps={{
                    endAdornment: (
                      <IconAdornment
                        onClick={handleClickShowPassword}
                        faIconProps={{
                          icon: showPassword ? faEyeSlash : faEye,
                        }}
                      />
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  label="Confirm password"
                  name="password2"
                  placeholder="Confirm password"
                  type={showPassword ? 'text' : 'password'}
                  component={TextInputFormField}
                  InputProps={{
                    endAdornment: (
                      <IconAdornment
                        onClick={handleClickShowPassword}
                        faIconProps={{
                          icon: showPassword ? faEyeSlash : faEye,
                        }}
                      />
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <div style={{ margin: '20px 0' }}>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                style={{ marginRight: 10 }}
              >
                Submit
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default CreatePrimaryContact;

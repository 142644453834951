import { faEye, faEyeSlash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, IconButton, InputAdornment, Typography } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import * as AnserInputValidation from '../../../shared/inputValidation';
import {
  changeUserPassword,
  resetCompleteAccessToken,
} from '../../../store/auth/actionCreators';
import { TextInputFormField } from '../../Shared/TextInputFormField';
import ReCaptchaVerifierV2 from '../../Shared/ReCaptchaVerifierV2/ReCaptchaVerifierV2';

const initialValues = {
  current_password: '',
  new_password: '',
  confirm_password: '',
  showCurrentPassword: false,
  showNewPassword: false,
  showConfirmPassword: false,
};

const schema = yup.object({
  current_password: yup.string().required('Current password missing'),
  new_password: yup
    .string()
    .required('New password missing')
    .when('current_password', {
      is: (current_password: string) => !!current_password,
      then: yup
        .string()
        .notOneOf(
          [yup.ref('current_password')],
          'New password cannot match old password'
        ),
    })
    .test(
      'password_requirements',
      'Password does not meet requirements',
      function (password) {
        if (!password) {
          return true;
        }
        return AnserInputValidation.validatePassword(password);
      }
    ),
  confirm_password: yup
    .string()
    .oneOf([yup.ref('new_password')], 'Passwords do not match'),
});

interface ChangePasswordProps {
  title: string;
}

function ChangePassword(props: ChangePasswordProps) {
  const { title } = props;

  const dispatch = useDispatch();

  const siteKey = process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY || '';

  const [isCaptchaVerified, setIsCaptchaVerified] = useState(!siteKey);

  const handleCaptchaVerification = (verified: boolean) => {
    setIsCaptchaVerified(verified);
  };

  return (
    <div>
      <Typography variant={'h1'}>{title}</Typography>
      <span style={{ fontSize: '1.125rem' }}>
        <div>Enter current password and new password below.</div>
        <div style={{ maxWidth: '535px', margin: '25px 0 30px 0' }}>
          Password must be at least 8 characters and include 1 number, 1
          lowercase and 1 capital letter and 1 special character.
        </div>
      </span>
      <div>
        <Formik
          initialValues={initialValues}
          onSubmit={async (
            { current_password, new_password },
            { resetForm }
          ) => {
            await dispatch(
              changeUserPassword({
                current_password,
                new_password,
              })
            );
            await dispatch(resetCompleteAccessToken());
            resetForm();
          }}
          validate={async (values) => {
            try {
              await schema.validate(values, { abortEarly: false });
              return {};
            } catch (error: any) {
              const errorSchema = error.inner.reduce(
                (acl: { [key: string]: any }, { path, errors }: any) =>
                  Object.assign(acl, {
                    [path]: [].concat(acl[path] || [], ...errors),
                  }),
                {}
              );
              return errorSchema;
            }
          }}
        >
          {({ handleSubmit, values, setFieldValue, isValid }) => (
            <Form onSubmit={handleSubmit}>
              <div style={{ marginBottom: '25px', width: '500px' }}>
                <Field
                  type={values.showCurrentPassword ? 'text' : 'password'}
                  label="Current password*"
                  name="current_password"
                  component={TextInputFormField}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle new password visibility"
                          onClick={() =>
                            setFieldValue(
                              'showCurrentPassword',
                              !values.showCurrentPassword
                            )
                          }
                          edge="end"
                        >
                          {values.showCurrentPassword ? (
                            <FontAwesomeIcon icon={faEyeSlash} />
                          ) : (
                            <FontAwesomeIcon icon={faEye} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div style={{ marginBottom: '25px', width: '500px' }}>
                <Field
                  type={values.showNewPassword ? 'text' : 'password'}
                  label="New password*"
                  name="new_password"
                  component={TextInputFormField}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle new password visibility"
                          onClick={() =>
                            setFieldValue(
                              'showNewPassword',
                              !values.showNewPassword
                            )
                          }
                          edge="end"
                        >
                          {values.showNewPassword ? (
                            <FontAwesomeIcon icon={faEyeSlash} />
                          ) : (
                            <FontAwesomeIcon icon={faEye} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div style={{ marginBottom: '25px', width: '500px' }}>
                <Field
                  type={values.showConfirmPassword ? 'text' : 'password'}
                  label="Confirm new password*"
                  name="confirm_password"
                  component={TextInputFormField}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle new password visibility"
                          onClick={() =>
                            setFieldValue(
                              'showConfirmPassword',
                              !values.showConfirmPassword
                            )
                          }
                          edge="end"
                        >
                          {values.showConfirmPassword ? (
                            <FontAwesomeIcon icon={faEyeSlash} />
                          ) : (
                            <FontAwesomeIcon icon={faEye} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {siteKey && (
                  <ReCaptchaVerifierV2
                    sitekey={siteKey}
                    onVerify={handleCaptchaVerification}
                  />
                )}
              </div>
              <div>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  disabled={!isCaptchaVerified || !isValid}
                >
                  Save
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default ChangePassword;

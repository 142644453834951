import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import HubCard from './components/HubCard';
import { AdherenceCard } from './components/AdherenceCard';
import { VitalsCard } from './components/VitalsCard';
import { PatientSurveyCard } from './components/PatientSurveyCard';
import { DashboardTabs } from './components/DashboardTabs';
import { SoftwareCard } from './components/SoftwareCard';
import { IssuesCard } from './components/IssuesCard';
import DetailPanel from './detailPanel/DetailPanel';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOverviewStats } from '../../store/overviewStats/actionCreators';
import { RootState } from '../../store';
import LoadingSpinner from '../Shared/LoadingSpinner';

const CustomerCareDashboard: React.FC = () => {
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedCard, setSelectedCard] = useState<string | null>(null);

  const { stats, isLoading } = useSelector(
    (state: RootState) => state.overviewStats
  );

  useEffect(() => {
    if (!stats) {
      dispatch(fetchOverviewStats());
    }
  }, [dispatch, stats]);

  const getStatsCategory = () => {
    if (!stats) return null;
    switch (selectedTab) {
      case 0:
        return stats.all;
      case 1:
        return stats.facilities;
      case 2:
        return stats.consumers;
      default:
        return stats.all;
    }
  };

  const currentStats = getStatsCategory();

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (!currentStats) return null;

  return (
    <Box>
      <DashboardTabs
        selectedTab={selectedTab}
        onTabChange={(tab) => setSelectedTab(tab)}
        buttonConfig={{
          show: true,
          label: 'Add new',
        }}
      />

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: {
            xs: 'repeat(auto-fill, minmax(300px, 1fr))',
            sm: 'repeat(auto-fill, minmax(300px, 1fr))',
            md: 'repeat(auto-fill, 1fr)',
            lg: 'repeat(3, 1fr)',
            xl: 'repeat(3, 1fr)',
          },
          gap: 3,
        }}
        p={2}
      >
        <AdherenceCard
          isSelected={selectedCard === 'adherence'}
          onClick={() => setSelectedCard('adherence')}
          data={{
            adherent: currentStats.adherence.adherent,
            nonAdherent: currentStats.adherence.non_adherent,
            unknown: currentStats.adherence.unknown,
          }}
        />
        <HubCard
          isSelected={selectedCard === 'hubs'}
          onClick={() => setSelectedCard('hubs')}
          data={{
            offline: currentStats.hubs.offline,
            unassigned: currentStats.hubs.unassigned,
            online: currentStats.hubs.online,
            total: currentStats.hubs.total,
          }}
        />
        <IssuesCard
          isSelected={selectedCard === 'issues'}
          onClick={() => setSelectedCard('issues')}
          data={{
            total: currentStats.issues.total,
            highPriority: currentStats.issues.high_priority,
            lowPriority: currentStats.issues.low_priority,
          }}
        />
        <PatientSurveyCard
          isSelected={selectedCard === 'survey'}
          onClick={() => setSelectedCard('survey')}
          data={{
            veryHappy: currentStats.patient_survey.very_happy,
            somewhatHappy: currentStats.patient_survey.somewhat_happy,
            somewhatUnhappy: currentStats.patient_survey.somewhat_unhappy,
            veryUnhappy: currentStats.patient_survey.very_unhappy,
          }}
        />
        <SoftwareCard
          isSelected={selectedCard === 'software'}
          onClick={() => setSelectedCard('software')}
          data={{
            version: stats?.stable_channel_software_version || 'N/A',
            upToDate: currentStats.software.up_to_date,
            needsUpdate: currentStats.software.need_updating,
          }}
        />
        <VitalsCard
          isSelected={selectedCard === 'vitals'}
          onClick={() => setSelectedCard('vitals')}
          data={{
            labels: ['BP', 'HR', 'SpO2', 'RR'],
            normal: [80, 70, 95, 12],
            atRisk: [10, 20, 5, 8],
            actionRequired: [10, 10, 0, 2],
          }}
          comingSoon
        />
      </Box>

      {<DetailPanel card={selectedCard || ''} selectedTab={selectedTab} />}
    </Box>
  );
};

export default CustomerCareDashboard;

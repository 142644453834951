import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ReactComponent as CommingSoonIcon } from '../../../images/icons/coming_soon_Icon.svg';

const ComingSoonCard = () => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box sx={{ mb: 2, color: 'primary.main' }}>
        <CommingSoonIcon />
      </Box>
      <Typography
        variant="h6"
        gutterBottom
        sx={{
          color: 'var(--Font-F1---Heading, #250E62)',
          fontSize: '18px',
          fontWeight: 500,
          lineHeight: '24px',
          letterSpacing: '0.2px',
        }}
      >
        Coming Soon
      </Typography>
      <Typography
        variant="body2"
        sx={{
          color: 'var(--Font-F3---Body, #585863)',
          fontSize: '14px',
          fontWeight: 500,
          lineHeight: '20px',
          letterSpacing: '0.2px',
        }}
      >
        This feature is currently under development and will be available soon.
      </Typography>
    </Box>
  );
};

export default ComingSoonCard;

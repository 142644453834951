import React from 'react';
import { Box, LinearProgress, Typography } from '@mui/material';
import BaseCard from './BaseCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/pro-duotone-svg-icons';

interface IssuesCardProps {
  isSelected?: boolean;
  onClick?: () => void;
  data: {
    total: number;
    highPriority: number;
    lowPriority: number;
  };
}

export const IssuesCard: React.FC<IssuesCardProps> = ({
  isSelected,
  onClick,
  data,
}) => {
  return (
    <BaseCard
      title="Issues"
      isSelected={isSelected}
      onClick={onClick}
      icon={<FontAwesomeIcon icon={faTriangleExclamation} color="#585863" />}
      totalCount={`${data.total} total`}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }} mt={2}>
        <Box>
          <LinearProgress
            variant="determinate"
            value={
              data.total === 0 ? 0 : (data.highPriority / data.total) * 100
            }
            sx={{
              height: 8,
              bgcolor: '#EFEFF1',
              borderRadius: 4,
              '& .MuiLinearProgress-bar': {
                backgroundColor: '#ff1744',
              },
            }}
          />
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }} mt={1}>
            <Typography
              sx={{
                color: 'var(--Font-F3---Body, #585863)',
                fontSize: '14px',
                fontWeight: 500,
              }}
            >
              High priority
            </Typography>
            <Typography
              sx={{
                color: 'var(--Font-F3---Body, #585863)',
                fontSize: '14px',
                fontWeight: 700,
              }}
            >
              {data.highPriority}
            </Typography>
          </Box>
        </Box>
        <Box>
          <LinearProgress
            variant="determinate"
            value={data.total === 0 ? 0 : (data.lowPriority / data.total) * 100}
            sx={{
              height: 8,
              bgcolor: '#EFEFF1',
              borderRadius: 4,
              '& .MuiLinearProgress-bar': {
                backgroundColor: '#FFA726',
              },
            }}
          />
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }} mt={1}>
            <Typography
              sx={{
                color: 'var(--Font-F3---Body, #585863)',
                fontSize: '14px',
                fontWeight: 500,
              }}
            >
              Low priority
            </Typography>
            <Typography
              sx={{
                color: 'var(--Font-F3---Body, #585863)',
                fontSize: '14px',
                fontWeight: 700,
              }}
            >
              {data.lowPriority}
            </Typography>
          </Box>
        </Box>
      </Box>
    </BaseCard>
  );
};

import { faAngleDown } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
  SxProps,
  Theme,
} from '@mui/material';
import React from 'react';

interface CustomExpandMoreProps {
  useNewStyles?: boolean;
}

const CustomExpandMore = ({ useNewStyles, ...rest }: CustomExpandMoreProps) => {
  return (
    <FontAwesomeIcon
      icon={faAngleDown}
      size="2x"
      style={
        useNewStyles
          ? { marginRight: '2px', width: '20px', height: '20px' }
          : {}
      }
      {...rest}
    />
  );
};

interface CustomerCareSelectProps {
  label?: string;
  name?: string;
  children: JSX.Element | JSX.Element[];
  value: any;
  onChange: (event: SelectChangeEvent<string>) => void;
  disabled?: boolean;
  inputProps?: any;
  sx?: SxProps<Theme> | undefined;
}

function CustomerCareSelect(props: CustomerCareSelectProps) {
  const { label, name, value, onChange, disabled, children, inputProps, sx } =
    props;

  return (
    <FormControl>
      <InputLabel shrink>{label}</InputLabel>
      <Select
        fullWidth
        labelId="customer_care_select_field"
        name={name}
        label={label}
        value={value}
        onChange={onChange}
        disabled={disabled}
        inputProps={inputProps}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          PaperProps: {
            style: {
              maxHeight: '500px',
              boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.4)',
              borderRadius: '8px',
            },
          },
          sx: {
            '& .MuiMenuItem-root': {
              color: '#2A2A30',
              '&.Mui-selected': {
                color: '#1E1E1E',
              },
            },
          },
        }}
        displayEmpty
        IconComponent={(props) => <CustomExpandMore {...props} useNewStyles />}
        sx={{
          ...sx,
          borderRadius: '8px',
          '& .MuiOutlinedInput-notchedOutline': {
            borderRadius: '8px',
          },
          '& .MuiSelect-select': {
            color: '#2A2A30',
          },
          '.MuiSelect-icon': {
            top: '30%',
          },
        }}
      >
        {children}
      </Select>
    </FormControl>
  );
}

export default CustomerCareSelect;

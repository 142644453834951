import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import { TextInputFormField } from '../../Shared/TextInputFormField';
import * as yup from 'yup';

interface LTSConfirmationDialogProps {
  isConfirmOpen: boolean;
  setIsConfirmOpen: (value: boolean) => void;
  selectedVersion: {
    version: string;
  } | null;
  onConfirm: () => void;
}

const LTSConfirmationDialog: React.FC<LTSConfirmationDialogProps> = ({
  isConfirmOpen,
  setIsConfirmOpen,
  selectedVersion,
  onConfirm,
}) => {
  const schema = yup.object({
    version: yup
      .string()
      .required('This field is required')
      .test('matches-version', 'Version must match exactly', function (value) {
        return value === selectedVersion?.version;
      }),
  });

  const onSubmit = () => {
    onConfirm();
  };

  return (
    <Formik
      validationSchema={schema}
      onSubmit={onSubmit}
      initialValues={{
        version: '',
      }}
    >
      {({ handleSubmit, isValid, dirty }) => (
        <Form onSubmit={handleSubmit}>
          <Dialog open={isConfirmOpen}>
            <DialogTitle>Confirm LTS Version</DialogTitle>
            <DialogContent>
              <Box sx={{ mt: 2, mb: 2 }}>
                <Alert severity="warning" sx={{ mb: 2 }}>
                  Please confirm you want to set version{' '}
                  {selectedVersion?.version} as the LTS version. This will
                  remove LTS status from any existing LTS version.
                </Alert>
                <Field
                  label="Type version number to confirm"
                  name="version"
                  placeholder="version"
                  component={TextInputFormField}
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => setIsConfirmOpen(false)}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                onClick={onConfirm}
                disabled={!isValid || !dirty}
                variant="contained"
                color="secondary"
              >
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        </Form>
      )}
    </Formik>
  );
};

export default LTSConfirmationDialog;

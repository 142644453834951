import { setCookieDomain, setRestHost } from './shared/utils';

let rest_host = process.env.REACT_APP_REST_HOST;
if ('443' !== process.env.REACT_APP_REST_USER_PORT_HTTPS) {
  rest_host = rest_host + ':' + process.env.REACT_APP_REST_USER_PORT_HTTPS;
}

let storage_host = process.env.REACT_APP_STORAGE_HOST;
if ('443' !== process.env.REACT_APP_STORAGE_USER_PORT_HTTPS) {
  storage_host =
    storage_host + ':' + process.env.REACT_APP_STORAGE_USER_PORT_HTTPS;
}

export const mqttIoTEndpoint = process.env.REACT_APP_IOT_ENDPOINT
  ? process.env.REACT_APP_IOT_ENDPOINT
  : '';
export const restHost = rest_host ? rest_host : '';
export const storageHost = storage_host ? storage_host : '';

setRestHost(restHost);
setCookieDomain(
  process.env.REACT_APP_COOKIE_DOMAIN ? process.env.REACT_APP_COOKIE_DOMAIN : ''
);

import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhoneVolume } from '@fortawesome/pro-light-svg-icons';
import { useHistory } from 'react-router-dom';
import { Box, IconButton } from '@mui/material';
import StatusChip from '../../Shared/StatusChip';
import { MedStatusType } from '../../../shared/constants';
import {
  faBarsFilter,
  faCircleExclamation,
  faTriangleExclamation,
} from '@fortawesome/pro-solid-svg-icons';
import DashboardTableLegend from './DashboardTableLegend';
import CustomerCareDTable from '../../Shared/CustomerCareDTable';
import OverviewPopover from './OverviewPopover';
import NoDataCard from './NoDataCard';
import LoadingSpinner from '../../Shared/LoadingSpinner';
import { fetchHubOwners } from '../../../store/hubOwner/actionCreators';
import { parseDateToLocal } from '../../../shared/utils';

interface AdherenceDetailPanelProps {
  selectedTab: number;
}

interface ActionCellProps {
  contactName: string | null;
  contactPhone: string | null;
  contactEmail: string | null;
}

const ActionCell = ({
  contactName,
  contactPhone,
  contactEmail,
}: ActionCellProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleEmailClick = () => {
    if (contactEmail) {
      window.location.href = `mailto:${contactEmail}`;
    }
  };

  return (
    <Box
      display="flex"
      justifyContent="end"
      borderRadius="8px"
      border="1px solid var(--Outline-O5---Separator, #D4D4D9)"
    >
      <>
        <IconButton size="small" onClick={handleOpenModal}>
          <FontAwesomeIcon
            icon={faPhoneVolume}
            style={{ marginRight: 8 }}
            size="sm"
          />
        </IconButton>
        <Box
          width="1px"
          bgcolor="var(--Outline-O5---Separator, #D4D4D9)"
          marginY={1}
        />
        <IconButton size="small" onClick={handleEmailClick}>
          <FontAwesomeIcon
            icon={faEnvelope}
            size="sm"
            style={{ marginLeft: 8 }}
          />
        </IconButton>
      </>
      {isModalOpen && (
        <OverviewPopover
          onClose={handleCloseModal}
          contactName={contactName}
          contactPhone={contactPhone}
        />
      )}
    </Box>
  );
};

const AdherenceDetailPanel: React.FC<AdherenceDetailPanelProps> = ({
  selectedTab,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { hubOwners, isLoading } = useSelector(
    (state: RootState) => state.hubOwner
  );

  useEffect(() => {
    if (!hubOwners?.length) {
      dispatch(fetchHubOwners());
    }
  }, [dispatch, hubOwners?.length]);

  const onViewAll = () => {
    history.push(`/v2/adherence`);
  };

  const columns = [
    {
      field: 'patient',
      headerName: 'Patient',
      flex: 1,
    },
    ...(selectedTab !== 2
      ? [{ field: 'facility_name', headerName: 'Facility', flex: 1 }]
      : []),
    {
      field: 'med_status',
      headerName: 'Med status',
      flex: 1,
      sortComparator: (v1: any, v2: any) => {
        return v1.text.localeCompare(v2.text);
      },
      renderCell: (params: any) => (
        <Box display="flex" alignItems="center">
          <StatusChip
            icon={params.value.icon}
            iconColor={params.value.color}
            labelText={params.value.text}
            conditionalValue={params.value.text === 'Unknown' ? true : false}
            backgroundColor="#FFFAEB"
          />
        </Box>
      ),
    },
    { field: 'hub_serial_number', headerName: 'Hub serial number', flex: 1 },
    { field: 'last_seen_online', headerName: 'Last online', flex: 1 },
    {
      field: 'action',
      headerName: 'Actions',
      renderCell: (params: any) => (
        <ActionCell
          contactName={params.row.contact_name}
          contactPhone={params.row.contact_phone}
          contactEmail={params.row.contact_email}
        />
      ),
    },
  ];

  const getMedStatusConfig = (status: string | null) => {
    switch (status?.toLowerCase()) {
      case MedStatusType.PastDue:
        return {
          icon: faTriangleExclamation,
          color: '#DB5543',
          text: 'Past due',
          backgroundColor: '#FFFAEB',
        };
      case MedStatusType.Missed:
        return {
          icon: faTriangleExclamation,
          color: '#DB5543',
          text: 'Missed',
          backgroundColor: '#FFFAEB',
        };
      case MedStatusType.Unknown:
        return {
          icon: faCircleExclamation,
          color: '#FCCD32',
          text: 'Unknown',
          backgroundColor: '#F5F5F5',
        };
      default:
        return {
          icon: faCircleExclamation,
          color: '#FCCD32',
          text: 'Unknown',
          backgroundColor: '#F5F5F5',
        };
    }
  };

  const rows: any[] = useMemo(() => {
    let filteredHubOwners = hubOwners || [];

    filteredHubOwners = filteredHubOwners.filter((owner: any) => {
      const status = owner?.med_status?.toLowerCase();
      return [
        MedStatusType.PastDue,
        MedStatusType.Missed,
        MedStatusType.Unknown,
      ].includes(status);
    });

    if (selectedTab === 1) {
      filteredHubOwners = filteredHubOwners.filter(
        (owner: any) => owner.facility_id != null
      );
    } else if (selectedTab === 2) {
      filteredHubOwners = filteredHubOwners.filter(
        (owner: any) => owner.facility_id == null
      );
    }

    return (
      filteredHubOwners?.map((owner: any) => {
        const hasPatient = owner?.first_name || owner?.last_name;
        const medStatusConfig = getMedStatusConfig(owner.med_status);
        return {
          id: owner.customer_id,
          patient: hasPatient
            ? `${owner?.first_name} ${owner?.last_name}`
            : 'N/A',
          facility_name: owner.facility_name || 'N/A',
          med_status: {
            icon: medStatusConfig.icon,
            color: medStatusConfig.color,
            text: medStatusConfig.text,
            backgroundColor: medStatusConfig.backgroundColor,
          },
          hub_serial_number: owner?.serial_number || 'N/A',
          last_seen_online: owner.last_seen_online
            ? parseDateToLocal(owner.last_seen_online, 'yyyy-MM-dd HH:mm:ss')
            : 'N/A',
          action: faBarsFilter,
          contact_name: owner.contact_name || null,
          contact_phone: owner.contact_phone || null,
          contact_email: owner.contact_email || null,
        };
      }) ?? []
    );
  }, [hubOwners, selectedTab]);

  const tableLegendData = [
    {
      name: 'Non-adherent',
      color: '#DB5543',
    },
    {
      name: 'Unknown',
      color: '#FCCD32',
    },
  ];
  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      {rows.length ? (
        <Box p={2}>
          <DashboardTableLegend tableLegendData={tableLegendData} />
          <CustomerCareDTable
            rows={rows}
            columns={columns}
            loading={isLoading}
            pageSize={4}
            onViewAll={onViewAll}
            defaultSortField="med_status"
            defaultSortDirection="asc"
          />
        </Box>
      ) : (
        <NoDataCard />
      )}
    </>
  );
};

export default AdherenceDetailPanel;

import React, { useState, useCallback, useEffect } from 'react';
import { Box, Popover, Typography, Button, IconButton } from '@mui/material';
import { faGear, faCircleUser } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { dimGrey, topBarBg } from '../../../mainTheme';
import { useDispatch, useSelector } from 'react-redux';
import { logOut } from '../../../store/auth/actionCreators';
import {
  getCookie,
  getFacilityRoleById,
  getPrivilegeById,
} from '../../../shared/utils';
import { RootState } from '../../../store';
import { isNull } from 'lodash';
import { getDashboardUser } from '../../../store/dashboardUser/actionCreators';
import { useHistory } from 'react-router-dom';
import { UserRoles } from '../../../shared/constants';
import CustomerCareTopbar from './CustomerCareTopbar';

const Topbar = () => {
  const history = useHistory();

  const [profileAnchorEl, setProfileAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const [topbarText, setTopbarText] = useState({
    text: 'Dashboard',
    description: 'Overview',
  });

  const dispatch = useDispatch();

  const { dashboardUserInfo } = useSelector(
    (state: RootState) => state.dashboardUser
  );

  useEffect(() => {
    if (isNull(dashboardUserInfo)) {
      dispatch(getDashboardUser());
    }
  }, [dashboardUserInfo, dispatch]);

  const currentRole: UserRoles = dashboardUserInfo?.facility_role_id
    ? (getFacilityRoleById(dashboardUserInfo.facility_role_id) as UserRoles)
    : (getPrivilegeById(dashboardUserInfo?.privilege_id ?? 0) as UserRoles);

  const handleClickProfile = (event: React.MouseEvent<HTMLElement>) => {
    setProfileAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setProfileAnchorEl(null);
  };

  const handleSettingsClick = () => {
    history.push('/v2/settings');
    handleClose();
  };

  const handleLogout = useCallback(() => {
    sessionStorage.removeItem('useNewUI');
    dispatch(logOut(getCookie('refresh_token') || ''));
  }, [dispatch]);

  const open = Boolean(profileAnchorEl);

  useEffect(() => {
    const path = history.location.pathname;
    const parts = path.split('/'); // Split the path into segments
    const baseRoute = parts[1] ? `/${parts[1]}/${parts[2]}` : path; // Get the base route for nested routes
    const hasNestedRoute = parts.length > 3; // Checking if there's a nested route like

    switch (baseRoute) {
      case '/v2/hubs':
        setTopbarText({
          text: 'Dashboard',
          description: hasNestedRoute ? 'Hubs > Hub details' : 'Hubs',
        });
        break;
      case '/v2/settings':
        setTopbarText({
          text: 'Settings',
          description: 'Manage your settings',
        });
        break;
      case '/v2/software':
        setTopbarText({
          text: 'Software', // Temp : Change the text when new designs are ready
          description: 'Manage software versions',
        });
        break;
      case '/v2/patient-survey':
        setTopbarText({
          text: 'Patient Survey',
          description: 'View patient survey responses', // Temp : Change the text when new designs are ready
        });
        break;
      default:
        setTopbarText({
          text: 'Dashboard',
          description: 'Overview',
        });
    }
  }, [history.location.pathname]);

  if (currentRole === UserRoles.CUSTOMER_CARE) {
    return (
      <CustomerCareTopbar
        handleClickProfile={handleClickProfile}
        profileAnchorEl={profileAnchorEl}
        handleClose={handleClose}
        open={open}
        handleSettingsClick={handleSettingsClick}
        handleLogout={handleLogout}
        dashboardUserInfo={dashboardUserInfo}
        currentTitle={topbarText.text}
        currentDescription={topbarText.description}
      />
    );
  }

  return (
    <Box
      bgcolor={topBarBg}
      height="72px"
      display="flex"
      justifyContent="end"
      alignItems="center"
      position="fixed"
      top={0}
      left={0}
      right={0}
      zIndex={1000}
    >
      <Box p={2} display="flex">
        <IconButton onClick={handleSettingsClick} style={{ color: dimGrey }}>
          <FontAwesomeIcon icon={faGear} size="sm" />{' '}
        </IconButton>

        <IconButton onClick={handleClickProfile} style={{ color: dimGrey }}>
          <FontAwesomeIcon icon={faCircleUser} size="sm" />
        </IconButton>
        <Popover
          id="simple-popover"
          open={open}
          anchorEl={profileAnchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Box
            p={2}
            display="flex"
            flexDirection="column"
            // Commenting out since design is not avalible now .
            // border={`1px solid ${topBarBg}`}
            bgcolor={topBarBg}
          >
            <Typography align="center">
              Hi, {dashboardUserInfo?.first_name}
            </Typography>
            <Button onClick={handleLogout}>Logout</Button>
          </Box>
        </Popover>
      </Box>
    </Box>
  );
};

export default Topbar;

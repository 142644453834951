import React, { ReactChild, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { mqttConnect } from '../store/mqtt/actionCreators';

interface MQTTConnectionProps {
  dispatch?: any;
  host: string;
  token: string;
  children: ReactChild;
}

function MQTTConnection(props: MQTTConnectionProps) {
  const dispatch = useDispatch();
  useEffect(() => {
    console.log('MQTTConnection mounted. props ', props);
    if (!props.token) {
      console.log('token expired, TODO: get a new token?');
    }

    const { host, token } = props;
    dispatch(mqttConnect(host || '', token || ''));
  }, [dispatch, props]);

  return <div>{props.children}</div>;
}

export default MQTTConnection;

import { TabSchema, User } from '../GlobalTypes';

export interface Owner extends User {
  name?: string;
  is_online?: boolean;
  last_login_at: string | null;
  created_at: string;
  modified_at: string | null;
  phone_number?: string | null;
  pin?: string | null;
  formatted_address?: string | null;
  long_format_dob?: string | null;
}

export interface Careteam extends User {
  created_at?: string;
  patient_customer_id: number;
  careteam_role_id: number;
  phone_number?: string | null;
  is_online?: boolean;
}

export type CustomerMedication = {
  medication_dose_id: number;
  customer_id: number;
  national_drug_code: string;
  modified_at: string | null;
  drug_name: string;
  tablet_count: string;
  prescribed_datetime: string;
  dispensed_at: string | null;
  medication_dose_status_id: number;
  administered_at: string | null;
  administered_by: null | number;
  administered_timezone: string | null;
  batch_id: number;
  bag_number: number;
  created_at: string;
  medication_dose_status: string | undefined;
  first_name: string;
  last_name: string;
  name: string;
  unique_parent_id?: number;
  desired_admin_time: string;
  device_tz: string;
  event_type_id: number;
  event_type: string;
};

export interface CareteamInvite {
  first_name: string;
  last_name: string;
  email: string;
  careteam_role_id: string;
  expires_at: string;
}

export interface MedChangesPayload {
  batch_id: number;
  bag_number: number;
  desired_admin_time?: string;
  event_type_id?: number;
}

export enum CustomerStoreActionTypes {
  SET_LAST_REFRESH = 'SET_LAST_REFRESH',
  SET_IS_REFRESHING = 'SET_IS_REFRESHING',
  POPULATE_CUSTOMERS = 'POPULATE_CUSTOMERS',
  CUSTOMERS_API_ERR = 'CUSTOMERS_API_ERR',
  CUSTOMERS_SET_LOAD_STATE = 'CUSTOMERS_SET_LOAD_STATE',
  SUCCESS_CREATE_USER = 'SUCCESS_CREATE_USER',
  POPULATE_CUSTOMER = 'POPULATE_CUSTOMER',
  POPULATE_CUSTOMER_CARE_TEAM = 'POPULATE_CUSTOMER_CARE_TEAM',
  CLEAR_CUSTOMER_AND_CARE_TEAM = 'CLEAR_CUSTOMER_AND_CARE_TEAM',
  POPULATE_CUSTOMER_MEDICATION_TIME = 'POPULATE_CUSTOMER_MEDICATION_TIME',
  CREATE_PRIMARY_CONTACT_SUCCESS = 'CREATE_PRIMARY_CONTACT_SUCCESS',
  POPULATE_CARE_TEAM_DETAILS = 'POPULATE_CARE_TEAM_DETAILS',
  CLEAR_CARE_TEAM_DETAILS = 'CLEAR_CARE_TEAM_DETAILS',
  POPULATE_CARETEAM_INVITES = 'POPULATE_CARETEAM_INVITES',
  CUSTOMERS_SIDE_BAR_TAB_SELECTION = 'CUSTOMERS_SIDE_BAR_TAB_SELECTION',
  SAVE_OWNER_PIN = 'SAVE_OWNER_PIN',
  CREATE_MEDICATION_ROLL_SUCCESS = 'CREATE_MEDICATION_ROLL_SUCCESS',
  GET_MEDICATION_SCHEDULE_TEMPLATE = 'GET_MEDICATION_SCHEDULE_TEMPLATE',
  RESET_IMPORT_MED_ROLL_FORM = 'RESET_IMPORT_MED_ROLL_FORM',
  SUCCESS_DELETE_USER = 'SUCCESS_DELETE_USER',
}

export type SetLastRefreshAction = {
  type: CustomerStoreActionTypes.SET_LAST_REFRESH;
};

export type SetIsRefreshingAction = {
  type: CustomerStoreActionTypes.SET_IS_REFRESHING;
  payload: boolean;
};

export type PopulateCustomerAction = {
  type: CustomerStoreActionTypes.POPULATE_CUSTOMER;
  payload: { customer: Owner };
};

export type PopulateCustomersAction = {
  type: CustomerStoreActionTypes.POPULATE_CUSTOMERS;
  payload: { customerList: Owner[] };
};

export type CustomerActionAPIError = {
  type: CustomerStoreActionTypes.CUSTOMERS_API_ERR;
  payload: { error: any };
};

export type CustomerActionSetLoadStateAction = {
  type: CustomerStoreActionTypes.CUSTOMERS_SET_LOAD_STATE;
  payload: { isLoading: boolean };
};

export type CustomerCreateUserSuccessAction = {
  type: CustomerStoreActionTypes.SUCCESS_CREATE_USER;
  payload: { createUserSuccessful: boolean };
};

export type CustomerPopulateCareteamAction = {
  type: CustomerStoreActionTypes.POPULATE_CUSTOMER_CARE_TEAM;
  payload: { careTeam: { [key: string]: Careteam[] } };
};

export type CustomerClearCustomerAndCareteamAction = {
  type: CustomerStoreActionTypes.CLEAR_CUSTOMER_AND_CARE_TEAM;
};

export type CustomerCreatePrimaryContactAction = {
  type: CustomerStoreActionTypes.CREATE_PRIMARY_CONTACT_SUCCESS;
};

export type CustomerPopulateCareteamDetailAction = {
  type: CustomerStoreActionTypes.POPULATE_CARE_TEAM_DETAILS;
  payload: { careTeamDetails: Careteam };
};

export type CustomerClearCareteamAction = {
  type: CustomerStoreActionTypes.CLEAR_CARE_TEAM_DETAILS;
};

export type CustomerPopulateCareteamInvitesAction = {
  type: CustomerStoreActionTypes.POPULATE_CARETEAM_INVITES;
  payload: { careteamInvites: CareteamInvite[] };
};

export type CustomerSwitchSidebarAction = {
  type: CustomerStoreActionTypes.CUSTOMERS_SIDE_BAR_TAB_SELECTION;
  payload: { selectedTab: TabSchema };
};

export type CustomerSaveOwnerPinAction = {
  type: CustomerStoreActionTypes.SAVE_OWNER_PIN;
  payload: { ownerPin: string | null };
};

export type CustomerDeleteUserSuccessAction = {
  type: CustomerStoreActionTypes.SUCCESS_DELETE_USER;
  payload: { deleteUserSuccessful: boolean };
};

export type CustomerStore = {
  owners: Owner[] | null;
  careGivers: Owner[] | null;
  error: any | null;
  isLoading: boolean;
  createUserSuccessful: boolean;
  careTeam: { [key: string]: Careteam[] } | null;
  careTeamDetails: Careteam | null;
  facilityCustomers: Owner[] | null;
  didAddPrimaryContact: boolean;
  selectedTab: TabSchema;
  careteamInvites: CareteamInvite[] | null;
  currentOwnerPin: string | null;
  deleteUserSuccessful: boolean;
  lastRefreshTime: string | null;
  isRefreshing: boolean;
};

export type CustomerStoreAction =
  | PopulateCustomerAction
  | PopulateCustomersAction
  | CustomerActionAPIError
  | CustomerActionSetLoadStateAction
  | CustomerCreateUserSuccessAction
  | CustomerPopulateCareteamAction
  | CustomerClearCustomerAndCareteamAction
  | CustomerCreatePrimaryContactAction
  | CustomerPopulateCareteamDetailAction
  | CustomerClearCareteamAction
  | CustomerPopulateCareteamInvitesAction
  | CustomerSwitchSidebarAction
  | CustomerSaveOwnerPinAction
  | CustomerDeleteUserSuccessAction
  | SetLastRefreshAction
  | SetIsRefreshingAction;

export interface CreateCustomerPayload {
  first_name: string;
  last_name: string;
  username: string;
  facility_role_id: number;
  password: string;
  password2?: string;
  gender: number | '';
  pharmacy: number;
  address1: string;
  address2?: string;
  city: string;
  state: string;
}

export interface NewTechnicalSupportUserPayload {
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
  priviege_id: number;
}

import { Box, Button, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { lightBackground1 } from '../../mainTheme';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import LoadingSpinner from '../Shared/LoadingSpinner';
import * as Yup from 'yup';
import { Field, Form, Formik, FormikProps } from 'formik';
import MaskedInput from '../Shared/MaskedInput';
import { DashboardUserProfile } from '../../store/dashboardUser/dashboardUserTypes';
import { isNull } from 'lodash';
import { updateDashboardUserProfile } from '../../store/dashboardUser/actionCreators';
import ChangePasswordModal from './components/ChangePasswordModal';
import {
  changeUserPassword,
  logOut,
  resetCompleteAccessToken,
} from '../../store/auth/actionCreators';
import { getDashboardUser } from '../../store/dashboardUser/actionCreators';
import { ChangePasswordInterface } from '../../store/auth/authTypes';
import PasswordChangeSuccessModal from './components/PasswordChangeSuccessModal';
import { AccountDelModalTypes, FACILITY_ROLE } from '../../shared/constants';
import {
  deleteCaregiverById,
  setDeleteCaregiverSuccess,
} from '../../store/facility/actionCreators';
import DeleteAccountModals from '../Shared/DeleteAccountModals/DeleteAccountModals';
import { getCookie } from '../../shared/utils';
import UserDeletedModal from '../Caregivers/components/UserDeletedModal';

const baseStyle = {
  color: '#000',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '24px',
  letterSpacing: '0.2px',
};

const headingStyle = {
  ...baseStyle,
  fontSize: '22px',
  lineHeight: '30px',
  letterSpacing: '0.36px',
};

const headLineStyle = {
  ...baseStyle,
  fontSize: '18px',
};

const contentStyle = {
  ...baseStyle,
  color: 'var(--Font-F3---Body, #585863)',
  fontSize: '16px',
  paddingBottom: 2,
};

const validationSchema = Yup.object().shape({
  phone: Yup.string()
    .matches(/^\(\d{3}\) \d{3}-\d{4}$/, 'Phone number is not valid')
    .required('Phone number is required'),
});

const stateSelector = (state: RootState) => {
  const { dashboardUser, auth, facility } = state;
  const { dashboardUserInfo } = dashboardUser;
  const { passwordChangeSuccess } = auth;
  const { deleteCaregiverSuccess } = facility;
  return {
    dashboardUserInfo,
    isLoading: dashboardUser.isLoading || auth.isLoading,
    passwordChangeSuccess,
    deleteCaregiverSuccess,
  };
};

const AccountSettings = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    dashboardUserInfo,
    isLoading,
    passwordChangeSuccess,
    deleteCaregiverSuccess,
  } = useSelector(stateSelector);
  const [isEditing, setIsEditing] = useState(false);
  const [isChangePasswordModalOpen, setChangePasswordModalOpen] =
    useState(false);
  const [isSuccessModalOpen, setSuccessModalOpen] = useState(false);
  const [currentModal, setCurrentModal] = useState<string | null>(null);
  const [isUserDeletedModalOpen, setUserDeletedModalOpen] = useState(false);

  const formikRef = useRef<FormikProps<{ phone: string }>>(null);

  const handleBackClick = () => {
    history.goBack();
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };
  const handleSaveClick = async () => {
    const values = formikRef?.current?.values;
    const { address1, city, first_name, last_name, state, zipcode } =
      dashboardUserInfo || {};
    if (
      values &&
      dashboardUserInfo &&
      address1 &&
      city &&
      first_name &&
      last_name &&
      state &&
      zipcode
    ) {
      const updatedProfile: DashboardUserProfile = {
        address1,
        city,
        first_name,
        last_name,
        phone: values.phone,
        state,
        zipcode,
      };
      await dispatch(updateDashboardUserProfile(updatedProfile));
    }
    setIsEditing(false);
  };

  const handleOpenChangePasswordModal = () => {
    setChangePasswordModalOpen(true);
  };

  const handleCloseChangePasswordModal = () => {
    setChangePasswordModalOpen(false);
  };

  useEffect(() => {
    if (passwordChangeSuccess) {
      handleCloseChangePasswordModal();
      setSuccessModalOpen(true);
    }
  }, [passwordChangeSuccess]);

  /* TODO: commenting below code , we’ll come back to it later. */

  // const columns = [
  //   { field: 'facility', headerName: 'Facility', flex: 1 },
  //   {
  //     field: 'action',
  //     headerName: 'Action',
  //     renderCell: () => <ActionCell handleNavigation={() => {}} />,
  //   },
  // ];

  // const rows = dashboardUserInfo
  //   ? [
  //       {
  //         id: dashboardUserInfo.phone,
  //         facility: dashboardUserInfo.facility_name,
  //       },
  //     ]
  //   : [];

  const handlers = {
    onClose: () => setCurrentModal(null),
    onConfirmDelete: () =>
      setCurrentModal(AccountDelModalTypes.ASSIGN_ADMIN_MODAL),
    onNext: () => setCurrentModal(AccountDelModalTypes.CONFIRM_DELETION_MODAL),
    setCurrentModal: setCurrentModal,
    onDeleteAccount: () => {
      dispatch(deleteCaregiverById(dashboardUserInfo?.customer_id || 0));
    },
  };

  const handleDeleteAccountClick = () => {
    const isFacilityAdmin =
      dashboardUserInfo?.facility_role_id === FACILITY_ROLE.FACILITY_ADMIN;
    const modalType =
      isFacilityAdmin && dashboardUserInfo?.sole_facility_admin // checks if the user is the only admin in the facility
        ? AccountDelModalTypes.ONLY_ADMIN_WARNING
        : AccountDelModalTypes.CONFIRM_DELETION_MODAL;

    setCurrentModal(modalType);
  };

  const handleCloseUserDeletedModal = () => {
    setUserDeletedModalOpen(false);
    dispatch(logOut(getCookie('refresh_token') || ''));
  };

  // Use a ref to ensure `getDashboardUser` is dispatched only once per render
  const hasFetchedDashboardUser = useRef(false);
  useEffect(() => {
    if (!hasFetchedDashboardUser.current) {
      dispatch(getDashboardUser());
      hasFetchedDashboardUser.current = true;
    }
  }, [dispatch]);

  useEffect(() => {
    if (deleteCaregiverSuccess) {
      setUserDeletedModalOpen(true);
      dispatch(setDeleteCaregiverSuccess(false));
    }
  }, [deleteCaregiverSuccess, dispatch]);

  return (
    <Box display="flex" flexDirection="column" bgcolor={lightBackground1} p={2}>
      <Box>
        <Button
          variant="text"
          color="secondary"
          onClick={handleBackClick}
          data-testid="back-button"
        >
          Back
        </Button>
      </Box>
      <Box py={2} display="flex" justifyContent="space-between">
        <Box>
          <Typography sx={headingStyle}>Settings</Typography>
        </Box>
        <Box display="flex" gap={2}>
          <Button
            variant="outlined"
            color="secondary"
            data-testid="change-password-button"
            onClick={handleOpenChangePasswordModal}
          >
            Change password
          </Button>
          <Button
            variant="contained"
            color="error"
            data-testid="delete-account-button"
            onClick={handleDeleteAccountClick}
          >
            Delete account
          </Button>
        </Box>
      </Box>
      <Box>
        <Box display="flex" alignItems="baseline" pb={2}>
          <Typography sx={headLineStyle} pr={2}>
            Contact details
          </Typography>
          <Button
            type="submit"
            variant="text"
            color="secondary"
            onClick={isEditing ? handleSaveClick : handleEditClick}
            sx={{ minWidth: 'auto', width: '80px' }}
          >
            {isEditing ? 'Save' : 'Edit'}
          </Button>
          {isEditing && (
            <Button
              variant="text"
              color="secondary"
              onClick={() => {
                setIsEditing(false);
              }}
              sx={{ minWidth: 'auto', width: '80px' }}
            >
              Cancel
            </Button>
          )}
        </Box>
        <Typography sx={headLineStyle}>Email</Typography>
        <Typography sx={contentStyle}>{dashboardUserInfo?.email}</Typography>
        <Typography sx={headLineStyle}>Phone number</Typography>
        {isEditing ? (
          <Formik
            innerRef={formikRef}
            initialValues={{
              ...dashboardUserInfo,
              phone: dashboardUserInfo?.phone || '',
            }}
            validationSchema={validationSchema}
            onSubmit={() => {}}
          >
            {({ handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <Box pb={1} sx={{ width: '50%' }}>
                  <Field
                    name="phone"
                    format="(###) ###-####"
                    placeholder="(123)-456-7890"
                    component={MaskedInput}
                    variant="standard"
                    size="small"
                    sx={{
                      fontSize: 18,
                      fontWeight: 'bold',
                      mb: 1,
                      color: 'red',
                    }}
                  />
                </Box>
              </Form>
            )}
          </Formik>
        ) : (
          <>
            <Typography sx={contentStyle}>
              {dashboardUserInfo?.phone || 'N/A'}
            </Typography>
          </>
        )}
        <Typography sx={headLineStyle}>Primary location</Typography>
        <Typography sx={contentStyle}>
          {dashboardUserInfo?.facility_name}
        </Typography>
        {/* TODO: commenting below code , we’ll come back to it later. */}
        {/* <Typography sx={headLineStyle} mt={4}>
          Facility details
        </Typography>
        <DataGridTable
          rows={rows}
          columns={columns}
          pageSize={5}
          maxHeight="30vh"
          minHeight="200px"
          hideFooter
        /> */}
      </Box>

      {isChangePasswordModalOpen && (
        <ChangePasswordModal
          onClose={handleCloseChangePasswordModal}
          onSubmit={async (values: ChangePasswordInterface) => {
            if (values.current_password && values.new_password) {
              await dispatch(
                changeUserPassword({
                  current_password: values.current_password,
                  new_password: values.new_password,
                })
              );
            }
            await dispatch(resetCompleteAccessToken());
          }}
          initialValues={{
            current_password: '',
            new_password: '',
          }}
        />
      )}
      {isSuccessModalOpen && (
        <PasswordChangeSuccessModal
          onClose={() => {
            setSuccessModalOpen(false);
          }}
        />
      )}

      {currentModal && dashboardUserInfo && (
        <DeleteAccountModals
          currentModal={currentModal}
          handlers={handlers}
          dashboardUserInfo={dashboardUserInfo}
        />
      )}

      {isUserDeletedModalOpen && (
        <UserDeletedModal
          heading="Delete account"
          modalTitle="Delete account"
          modalContent
          onClose={handleCloseUserDeletedModal}
        />
      )}

      {(isNull(dashboardUserInfo) || isLoading) && <LoadingSpinner />}
    </Box>
  );
};

export default AccountSettings;

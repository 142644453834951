import { Reducer } from 'redux';
import {
  OverviewStatsStore,
  OverviewStatsAction,
  OverviewStatsActionTypes,
} from './overviewStatsTypes';

const initialState: OverviewStatsStore = {
  stats: null,
  error: null,
  isLoading: false,
};

const overviewStatsReducer: Reducer<OverviewStatsStore, OverviewStatsAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case OverviewStatsActionTypes.FETCH_STATS_SUCCESS:
      return {
        ...state,
        stats: action.payload,
        error: null,
        isLoading: false,
      };
    case OverviewStatsActionTypes.FETCH_STATS_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case OverviewStatsActionTypes.SET_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    default:
      return state;
  }
};

export default overviewStatsReducer;

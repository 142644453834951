import { faEye, faEyeSlash } from '@fortawesome/pro-light-svg-icons';
import {
  AppBar,
  Button,
  Container,
  DialogActions,
  Grid,
  Toolbar,
  Typography,
} from '@mui/material';
import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { match } from 'react-router-dom';
import * as yup from 'yup';
import navLogo from '../images/omcarenavlogo.png';
import { redirectToLogin } from '../shared/utils';
import * as AnserInputValidation from '../shared/inputValidation';
import { RootState } from '../store';
import {
  createFacilityAdmin,
  setErrorAlert,
} from '../store/facility/actionCreators';
import { FacilityAdminRegisterData } from '../store/facility/facilityTypes';
import Modal from './Shared/Modal';
import { IconAdornment, TextInputFormField } from './Shared/TextInputFormField';

interface RegisterProps {
  match: match<{ token: string }>;
}

const stateSelector = (state: RootState) => {
  const { facility } = state;
  const {
    showSuccessAlert,
    showErrorAlert,
    showDuplicateAlert,
    showDuplicateAlertFacility,
    showDuplicateAlertCareTeam,
  } = facility;

  return {
    showSuccessAlert,
    showErrorAlert,
    showDuplicateAlert,
    showDuplicateAlertFacility,
    showDuplicateAlertCareTeam,
  };
};

const schema = yup.object({
  first_name: yup.string().trim().required('First name is required'),
  last_name: yup.string().trim().required('Last name is required'),
  password: yup
    .string()
    .test('match', AnserInputValidation.passwordRequirements, function (value) {
      return AnserInputValidation.validatePassword(value ?? '');
    }),
  password2: yup
    .string()
    .test('match', 'Passwords do not match', function (value) {
      const { password } = this.parent;
      return password === value;
    }),
});

function Register(props: RegisterProps) {
  const {
    showErrorAlert,
    showSuccessAlert,
    showDuplicateAlert,
    showDuplicateAlertCareTeam,
    showDuplicateAlertFacility,
  } = useSelector(stateSelector);
  const dispatch = useDispatch();
  const [showPassword, setPasswordVisibility] = useState(false);

  const {
    match: {
      params: { token },
    },
  } = props;

  const onSubmit = (data: FacilityAdminRegisterData) => {
    if (!token) {
      dispatch(setErrorAlert(true, 'Token not found'));
    } else {
      data.token = token;
      dispatch(createFacilityAdmin(data));
    }
  };

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <img src={navLogo} alt="OmcareLogo" style={{ height: '30px' }} />
        </Toolbar>
      </AppBar>

      <Container>
        <Grid item xs={12} sm={6}>
          <Typography variant="h4">
            Complete your account registration
          </Typography>
        </Grid>
        <Formik
          validationSchema={schema}
          onSubmit={onSubmit}
          initialValues={{
            first_name: '',
            last_name: '',
            password: '',
            password2: '',
            token: '',
          }}
        >
          {({ handleSubmit }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Grid container direction="column" spacing={4}>
                <Grid item xs>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Field
                        label="First name"
                        name="first_name"
                        type="text"
                        placeholder="First name"
                        component={TextInputFormField}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field
                        label="Last name"
                        name="last_name"
                        type="text"
                        placeholder="Last name"
                        component={TextInputFormField}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Field
                    label="Password"
                    name="password"
                    placeholder="Password"
                    type={showPassword ? 'text' : 'password'}
                    component={TextInputFormField}
                    InputProps={{
                      endAdornment: (
                        <IconAdornment
                          onClick={() => setPasswordVisibility((prev) => !prev)}
                          faIconProps={{
                            icon: showPassword ? faEye : faEyeSlash,
                          }}
                        />
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    label="Confirm password"
                    name="password2"
                    placeholder="Confirm password"
                    type={showPassword ? 'text' : 'password'}
                    component={TextInputFormField}
                    InputProps={{
                      endAdornment: (
                        <IconAdornment
                          onClick={() => setPasswordVisibility((prev) => !prev)}
                          faIconProps={{
                            icon: showPassword ? faEye : faEyeSlash,
                          }}
                        />
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs>
                  <Button type="submit" variant="contained" color="secondary">
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Container>
      {showSuccessAlert ? (
        <Modal title={'Account registered'}>
          Your account has been created! Please continue with the{' '}
          <a href="https://apps.apple.com/us/app/omcare/id1513962801">
            Ōmcare app
          </a>
          <DialogActions>
            <Button
              onClick={redirectToLogin}
              variant="contained"
              color="secondary"
            >
              Login
            </Button>
          </DialogActions>
        </Modal>
      ) : null}
      {showErrorAlert ? (
        <Modal title={'The invite has expired'} onClose={redirectToLogin}>
          Please contact Ōmcare Technical Support for further assistance.{' '}
          <DialogActions>
            <Button
              onClick={redirectToLogin}
              variant="contained"
              color="secondary"
            >
              Close
            </Button>
          </DialogActions>
        </Modal>
      ) : null}
      {showDuplicateAlert ? (
        <Modal title={`Account already exists`} onClose={redirectToLogin}>
          Your account is already created.{' '}
          <DialogActions>
            <Button
              onClick={redirectToLogin}
              variant="contained"
              color="secondary"
            >
              Close
            </Button>
          </DialogActions>
        </Modal>
      ) : null}
      {showDuplicateAlertFacility ? (
        <Modal
          title={`Facility Account already exists`}
          onClose={redirectToLogin}
        >
          An existing facility account is already using this email. Please use
          different email to get invite{' '}
          <DialogActions>
            <Button
              onClick={redirectToLogin}
              variant="contained"
              color="secondary"
            >
              Close
            </Button>
          </DialogActions>
        </Modal>
      ) : null}
      {showDuplicateAlertCareTeam ? (
        <Modal
          title={`Care team account already exists`}
          onClose={redirectToLogin}
        >
          It looks like you already have account with us. Please login to our
          app using email address invite was sent to and Accept invite under
          Quick Updates!{' '}
          <DialogActions>
            <Button
              onClick={redirectToLogin}
              variant="contained"
              color="secondary"
            >
              Close
            </Button>
          </DialogActions>
        </Modal>
      ) : null}
    </>
  );
}

export default Register;

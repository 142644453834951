import MaterialTable, {
  Column,
  MTableToolbar,
  Options,
} from '@material-table/core';
import { Button, Container } from '@mui/material';
import { styled } from '@mui/system';
import isNull from 'lodash/isNull';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { useHistory } from 'react-router-dom';
import {
  ACCOUNT_DETAILS_ONLY,
  UNIFIED_FACILITY_ADMIN_ROLES,
} from '../../shared/constants';
import {
  DisplayRole,
  NameFormatter,
  newlyCreated,
  sortByCreatedAt,
  sortByFullName,
  StatusIcon,
  TableOptions,
} from '../../shared/tableUtils';
import { getPrivilegeById } from '../../shared/utils';
import {
  clearCustomerAndCareTeam,
  getCustomerList,
  getFacilityCustomers,
  sideBarTabSelection,
  populateCareteamInvites,
} from '../../store/customers/actionCreators';
import { Owner, CareteamInvite } from '../../store/customers/customerTypes';
import { DashboardUser } from '../../store/dashboardUser/dashboardUserTypes';
import { HeaderWrapper } from '../Shared/HeaderWrapper';
import LoadingSpinner from '../Shared/LoadingSpinner';
import { Wrapper } from '../Shared/Wrapper';
import { Pharmacy } from '../../store/pharmacy/pharmacyTypes';
import { getPharmacies } from '../../store/pharmacy/actionCreators';

const TableActionsWrapper = styled('div')(() => ({
  position: 'absolute',
  top: '7px',
}));

function Customers() {
  const {
    dashboardUserInfo,
    facilityCustomers,
    connected,
    pharmacies,
    careteamInvites,
  } = useSelector<
    RootState,
    {
      dashboardUserInfo: DashboardUser | null;
      facilityCustomers: Owner[] | null;
      connected: boolean;
      pharmacies: Pharmacy[] | null;
      careteamInvites: CareteamInvite[] | null;
    }
  >((state) => {
    const { dashboardUser, customers, mqtt, pharmacy } = state;
    const { dashboardUserInfo } = dashboardUser;
    const { facilityCustomers } = customers;
    const { connected } = mqtt;
    const { pharmacies } = pharmacy;
    const { careteamInvites } = customers;
    return {
      dashboardUserInfo,
      facilityCustomers,
      connected,
      pharmacies,
      careteamInvites,
    };
  });

  const dispatch = useDispatch();

  const history = useHistory();

  const [selectedCustomers, setSelectedCustomers] = useState<Owner[]>([]);

  useEffect(() => {
    if (dashboardUserInfo) {
      if (
        getPrivilegeById(dashboardUserInfo.privilege_id) === 'customer_care'
      ) {
        dispatch(getCustomerList());
      } else if (dashboardUserInfo.facility_id) {
        dispatch(getFacilityCustomers(dashboardUserInfo.facility_id));
      }
      dispatch(clearCustomerAndCareTeam());
      dispatch(sideBarTabSelection(ACCOUNT_DETAILS_ONLY.ACCOUNT_DETAILS));
    }
    if (isNull(pharmacies)) {
      dispatch(getPharmacies());
    }
  }, [connected, dashboardUserInfo, pharmacies, dispatch]);

  useEffect(() => {
    if (careteamInvites) {
      dispatch(populateCareteamInvites(null));
    }
  }, [dispatch, careteamInvites]);

  const columns: Column<Owner>[] = [
    {
      title: 'Role',
      field: 'facility_role_id',
      defaultSort: 'desc',
      render: DisplayRole,
    },
    { title: 'Id', field: 'customer_id' },
    {
      title: 'Name',
      field: 'name',
      render: NameFormatter,
      customSort: sortByFullName,
    },
    { title: 'Username', field: 'email' },
    {
      title: 'Created',
      field: 'created_at',
      customSort: sortByCreatedAt,
      render: newlyCreated,
    },
    {
      title: 'Status',
      field: 'is_online',
      render: StatusIcon,
    },
  ];

  const onViewAccountDetails = () => {
    const path = '/customers/' + selectedCustomers[0].customer_id;
    history.push(path);
  };

  const addUser = () => {
    history.push('/customers/create');
  };

  const addOwner = () => {
    history.push('/customers/owner/create');
  };

  // MUI Table has props defined as `any`
  const CustomToolbar = (props: any) => {
    return (
      <div>
        <MTableToolbar {...props} />
        <TableActionsWrapper>
          <Button
            variant="contained"
            color="secondary"
            disabled={selectedCustomers.length !== 1}
            sx={{ marginRight: '10px', marginLeft: '8px' }}
            onClick={onViewAccountDetails}
          >
            View account details
          </Button>
        </TableActionsWrapper>
      </div>
    );
  };

  if (isNull(facilityCustomers) || isNull(dashboardUserInfo)) {
    return <LoadingSpinner />;
  } else {
    let customers = facilityCustomers.filter((customer) => {
      return customer.privilege_id !== 2 && customer.privilege_id !== 3;
    });

    return (
      <Container maxWidth="lg">
        <Helmet>
          <title>Ōmcare - Customers</title>
        </Helmet>
        <Wrapper>
          {dashboardUserInfo &&
            dashboardUserInfo.facility_role_id &&
            UNIFIED_FACILITY_ADMIN_ROLES.includes(
              dashboardUserInfo.facility_role_id
            ) && (
              <HeaderWrapper>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={addUser}
                  sx={{ marginRight: '10px' }}
                >
                  Add user
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={addOwner}
                >
                  Add Ōmcare owner
                </Button>
              </HeaderWrapper>
            )}
          <MaterialTable
            columns={columns}
            data={customers}
            options={{
              idSynonym: 'customer_id',
              pageSize: 150,
              pageSizeOptions: [150, 250, 350],
              selection: true,
              showTextRowsSelected: false,
              ...(TableOptions as Partial<Options<Owner>>),
            }}
            components={{
              Toolbar: CustomToolbar,
            }}
            onSelectionChange={(r) => setSelectedCustomers(r)}
          />
        </Wrapper>
      </Container>
    );
  }
}

export default Customers;

import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { getDeviceList } from '../../../store/devices/actionCreators';
import { Device } from '../../../store/devices/deviceTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCloudArrowDown,
  faPhoneVolume,
} from '@fortawesome/pro-light-svg-icons';
import { useHistory } from 'react-router-dom';
import { Box, IconButton } from '@mui/material';
import StatusChip from '../../Shared/StatusChip';
import { SoftwareStatus } from '../../../shared/constants';
import {
  faBarsFilter,
  faTriangleExclamation,
} from '@fortawesome/pro-solid-svg-icons';
import DashboardTableLegend from './DashboardTableLegend';
import CustomerCareDTable from '../../Shared/CustomerCareDTable';
import OverviewPopover from './OverviewPopover';
import NoDataCard from './NoDataCard';
import LoadingSpinner from '../../Shared/LoadingSpinner';

interface SoftwareDetailPanelProps {
  selectedTab: number;
}

interface ActionCellProps {
  contactName: string | null;
  contactPhone: string | null;
}

const ActionCell = ({ contactName, contactPhone }: ActionCellProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Box
      display="flex"
      justifyContent="end"
      borderRadius="8px"
      border="1px solid var(--Outline-O5---Separator, #D4D4D9)"
    >
      <>
        <IconButton size="small" onClick={handleOpenModal}>
          <FontAwesomeIcon
            icon={faPhoneVolume}
            style={{ marginRight: 8 }}
            size="sm"
          />
        </IconButton>
        <Box
          width="1px"
          bgcolor="var(--Outline-O5---Separator, #D4D4D9)"
          marginY={1}
        />
        <IconButton size="small">
          <FontAwesomeIcon
            icon={faCloudArrowDown}
            size="sm"
            style={{ marginLeft: 8 }}
          />
        </IconButton>
      </>
      {isModalOpen && (
        <OverviewPopover
          onClose={handleCloseModal}
          contactName={contactName}
          contactPhone={contactPhone}
        />
      )}
    </Box>
  );
};
const stateSelector = (state: RootState) => {
  const { devices } = state;
  const { deviceList, isLoading: isDeviceListLoading } = devices;

  return {
    deviceList,
    isDeviceListLoading,
  };
};

const SoftwareDetailPanel: React.FC<SoftwareDetailPanelProps> = ({
  selectedTab,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { deviceList, isDeviceListLoading } = useSelector<
    RootState,
    ReturnType<typeof stateSelector>
  >(stateSelector);

  useEffect(() => {
    if (!deviceList?.length) {
      dispatch(getDeviceList());
    }
  }, [deviceList, dispatch]);

  const onViewAll = () => {
    history.push(`v2/software`);
  };

  const columns = [
    {
      field: 'patient',
      headerName: 'Patient',
      flex: 1,
    },
    ...(selectedTab !== 2
      ? [{ field: 'facility_name', headerName: 'Facility', flex: 1 }]
      : []),
    {
      field: 'software_status',
      headerName: 'Software status',
      flex: 1,
      sortComparator: (v1: any, v2: any) => {
        return v1.text.localeCompare(v2.text);
      },
      renderCell: (params: any) => (
        <Box display="flex" alignItems="center">
          <StatusChip
            icon={params.value.icon}
            iconColor={params.value.color}
            labelText={params.value.text}
            conditionalValue={false}
          />
        </Box>
      ),
    },
    { field: 'hub_serial_number', headerName: 'Hub serial number', flex: 1 },
    { field: 'software_version', headerName: 'Software version', flex: 1 },
    {
      field: 'action',
      headerName: 'Actions',
      renderCell: (params: any) => (
        <ActionCell
          contactName={params.row.contact_name}
          contactPhone={params.row.contact_phone}
        />
      ),
    },
  ];

  const rows: any[] = useMemo(() => {
    let filteredDevices = deviceList || [];

    if (deviceList) {
      filteredDevices = deviceList.filter(
        (device: Device) =>
          device.software_status === SoftwareStatus.NeedUpdating &&
          device.customer_id !== null
      );

      if (selectedTab === 1) {
        filteredDevices = filteredDevices.filter(
          (device: Device) => device.facility_id != null
        );
      } else if (selectedTab === 2) {
        filteredDevices = filteredDevices.filter(
          (device: Device) => device.facility_id == null
        );
      }
    }

    return (
      filteredDevices?.map((device: Device) => {
        const hasPatient = device?.first_name || device?.last_name;
        return {
          id: device.id,
          patient: hasPatient
            ? `${device?.first_name} ${device?.last_name}`
            : 'N/A',
          facility_name: device.facility_name || 'N/A',
          software_status: {
            icon: faTriangleExclamation,
            color: '#DB5543',
            text: SoftwareStatus.NeedUpdating,
          },
          hub_serial_number: device?.serial_number,
          software_version: device.software_version || 'N/A',
          action: faBarsFilter,
          contact_name: device.contact_name || null,
          contact_phone: device.contact_phone || null,
        };
      }) ?? []
    );
  }, [deviceList, selectedTab]);

  const tableLegendData = [
    {
      name: 'Need updating',
      color: '#DB5543',
    },
  ];
  if (isDeviceListLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      {rows.length ? (
        <Box p={2}>
          <DashboardTableLegend tableLegendData={tableLegendData} />
          <CustomerCareDTable
            rows={rows}
            columns={columns}
            loading={isDeviceListLoading}
            pageSize={4}
            onViewAll={onViewAll}
            defaultSortField="software_status"
            defaultSortDirection="asc"
          />
        </Box>
      ) : (
        <NoDataCard />
      )}
    </>
  );
};

export default SoftwareDetailPanel;

import { SemVer } from 'semver';

export enum SoftwareActionType {
  SOFTWARE_API_ERR = 'SOFTWARE_API_ERR',
  SOFTWARE_CREATED_EDITED = 'SOFTWARE_CREATED_EDITED',
  SET_SOFTWARE_VERSIONS = 'SET_SOFTWARE_VERSIONS',
  SET_SOFTWARE_DEPLOYMENT = 'SET_SOFTWARE_DEPLOYMENT',
  SOFTWARE_SET_LOAD_STATE = 'SOFTWARE_SET_LOAD_STATE',
}

export interface SoftwareStore {
  softwareVersions: SoftwareVersion[] | null;
  baseVersionOptions: SoftwareVersion[];
  deployments: SoftwareDeployment[] | null;
  deployment_loaded: boolean;
  error: any | null;
  isLoading: boolean;
  goToSoftwareVersionsPage: boolean;
}

export interface SoftwareAPIErrorAction {
  type: SoftwareActionType.SOFTWARE_API_ERR;
  payload: { error: any };
}

export interface SoftwareSetLoadStateAction {
  type: SoftwareActionType.SOFTWARE_SET_LOAD_STATE;
  payload: { isLoading: boolean };
}

export interface SoftwareCreatedEditedAction {
  type: SoftwareActionType.SOFTWARE_CREATED_EDITED;
  payload: { software: SoftwareVersion[] };
}

export interface SoftwareSetVersionAction {
  type: SoftwareActionType.SET_SOFTWARE_VERSIONS;
  payload: { software: SoftwareVersion[] };
}

export interface SetSoftwareDevelopmentAction {
  type: SoftwareActionType.SET_SOFTWARE_DEPLOYMENT;
  payload: { deployments: SoftwareDeployment[] };
}

export type SoftwareAction =
  | SetSoftwareDevelopmentAction
  | SoftwareSetVersionAction
  | SoftwareCreatedEditedAction
  | SoftwareSetLoadStateAction
  | SoftwareAPIErrorAction;

export interface SoftwareVersion {
  id: number;
  semver: SemVer | null;
  software_product_id: number;
  version_major: number;
  version_minor: number;
  version_prerelease: number;
  base_version_id: number;
  full_url: string;
  patch_url: string | null;
  documentation_url: string | null;
  created_at: string;
  created_by: number;
  modified_at: string;
  modified_by: number | null;
  version: string;
  version_raw: string;
  commit_url: URL;
  is_lts: boolean;
}

export interface SoftwareDeployment {
  id: number;
  software_version_id: number;
  software_channel_id: number;
  created_at: string;
  created_by: number;
  documentation_url: string | null;
  version_major: number;
  version_minor: number;
  version_prerelease: number;
  email: string;
  software_channel: {
    value: number;
    display: string;
  };
  version: string;
  version_id: number;
  version_raw: string;
}

export interface SoftwareDeploymentRequest {
  version_id: string;
  channel_id: string;
  documentation_url: string;
}

export interface SoftwareVersionRequest {
  version_major: number;
  version_minor: number;
  version_prerelease: number;
  documentation_url: string;
  base_version_id: number;
  full_url: string;
  patch_url: string;
  software_product_id: number;
}

export interface SoftwareVersionRequestV2 {
  version: string;
  documentation_url: string | null;
  base_version_id: number;
  full_url: string;
  patch_url: string | null;
  software_product_id: number;
  set_lts_version?: boolean;
  id?: number;
}

import React from 'react';
import { Box } from '@mui/material';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';
import BaseCard from './BaseCard';
import { CustomLegend } from './CustomLegend';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBadgeCheck } from '@fortawesome/pro-duotone-svg-icons';

interface PatientSurveyData {
  veryHappy: number;
  somewhatHappy: number;
  somewhatUnhappy: number;
  veryUnhappy: number;
}

interface PatientSurveyCardProps {
  isSelected?: boolean;
  onClick?: () => void;
  data: PatientSurveyData;
}

const SURVEY_CONFIG = {
  categories: [
    { name: 'Very unhappy', key: 'veryUnhappy', color: '#DB5543' },
    { name: 'Somewhat unhappy', key: 'somewhatUnhappy', color: '#FA881E' },
    { name: 'Somewhat happy', key: 'somewhatHappy', color: '#6BCABA' },
    { name: 'Very happy', key: 'veryHappy', color: '#42AF60' },
  ],
  noData: {
    color: '#9999A4',
    value: 100,
  },
} as const;

export const PatientSurveyCard: React.FC<PatientSurveyCardProps> = ({
  isSelected,
  onClick,
  data,
}) => {
  const total =
    data.veryHappy +
    data.somewhatHappy +
    data.somewhatUnhappy +
    data.veryUnhappy;
  const isNoData = total === 0;

  const chartData = SURVEY_CONFIG.categories.map((category) => {
    const value = data[category.key as keyof PatientSurveyData];
    return {
      name: category.name,
      value: isNoData ? 0 : value,
      percentage: isNoData ? 0 : Math.round((value / total) * 100),
      color: category.color,
    };
  });

  if (isNoData) {
    chartData[0].value = SURVEY_CONFIG.noData.value; // to show the no data color in pie chart
  }

  return (
    <BaseCard
      title="Patient survey"
      isSelected={isSelected}
      onClick={onClick}
      icon={<FontAwesomeIcon icon={faBadgeCheck} color="#585863" />}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <CustomLegend
          payload={chartData.map((entry) => ({
            color: entry.color,
            name: entry.name,
            value: isNoData ? 0 : entry.value,
          }))}
        />
        <PieChart width={112} height={112}>
          <Pie
            data={chartData}
            cx="50%"
            cy="50%"
            innerRadius={0}
            outerRadius={55}
            paddingAngle={isNoData ? 0 : 2}
            dataKey="value"
          >
            {chartData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={isNoData ? SURVEY_CONFIG.noData.color : entry.color}
                strokeWidth={0}
              />
            ))}
          </Pie>
          {isNoData && (
            <>
              <text
                x="50%"
                y="45%"
                textAnchor="middle"
                dominantBaseline="middle"
                fill="#FFF"
                style={{
                  fontSize: '14px',
                  fontWeight: 500,
                  letterSpacing: '0.2px',
                  lineHeight: '20px',
                }}
              >
                No
              </text>
              <text
                x="50%"
                y="60%"
                textAnchor="middle"
                dominantBaseline="middle"
                fill="#FFF"
                style={{
                  fontSize: '14px',
                  fontWeight: 500,
                  letterSpacing: '0.2px',
                  lineHeight: '20px',
                }}
              >
                responses
              </text>
            </>
          )}
          {!isNoData && (
            <Tooltip
              formatter={(_value: number, name: string) => {
                const dataPoint = chartData.find((item) => item.name === name);
                return [`${dataPoint?.percentage}%` || 0];
              }}
              contentStyle={{
                borderRadius: 8,
                border: 'none',
                boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
              }}
            />
          )}
        </PieChart>
      </Box>
    </BaseCard>
  );
};

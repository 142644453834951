import { Box } from '@mui/material';
import React from 'react';
import ComingSoonCard from '../Dashboard/detailPanel/ComingSoonCard ';

const Issues = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100%"
    >
      <ComingSoonCard />
    </Box>
  );
};

export default Issues;

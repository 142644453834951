import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { getDeviceList } from '../../../store/devices/actionCreators';
import { Device } from '../../../store/devices/deviceTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEnvelope,
  faLink,
  faPhoneVolume,
} from '@fortawesome/pro-light-svg-icons';
import { useHistory } from 'react-router-dom';
import { Box, IconButton } from '@mui/material';
import StatusChip from '../../Shared/StatusChip';
import { Status } from '../../../shared/constants';
import {
  faBarsFilter,
  faCircleExclamation,
  faTriangleExclamation,
} from '@fortawesome/pro-solid-svg-icons';
import DashboardTableLegend from './DashboardTableLegend';
import CustomerCareDTable from '../../Shared/CustomerCareDTable';
import OverviewPopover from './OverviewPopover';
import NoDataCard from './NoDataCard';
import LoadingSpinner from '../../Shared/LoadingSpinner';
import { parseDateToLocal } from '../../../shared/utils';

interface HubsDetailPanelProps {
  selectedTab: number;
}

interface ActionCellProps {
  isUnassigned: boolean;
  contactName: string | null;
  contactPhone: string | null;
  contactEmail: string | null;
}

const ActionCell = ({
  isUnassigned,
  contactName,
  contactPhone,
  contactEmail,
}: ActionCellProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleEmailClick = () => {
    if (contactEmail) {
      window.location.href = `mailto:${contactEmail}`;
    }
  };

  return (
    <Box
      display="flex"
      justifyContent="end"
      borderRadius="8px"
      border="1px solid var(--Outline-O5---Separator, #D4D4D9)"
    >
      {isUnassigned ? (
        <Box width="100%" display="flex" justifyContent="center">
          <IconButton size="small">
            <FontAwesomeIcon icon={faLink} size="sm" />
          </IconButton>
        </Box>
      ) : (
        <>
          <IconButton size="small" onClick={handleOpenModal}>
            <FontAwesomeIcon
              icon={faPhoneVolume}
              style={{ marginRight: 8 }}
              size="sm"
            />
          </IconButton>
          <Box
            width="1px"
            bgcolor="var(--Outline-O5---Separator, #D4D4D9)"
            marginY={1}
          />
          <IconButton size="small" onClick={handleEmailClick}>
            <FontAwesomeIcon
              icon={faEnvelope}
              size="sm"
              style={{ marginLeft: 8 }}
            />
          </IconButton>
        </>
      )}
      {isModalOpen && (
        <OverviewPopover
          onClose={handleCloseModal}
          contactName={contactName}
          contactPhone={contactPhone}
        />
      )}
    </Box>
  );
};
const stateSelector = (state: RootState) => {
  const { devices } = state;
  const { deviceList, isLoading: isDeviceListLoading } = devices;

  return {
    deviceList,
    isDeviceListLoading,
  };
};

const HubsDetailPanel: React.FC<HubsDetailPanelProps> = ({ selectedTab }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { deviceList, isDeviceListLoading } = useSelector<
    RootState,
    ReturnType<typeof stateSelector>
  >(stateSelector);

  useEffect(() => {
    if (!deviceList?.length) {
      dispatch(getDeviceList());
    }
  }, [deviceList, dispatch]);

  const onViewAll = () => {
    history.push(`/v2/hubs`);
  };

  const columns = [
    {
      field: 'patient',
      headerName: 'Patient',
      flex: 1,
    },
    ...(selectedTab !== 2
      ? [{ field: 'facility_name', headerName: 'Facility', flex: 1 }]
      : []),
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      sortComparator: (v1: any, v2: any) => {
        return v1.text.localeCompare(v2.text);
      },
      renderCell: (params: any) => {
        const statusValue = params.value;
        return (
          <Box display="flex" alignItems="center">
            <StatusChip
              icon={statusValue.icon}
              iconColor={statusValue.color}
              labelText={statusValue.text}
              conditionalValue={statusValue.text === Status.Unassigned}
              backgroundColor="#FFFAEB"
            />
          </Box>
        );
      },
    },
    { field: 'hub_serial_number', headerName: 'Hub serial number', flex: 1 },
    { field: 'last_seen_online', headerName: 'Last online', flex: 1 },
    {
      field: 'action',
      headerName: 'Actions',
      renderCell: (params: any) => (
        <ActionCell
          isUnassigned={params.row.isUnassigned}
          contactName={params.row.contact_name}
          contactPhone={params.row.contact_phone}
          contactEmail={params.row.contact_email}
        />
      ),
    },
  ];

  const rows: any[] = useMemo(() => {
    let filteredDevices = deviceList;

    if (deviceList) {
      filteredDevices = deviceList.filter(
        (device: Device) =>
          device.status === Status.Offline.toLowerCase() ||
          device.customer_id === null
      );

      if (selectedTab === 1) {
        filteredDevices = filteredDevices.filter(
          (device: Device) => device.facility_id != null
        );
      } else if (selectedTab === 2) {
        filteredDevices = filteredDevices.filter(
          (device: Device) => device.facility_id == null
        );
      }
    }

    return (
      filteredDevices?.map((device: Device) => {
        const isUnassigned = device.customer_id === null;
        const hasPatient = device?.first_name || device?.last_name;
        return {
          id: device.id,
          patient: hasPatient
            ? `${device?.first_name} ${device?.last_name}`
            : 'N/A',
          facility_name: device.facility_name || 'N/A',
          status: {
            icon: isUnassigned ? faCircleExclamation : faTriangleExclamation,
            color: isUnassigned ? '#FCCD32' : '#DB5543',
            text: isUnassigned ? Status.Unassigned : Status.Offline,
          },
          hub_serial_number: device?.serial_number,
          last_seen_online: device.last_seen_online
            ? parseDateToLocal(device.last_seen_online, 'yyyy-MM-dd HH:mm:ss')
            : 'N/A',
          action: faBarsFilter,
          isUnassigned: isUnassigned,
          contact_name: device.contact_name || null,
          contact_phone: device.contact_phone || null,
          contact_email: device.contact_email || null,
        };
      }) ?? []
    );
  }, [deviceList, selectedTab]);

  const tableLegendData = [
    {
      name: 'Offline',
      color: '#DB5543',
    },
    {
      name: 'Unassigned',
      color: '#FCCD32',
    },
  ];
  if (isDeviceListLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      {rows.length ? (
        <Box p={2}>
          <DashboardTableLegend tableLegendData={tableLegendData} />
          <CustomerCareDTable
            rows={rows}
            columns={columns}
            loading={isDeviceListLoading}
            pageSize={4}
            onViewAll={onViewAll}
            defaultSortField="status"
            defaultSortDirection="asc"
          />
        </Box>
      ) : (
        <NoDataCard />
      )}
    </>
  );
};

export default HubsDetailPanel;

import { faEye, faEyeSlash } from '@fortawesome/pro-light-svg-icons';
import { Button, Container, Grid, Typography } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import isNull from 'lodash/isNull';
import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { FACILITY_ROLE, US_STATES, GENDER } from '../../shared/constants';
import * as AnserInputValidation from '../../shared/inputValidation';
import { RootState } from '../../store';
import {
  createUser,
  setCreateUserSuccessful,
} from '../../store/customers/actionCreators';
import { CreateCustomerPayload } from '../../store/customers/customerTypes';
import { getPharmacies } from '../../store/pharmacy/actionCreators';
import { Pharmacy } from '../../store/pharmacy/pharmacyTypes';
import LoadingSpinner from '../Shared/LoadingSpinner';
import MaskedInput from '../Shared/MaskedInput';
import SelectFormField from '../Shared/SelectFormField';
import {
  IconAdornment,
  TextInputFormField,
} from '../Shared/TextInputFormField';

const schema = yup.object({
  first_name: yup.string().trim().required('This field is required'),

  last_name: yup.string().trim().required('This field is required'),

  username: yup
    .string()
    .trim()
    .required('This field is required')
    .email('Please enter a valid email address'),

  password: yup
    .string()
    .test('match', AnserInputValidation.passwordRequirements, function (value) {
      return AnserInputValidation.validatePassword(value ?? '');
    }),

  password2: yup
    .string()
    .test('match', 'Passwords do not match', function (value) {
      const { password } = this.parent;
      return password === value;
    }),

  gender: yup
    .number()
    .required('This field is required')
    .min(0, 'Invalid gender'),

  pharmacy_id: yup.number().required('This field is required'),

  date_of_birth: yup
    .string()
    .required('This field is required')
    .test('match', 'Please fill in valid date of birth', function (value) {
      return value
        ? AnserInputValidation.validateDateOfBirth(
            value.slice(value.length - 4),
            value.substr(0, 2),
            value.substr(3, 5)
          )
          ? true
          : false
        : false;
    }),

  address1: yup.string().required('This field is required'),

  city: yup.string().required('This field is required'),

  state: yup.string().required('This field is required'),

  zipcode: yup
    .number()
    .typeError('Invalid zip code')
    .required('This field is required')
    .max(99999, 'Invalid zip code')
    .min(10000, 'Invalid zip code'),

  phone: yup.string().required('This field is required'),

  pharmacy_patient_id: yup.string().required('This field is required'),
});

function CreateNewOwner() {
  const { isLoading, pharmacies, createUserSuccessful } = useSelector<
    RootState,
    {
      isLoading: boolean;
      pharmacies: Pharmacy[] | null;
      createUserSuccessful: boolean;
    }
  >((state) => {
    const { customers, pharmacy } = state;
    const { isLoading, createUserSuccessful } = customers;
    const { pharmacies } = pharmacy;
    return {
      isLoading,
      createUserSuccessful,
      pharmacies,
    };
  });
  const dispatch = useDispatch();
  const history = useHistory();

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword((previousState) => !previousState);
  };

  useEffect(() => {
    if (isNull(pharmacies)) {
      dispatch(getPharmacies());
    }
    if (createUserSuccessful) {
      history.push('/customers');
      dispatch(setCreateUserSuccessful(false));
    }
  }, [pharmacies, createUserSuccessful, history, dispatch]);

  const onSubmit = (data: Partial<CreateCustomerPayload>) => {
    delete data.password2;
    dispatch(createUser(data));
  };

  const handleCancel = () => {
    history.push('/customers');
  };

  const pharmacy_list = useMemo(() => {
    if (isNull(pharmacies)) return [];
    return pharmacies.map((pharmacy) => {
      return { label: pharmacy.name, value: pharmacy.id };
    });
  }, [pharmacies]);

  const states_list = useMemo(() => {
    if (US_STATES.length < 0) return [];
    return US_STATES.map((item) => {
      return { label: item.name, value: item.id };
    });
  }, []);

  const genderData = useMemo(() => {
    return [
      { label: 'Female', value: GENDER.FEMALE },
      { label: 'Male', value: GENDER.MALE },
      { label: 'Non binary', value: GENDER.NON_BINARY },
      { label: 'Decline to say', value: GENDER.DECLINE_TO_SAY },
    ];
  }, []);

  return (
    <Container maxWidth="lg">
      <Helmet>
        <title>Ōmcare - Customers - Create New Owner</title>
      </Helmet>
      <Typography variant={'h1'}>Create an Ōmcare owner account</Typography>
      <Formik
        validationSchema={schema}
        onSubmit={onSubmit}
        initialValues={{
          first_name: '',
          last_name: '',
          username: '',
          gender: '',
          pharmacy_id: '',
          date_of_birth: '',
          address1: '',
          address2: '',
          city: '',
          state: '',
          country: 'United States',
          zipcode: '',
          phone: '',
          password: '',
          password2: '',
          facility_role_id: FACILITY_ROLE.FACILITY_PATIENT,
          pharmacy_patient_id: '',
        }}
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Grid container alignItems="flex-start" spacing={2}>
              <Grid item xs={6}>
                <Field
                  label="First name"
                  name="first_name"
                  placeholder="First name"
                  component={TextInputFormField}
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  label="Last name"
                  name="last_name"
                  placeholder="Last name"
                  component={TextInputFormField}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  label="How do they identify"
                  name="gender"
                  placeholder="How do they identify"
                  component={SelectFormField}
                  options={genderData}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  label="Email address"
                  name="username"
                  placeholder="Email address"
                  component={TextInputFormField}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  label="Password"
                  name="password"
                  placeholder="Password"
                  type={showPassword ? 'text' : 'password'}
                  component={TextInputFormField}
                  InputProps={{
                    endAdornment: (
                      <IconAdornment
                        onClick={handleClickShowPassword}
                        faIconProps={{
                          icon: showPassword ? faEyeSlash : faEye,
                        }}
                      />
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  label="Confirm password"
                  name="password2"
                  placeholder="Confirm password"
                  type={showPassword ? 'text' : 'password'}
                  component={TextInputFormField}
                  InputProps={{
                    endAdornment: (
                      <IconAdornment
                        onClick={handleClickShowPassword}
                        faIconProps={{
                          icon: showPassword ? faEyeSlash : faEye,
                        }}
                      />
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  label="Address"
                  name="address1"
                  placeholder="1234 Main Street"
                  component={TextInputFormField}
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  label="Address 2 (optional)"
                  name="address2"
                  placeholder="Apartment, studio, or floor"
                  component={TextInputFormField}
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  label="City"
                  name="city"
                  placeholder="City"
                  component={TextInputFormField}
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  label="State"
                  name="state"
                  placeholder="State"
                  component={SelectFormField}
                  options={states_list}
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  label="Zip code"
                  name="zipcode"
                  placeholder="Zip code"
                  component={TextInputFormField}
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  label="Phone number"
                  name="phone"
                  format="(###) ###-####"
                  placeholder="(123)-456-7890"
                  component={MaskedInput}
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  label="Date of birth"
                  name="date_of_birth"
                  format="##/##/####"
                  mask={['M', 'M', 'D', 'D', 'Y', 'Y', 'Y', 'Y']}
                  placeholder="MM/DD/YYYY"
                  component={MaskedInput}
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  label="Pharmacy"
                  name="pharmacy_id"
                  placeholder="Pharmacy"
                  component={SelectFormField}
                  options={pharmacy_list}
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  label="Pharmacy owner ID"
                  name="pharmacy_patient_id"
                  placeholder="Pharmacy owner ID"
                  component={TextInputFormField}
                />
              </Grid>
            </Grid>
            <div style={{ margin: '20px 0' }}>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                disabled={isLoading}
                style={{ marginRight: 10 }}
              >
                Submit
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                disabled={isLoading}
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      {isLoading && <LoadingSpinner />}
    </Container>
  );
}

export default CreateNewOwner;

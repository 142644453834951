import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ReactComponent as NoCardSelectionIcon } from '../../../images/icons/no_selection_card_icon.svg';

const NoSelectionCard = () => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box sx={{ mb: 2, color: 'text.secondary' }}>
        <NoCardSelectionIcon />
      </Box>
      <Typography
        variant="h6"
        gutterBottom
        sx={{
          color: 'var(--Font-F1---Heading, #250E62)',
          fontSize: '18px',
          fontWeight: 500,
          lineHeight: '24px',
          letterSpacing: '0.2px',
        }}
      >
        Select a card
      </Typography>
      <Typography
        variant="body2"
        sx={{
          color: 'var(--Font-F3---Body, #585863)',
          fontSize: '14px',
          fontWeight: 500,
          lineHeight: '20px',
          letterSpacing: '0.2px',
        }}
      >
        Select a card above to view more information.
      </Typography>
    </Box>
  );
};

export default NoSelectionCard;

import { DateTime } from 'luxon';
import { toast } from 'react-toastify';
import { AnyAction, Dispatch } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import restClient from '../../services/rest';
import {
  CreateMedicationRollPost,
  GetMedicationScheduleTemplateAction,
  HubOwnerStore,
  HubOwnerStoreActionTypes,
  ResetImportMedRollFormAction,
  CreateMedRollTemplate,
  GetMedicationScheduleAction,
  GetMedicationEventHistoryAction,
  MedEventHistory,
  HubOwner,
  FetchHubOwnersSuccessAction,
  SetLoadingAction,
  UnsetLoadingAction,
  AddHubOwnerSuccessAction,
  ResetAddHubOwnerSuccessAction,
  AddHubOwnerParams,
  EditOwnerSuccessAction,
  EditOwnerReqParams,
} from './types';

import { ImportMedicationRoll } from '../../generated/models/ImportMedicationRoll';
import {
  MedEventType,
  MedicationDoseEventType,
  MedEventStatusEnum,
  LoadScheduleMatchItem,
  UpdateMedEvents,
} from '../../generated';
import { setLoadState } from '../customers/actionCreators';
import {
  Drug,
  MedEvent,
  Pouch,
} from '../../components/Shared/ImportMedicationRollStepFormTypes';
import _, { maxBy as _maxBy } from 'lodash';
import { RelationshipToOwner } from '../../shared/constants';

function createMedicationRollSuccess() {
  return {
    type: HubOwnerStoreActionTypes.CREATE_MEDICATION_ROLL_SUCCESS,
  };
}

function getMedicationScheduleTemplateSuccess(
  data: CreateMedRollTemplate
): GetMedicationScheduleTemplateAction {
  return {
    type: HubOwnerStoreActionTypes.GET_MEDICATION_SCHEDULE_TEMPLATE,
    payload: { medScheduleTemplate: data },
  };
}

function getMedicationScheduleSuccess(
  data: LoadScheduleMatchItem[]
): GetMedicationScheduleAction {
  return {
    type: HubOwnerStoreActionTypes.GET_MEDICATION_SCHEDULE,
    payload: { medSchedule: data },
  };
}

function getMedicationEventHistorySuccess(
  ownerId: number,
  medEventId: string,
  data: MedEventHistory
): GetMedicationEventHistoryAction {
  return {
    type: HubOwnerStoreActionTypes.GET_MEDICATION_EVENT_HISTORY,
    payload: { ownerId, medEventId, medEventHistory: data },
  };
}

export const resetImportMedRollForm = (): ResetImportMedRollFormAction => {
  return {
    type: HubOwnerStoreActionTypes.RESET_IMPORT_MED_ROLL_FORM,
  };
};

export const setLoading = (isLoading: boolean): SetLoadingAction => {
  return {
    type: HubOwnerStoreActionTypes.SET_LOADING,
    payload: isLoading,
  };
};

export const unsetLoading = (): UnsetLoadingAction => {
  return {
    type: HubOwnerStoreActionTypes.UNSET_LOADING,
  };
};

export const addHubOwnerSuccess = (
  message: string
): AddHubOwnerSuccessAction => {
  return {
    type: HubOwnerStoreActionTypes.ADD_HUB_OWNER_SUCCESS,
    payload: { message },
  };
};

export const resetAddHubOwnerSuccess = (): ResetAddHubOwnerSuccessAction => {
  return {
    type: HubOwnerStoreActionTypes.RESET_ADD_HUB_OWNER_SUCCESS,
  };
};

export const addHubOwner = (data: AddHubOwnerParams) => {
  return async (
    dispatch: ThunkDispatch<HubOwnerStore, void, AnyAction> & Dispatch
  ) => {
    dispatch(setLoading(true));
    try {
      await restClient.owners.addNewOwner(data);
      dispatch(addHubOwnerSuccess('Hub owner added successfully'));
      toast.success('Hub owner added successfully');
    } catch (err: any) {
      if (err.response) {
        switch (err.response.status) {
          case 409:
            toast.error('Customer already exists');
            break;
          case 500:
            toast.error('Cannot add owner. Please try again');
            break;
          default:
            toast.error('Something went wrong');
        }
      } else {
        toast.error('Something went wrong');
      }
    } finally {
      dispatch(unsetLoading());
    }
  };
};

function fetchHubOwnersSuccess(data: HubOwner[]): FetchHubOwnersSuccessAction {
  return {
    type: HubOwnerStoreActionTypes.FETCH_HUB_OWNERS_SUCCESS,
    payload: data,
  };
}

export const fetchHubOwners = (
  relationshipToOwner?: RelationshipToOwner,
  soleRelationship?: boolean
) => {
  return async (
    dispatch: ThunkDispatch<HubOwnerStore, void, AnyAction> & Dispatch
  ) => {
    dispatch(setLoading(true));
    try {
      const response = await restClient.owners.getOwners(
        relationshipToOwner,
        soleRelationship
      );
      dispatch(fetchHubOwnersSuccess(response));
    } catch (err: any) {
      toast.error(err?.response?.data || err);
    } finally {
      dispatch(unsetLoading());
    }
  };
};

export const editOwnerSuccess = (
  ownerId: number,
  requestBody: EditOwnerReqParams
): EditOwnerSuccessAction => {
  return {
    type: HubOwnerStoreActionTypes.EDIT_OWNER_SUCCESS,
    payload: { ownerId, requestBody },
  };
};

export const editOwner = (ownerId: number, requestBody: EditOwnerReqParams) => {
  return async (
    dispatch: ThunkDispatch<HubOwnerStore, void, AnyAction> & Dispatch
  ) => {
    dispatch(setLoading(true));
    try {
      await restClient.owners.editOwner(ownerId, requestBody);
      dispatch(editOwnerSuccess(ownerId, requestBody));
      toast.success('Owner details updated successfully');
      return Promise.resolve();
    } catch (err: any) {
      return Promise.reject(err);
    } finally {
      dispatch(unsetLoading());
    }
  };
};

export const createMedicationRoll = (data: CreateMedicationRollPost) => {
  const params = {
    batch_id: data.batchId,
    start_date: data.startDate.toFormat('yyyy-MM-dd'),
    pharmacy_id: data.pharmacyId,
    schedule_template_cadence: 'REPEAT_DAYS',
    schedule_template_duration: data.scheduleTemplateDuration,
    schedule_template: data.scheduleTemplate.map((medEvent) => {
      return {
        prescribed_time_of_day_24:
          medEvent.prescribedTimeOfDay24.toLocaleString(
            DateTime.TIME_24_SIMPLE
          ),
        desired_time_of_day_24: medEvent.desiredTimeOfDay24.toLocaleString(
          DateTime.TIME_24_SIMPLE
        ),
        event_type: medEvent.eventType,
        pouches: medEvent.pouches.map((pouch) => {
          return {
            pouch_number: pouch.pouchNumber,
            drugs: pouch.drugs.map((d) => {
              return {
                national_drug_code: d.nationalDrugCode,
                roll_packaged: d.rollPackaged,
                is_prn: d.isPrn,
              };
            }),
          };
        }),
      };
    }),
  } as ImportMedicationRoll;

  return async (
    dispatch: ThunkDispatch<HubOwnerStore, void, AnyAction> & Dispatch
  ) => {
    try {
      await restClient.owners.createMedicationRoll(data.customerId, params);

      toast.success('Successfully imported medication roll');
      dispatch(createMedicationRollSuccess());
    } catch (err: any) {
      toast.error(err?.response?.data ?? '');
    }
  };
};

export const getMedicationSchedule = (
  customerId: number,
  startDate: luxon.DateTime = DateTime.fromObject({
    day: 1,
    year: 1970,
    month: 1,
  }),
  endDate: luxon.DateTime = DateTime.fromJSDate(new Date(86400000000000))
) => {
  return async (
    dispatch: ThunkDispatch<HubOwnerStore, void, AnyAction> & Dispatch
  ) => {
    try {
      const medSchedule = await restClient.owners.getMedScheduleByOwnerId(
        customerId,
        startDate.toISODate(),
        endDate.toISODate(),
        true
      );

      const simplifiedMedSchedule = (
        medSchedule?.schedule
          ? medSchedule?.schedule.map((medEvent) => {
              const doses = medEvent.pouches
                .flatMap((pouch) =>
                  pouch.doses.map((dose) => ({
                    ...dose,
                    ...{
                      batch_id: pouch.batch_id.toString(),
                      pouch_number: pouch.pouch_number,
                    },
                  }))
                )
                .map((dose) =>
                  _.assign(
                    {},
                    _.omit(dose, [
                      'dose_id',
                      'medication_dose_status_id',
                      'roll_packaged',
                      'received_at',
                      'administered_timezone',
                      'tablet_count',
                    ]),
                    {
                      id: dose.dose_id,
                      status_id: dose.medication_dose_status_id,
                      roll_packaged: dose.roll_packaged === true,
                      dosage: dose.tablet_count
                        ? `QTY ${dose.tablet_count}`
                        : '',
                      consumption_notes: ' ',
                    }
                  )
                );

              let med_event_status;
              const counts = _.countBy(doses, 'status');
              if (counts.active) {
                med_event_status = MedEventStatusEnum.ACTIVE;
              }
              if (!counts.active && counts.complete) {
                med_event_status = MedEventStatusEnum.COMPLETE;
              }
              if (!counts.complete && !counts.active) {
                if (counts.missed) {
                  med_event_status = MedEventStatusEnum.MISSED;
                }
                if (counts.upcoming) {
                  med_event_status = MedEventStatusEnum.UPCOMING;
                }
                if (!counts.missed && !counts.upcoming && counts.skipped) {
                  med_event_status = MedEventStatusEnum.COMPLETE;
                }
              }

              return _.assign(
                {},
                _.omit(medEvent, ['pouches', 'dose_count', 'pouch_count']),
                {
                  status: med_event_status,
                  doses,
                }
              );
            })
          : []
      ) as LoadScheduleMatchItem[];

      dispatch(getMedicationScheduleSuccess(simplifiedMedSchedule));
      dispatch(setLoadState(false));
    } catch (err: any) {
      toast.error(err?.response?.data || err);
    }
  };
};

export const getMedicationEventHistory = (
  ownerId: number,
  medEventId: string
) => {
  return async (
    dispatch: ThunkDispatch<HubOwnerStore, void, AnyAction> & Dispatch
  ) => {
    try {
      dispatch(setLoadState(true));
      const history =
        await restClient.owners.getMedEventHistoryByOwnerIdAndMedEventId(
          ownerId,
          medEventId
        );
      dispatch(
        getMedicationEventHistorySuccess(
          ownerId,
          medEventId,
          history as MedEventHistory
        )
      );
      dispatch(setLoadState(false));
    } catch (err: any) {
      toast.error(err?.response?.data || err);
    }
  };
};

export const addMedicationDoseEvents = (
  ownerId: number,
  eventName: MedicationDoseEventType,
  requestBody: {
    /**
     * The timestamp of the event.
     */
    timestamp: string;
    /**
     * An array of doses.
     */
    doses: Array<{
      /**
       * The ID of the dose.
       */
      dose_id: string;
      /**
       * The flow of dispensing, this is only required when event_name is dispensed
       */
      dispensed_flow?: 'past_due' | 'on_the_go' | 'med_assist';
    }>;
  }
) => {
  return async (
    dispatch: ThunkDispatch<HubOwnerStore, void, AnyAction> & Dispatch
  ) => {
    try {
      dispatch(setLoadState(true));
      await restClient.owners.addMedicationDoseEvents(
        ownerId,
        eventName,
        requestBody
      );

      toast.success('Medication schedule updated');
      dispatch(getMedicationSchedule(ownerId));
    } catch (err: any) {
      toast.error('Failed to update medication schedule');
    }
  };
};

export const deleteOwnersMedicationDoses = (
  ownerId: number,
  requestBody: {
    /**
     * Filter conditions to determine which medication events to delete
     */
    filters: {
      /**
       * Array of batch IDs to be deleted
       */
      batch_ids?: Array<string>;
      /**
       * Array of pouch numbers to target specific medication events
       */
      pouch_numbers?: Array<number>;
      /**
       * Array of dose IDs to specify which doses to delete
       */
      dose_ids?: Array<number>;
      /**
       * Array of medication event IDs for deletion in the 'YYYY-MM-DD HH:mm:ss' format
       */
      med_event_ids?: Array<string>;
    };
    /**
     * A flag indicating whether only medication event history should be deleted
     */
    delete_history_only?: boolean;
  }
) => {
  return async (
    dispatch: ThunkDispatch<HubOwnerStore, void, AnyAction> & Dispatch
  ) => {
    try {
      dispatch(setLoadState(true));
      await restClient.owners.deleteOwnersMedicationDoses(ownerId, requestBody);

      toast.success('Medication events/history deleted');
      dispatch(getMedicationSchedule(ownerId));
    } catch (err: any) {
      toast.error('Failed to delete medication events/history');
    }
  };
};

export const updateMedEvents = (
  ownerId: number,
  requestBody: UpdateMedEvents
) => {
  return async (
    dispatch: ThunkDispatch<HubOwnerStore, void, AnyAction> & Dispatch
  ) => {
    try {
      dispatch(setLoadState(true));
      await restClient.owners.updateMedEvents(ownerId, requestBody);

      toast.success('Medication events updated');
      dispatch(getMedicationSchedule(ownerId));
    } catch (err: any) {
      toast.error('Failed to update medication events');
    }
  };
};

export const getMedicationScheduleTemplate = (customerId: number) => {
  return async (
    dispatch: ThunkDispatch<HubOwnerStore, void, AnyAction> & Dispatch
  ) => {
    try {
      const medScheduleTemplate =
        await restClient.owners.getMedScheduleByOwnerId(
          customerId,
          DateTime.fromObject({ day: 1, year: 1970, month: 1 }).toISODate(),
          DateTime.fromJSDate(new Date(86400000000000)).toISODate(),
          true
        );

      const medRoll = _maxBy(medScheduleTemplate?.med_rolls, 'end_datetime');

      if (!medRoll) {
        return;
      }

      const matchingMedEvents = _.filter(
        medScheduleTemplate?.schedule,
        (x) =>
          DateTime.fromISO(x.prescribed_datetime).toISODate() ===
            DateTime.fromISO(medRoll.end_datetime).toISODate() &&
          _.some(x.pouches, (p) => p.batch_id === medRoll.batch_id)
      );

      const medEvents = matchingMedEvents?.map((x) => {
        return {
          desiredTimeOfDay: DateTime.fromISO(x.desired_datetime, {
            zone: medScheduleTemplate.schedule_local_timezone,
          }),
          eventType: MedEventType[x.event_type],
          pouches: x.pouches
            ?.filter((p) => p.batch_id === medRoll.batch_id)
            .map((p) => {
              return {
                pouchNumber: p.pouch_number?.toString(),
                drugs: p.doses?.map((d) => {
                  return {
                    name: d.drug_name,
                    ndc: d.national_drug_code,
                    rollPackaged: d.roll_packaged,
                    isPrn: d.is_prn,
                  };
                }) as Drug[],
              };
            }) as Pouch[],
        };
      }) as MedEvent[];
      dispatch(
        getMedicationScheduleTemplateSuccess({
          medEvents: medEvents,
          batchId: medRoll.batch_id,
          date: medEvents.length
            ? DateTime.fromISO(medRoll.end_datetime, {
                zone: medScheduleTemplate.schedule_local_timezone,
              }).startOf('day')
            : undefined,
        })
      );
    } catch (err: any) {
      toast.error(err?.response?.data || err);
    }
  };
};

import React from 'react';
import NoSelectionCard from './NoSelectionCard ';
import NoDataCard from './NoDataCard';
import ComingSoonCard from './ComingSoonCard ';
import HubsDetailPanel from './HubsDetailPanel';
import SoftwareDetailPanel from './SoftwareDetailPanel';
import PatientSurveyDetailPanel from './PatientSurveyDetailPanel';
import AdherenceDetailPanel from './AdherenceDetailPanel';
import IssuesDetailPanel from './IssuesDetailPanel';

interface DetailPanelProps {
  card: string;
  selectedTab: number;
}

const DetailPanel: React.FC<DetailPanelProps> = ({ card, selectedTab }) => {
  if (!card) {
    return <NoSelectionCard />;
  }

  switch (card) {
    case 'adherence':
      return <AdherenceDetailPanel selectedTab={selectedTab} />;
    case 'hubs':
      return <HubsDetailPanel selectedTab={selectedTab} />;
    case 'issues':
      return <IssuesDetailPanel selectedTab={selectedTab} />;
    case 'survey':
      return <PatientSurveyDetailPanel selectedTab={selectedTab} />;
    case 'software':
      return <SoftwareDetailPanel selectedTab={selectedTab} />;
    case 'vitals':
      return <ComingSoonCard />;

    default:
      return <NoDataCard />;
  }
};

export default DetailPanel;

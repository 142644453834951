import { GENDER } from './constants';

export const passwordRequirements: string =
  'Password must be at least 8 characters long, and contain 1 number, 1 upper case and 1 lower case letter and at least 1 special character';

export const validateDateOfBirth = (
  year: string | number,
  month: string | number,
  day: string | number
): Date | null => {
  const dobYear = parseInt(year as string);

  if (isNaN(dobYear)) {
    return null; // fail
  }
  if (dobYear < 1880 || dobYear > new Date().getFullYear()) {
    return null; // fail
  }

  let dobMonth = parseInt(month as string);

  if (isNaN(dobMonth)) {
    return null; // fail
  }
  if (dobMonth < 1 || dobMonth > 12) {
    return null; // fail
  }

  dobMonth -= 1; // JavaScript Date() month is 0-based

  const dobDay = parseInt(day as string);

  if (isNaN(dobDay)) {
    return null; // fail
  }
  if (dobDay < 1) {
    return null; // fail
  }

  const daysPerMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  const isLeapYear = new Date(dobYear, 1, 29).getDate() === 29;

  if (isLeapYear) {
    daysPerMonth[1] = 29;
  }

  if (dobDay > daysPerMonth[dobMonth]) {
    return null; // fail
  }

  return new Date(dobYear, dobMonth, dobDay);
};

export const validateEmail = (email: string): boolean => {
  return email.includes('@');
};

export const validatePhone = (phone: string): boolean => {
  const matches = phone.match(/\d/g);
  return matches !== null && matches.length === 10;
};

export const validatePassword = (password: string): boolean => {
  return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&])[A-Za-z\d@#$!%*?&]{8,}$/.test(
    password
  );
};

export const validateGender = (gender: number): boolean => {
  return Object.values(GENDER).includes(gender);
};

export const validateUUID = (uuid: string): boolean => {
  return /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(
    uuid
  );
};

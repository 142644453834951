import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { styled } from '@mui/system';

import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMedkit,
  faSignIn,
  faSignOut,
  faThumbsUp,
  faExclamationTriangle,
  faPauseCircle,
  faStopCircle,
  faBan,
  faCheckCircle,
  faTimesCircle,
  faQuestionCircle,
  faPaperPlane,
} from '@fortawesome/pro-light-svg-icons';

import { RootState } from '../../../store';
import { MedEventHistoryState } from '../../../store/hubOwner/types';
import { getMedicationEventHistory } from '../../../store/hubOwner/actionCreators';

const Container = styled('div')(() => ({ display: 'flex' }));
const Content = styled('div')(() => ({ flexGrow: 1, margin: 50 }));

const completedSuccessIcon = {
  icon: <FontAwesomeIcon icon={faCheckCircle} />,
  iconStyle: { background: 'rgb(16, 204, 82)', color: '#fff' },
};
const completedFailedIcon = {
  icon: <FontAwesomeIcon icon={faTimesCircle} />,
  iconStyle: { background: 'rgb(255, 0, 0)', color: '#fff' },
};
const invalidatedIcon = {
  icon: <FontAwesomeIcon icon={faBan} />,
  iconStyle: { background: 'rgb(255, 235, 59)', color: '#fff' },
};
const skippedIcon = {
  icon: <FontAwesomeIcon icon={faStopCircle} />,
  iconStyle: { background: 'rgb(189, 189, 189)', color: '#fff' },
};
const bagRemovedIcon = {
  icon: <FontAwesomeIcon icon={faPauseCircle} />,
  iconStyle: { background: 'rgb(251, 140, 0)', color: '#fff' },
};
const dispenseFailedIcon = {
  icon: <FontAwesomeIcon icon={faExclamationTriangle} />,
  iconStyle: { background: 'rgb(255, 87, 34)', color: '#fff' },
};
const dispensedIcon = {
  icon: <FontAwesomeIcon icon={faThumbsUp} />,
  iconStyle: { background: 'rgb(205, 220, 57)', color: '#fff' },
};
const unloadedIcon = {
  icon: <FontAwesomeIcon icon={faSignOut} />,
  iconStyle: { background: 'rgb(216, 27, 96)', color: '#fff' },
};
const loadedIcon = {
  icon: <FontAwesomeIcon icon={faSignIn} />,
  iconStyle: { background: 'rgb(33, 150, 243)', color: '#fff' },
};
const prescribedIcon = {
  icon: <FontAwesomeIcon icon={faMedkit} />,
  iconStyle: { background: 'rgb(142, 36, 170)', color: '#fff' },
};
const predispensedIcon = {
  icon: <FontAwesomeIcon icon={faPaperPlane} />,
  iconStyle: { background: 'rgb(255, 193, 7)', color: '#fff' },
};
const unansweredIcon = {
  icon: <FontAwesomeIcon icon={faQuestionCircle} />,
  iconStyle: { background: 'rgb(252, 211, 77)', color: '#fff' },
};

const EVENT_ICON_MAPPING: { [key: number]: any } = {
  10: prescribedIcon,
  20: loadedIcon,
  21: unloadedIcon,
  30: dispensedIcon,
  31: dispenseFailedIcon,
  32: bagRemovedIcon,
  40: skippedIcon,
  50: completedSuccessIcon,
  60: invalidatedIcon,
  70: completedFailedIcon,
  39: predispensedIcon,
  73: unansweredIcon,
  75: unansweredIcon,
  76: unansweredIcon,
  77: unansweredIcon,
  79: unansweredIcon,
};
const EVENT_NAME_MAPPING: { [key: number]: string } = {
  10: 'Prescribed',
  20: 'Loaded',
  21: 'Unloaded',
  30: 'Dispensed',
  31: 'Dispense Failed',
  32: 'Bag Removed',
  40: 'Skipped',
  50: 'Administered',
  60: 'Invalidated',
  70: 'Missed',
  39: 'Pre-Dispensed',
  73: 'Dispensed but Unanswered',
  75: 'Prescribed but Unanswered',
  76: 'Bag Removed but Unanswered',
  77: 'Unloaded but Unanswered',
  79: 'Pre-Dispensed but Unanswered',
};

interface TimelineEvent {
  icon: any;
  date?: string;
  title?: string;
  subtitle?: string;
  desc?: any;
}

const MedicationEventHistory = () => {
  const { med_event_id } = useParams<{ med_event_id: string }>();
  const { customer_id } = useParams<{ customer_id: string }>();
  const dispatch = useDispatch();

  const medEventHistory = useSelector<
    RootState,
    MedEventHistoryState | undefined
  >((state) => {
    return state.hubOwner.medEventHistory;
  });

  // useEffect to retrieve med event history
  useEffect(() => {
    if (
      !medEventHistory ||
      medEventHistory.ownerId !== Number(customer_id) ||
      medEventHistory.medEventId !== med_event_id
    ) {
      dispatch(getMedicationEventHistory(Number(customer_id), med_event_id));
    }
  }, [medEventHistory, dispatch, customer_id, med_event_id]);

  const timeline: TimelineEvent[] = [{ icon: prescribedIcon }];
  medEventHistory?.history &&
    medEventHistory.history.forEach((event) => {
      const newTimelineEvent = {
        date: new Intl.DateTimeFormat('en-US', {
          weekday: 'short',
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
          timeZone: event.device_time_zone,
          timeZoneName: 'short',
        }).format(new Date(event.occured_at)),
        subtitle: `Sent by: ${event.sent_by.customer_first_name} ${event.sent_by.customer_last_name}`,
        desc: (
          <ul>
            {event.doses.map((dose) => (
              <li key={dose.id}>{dose.drug_name}</li>
            ))}
          </ul>
        ),
      };
      timeline.unshift({
        ...newTimelineEvent,
        icon: EVENT_ICON_MAPPING[event.status_id],
        title: EVENT_NAME_MAPPING[event.status_id],
      });

      if (
        event.status_id === 30 &&
        event.event_data &&
        event.event_data.dispensed_flow
      ) {
        if (event.event_data.dispensed_flow === 'past_due') {
          timeline.unshift({
            ...newTimelineEvent,
            icon: EVENT_ICON_MAPPING[70],
            title: EVENT_NAME_MAPPING[70],
          });
        } else if (event.event_data.dispensed_flow === 'on_the_go') {
          timeline.unshift({
            ...newTimelineEvent,
            icon: EVENT_ICON_MAPPING[39],
            title: EVENT_NAME_MAPPING[39],
          });
        }
      }
    });

  return (
    <Container>
      <Helmet>
        <title>Ōmcare - Medication Event History</title>
      </Helmet>
      <Content>
        <h4>Medication Event: {med_event_id}</h4>
        <VerticalTimeline>
          {timeline.map((t, i) => {
            const contentStyle =
              i === 0 && t.title
                ? { background: 'rgb(33, 150, 243)', color: '#fff' }
                : undefined;
            const arrowStyle =
              i === 0 && t.title
                ? { borderRight: '7px solid  rgb(33, 150, 243)' }
                : undefined;

            return (
              <VerticalTimelineElement
                key={i}
                className="vertical-timeline-element--work"
                contentStyle={contentStyle}
                contentArrowStyle={arrowStyle}
                date={t.date}
                dateClassName="omcare-text-default-color"
                {...t.icon}
              >
                {t.title ? (
                  <React.Fragment>
                    <h3 className="vertical-timeline-element-title">
                      {t.title}
                    </h3>
                    {t.subtitle && (
                      <h5 className="vertical-timeline-element-subtitle">
                        {t.subtitle}
                      </h5>
                    )}
                    {t.desc && <p>{t.desc}</p>}
                  </React.Fragment>
                ) : undefined}
              </VerticalTimelineElement>
            );
          })}
        </VerticalTimeline>
      </Content>
    </Container>
  );
};

export default MedicationEventHistory;

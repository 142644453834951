import { Box } from '@mui/material';
import { Field, Form, Formik, FormikProps } from 'formik';
import React from 'react';
import * as yup from 'yup';
import { TextInputFormField } from '../Shared/TextInputFormField';
import MaskedInput from '../Shared/MaskedInput';

const schema = yup.object({
  first_name: yup
    .string()
    .matches(
      /^[A-Za-z]+$/,
      'First name must contain only alphabetical characters'
    )
    .required('First name is required'),
  last_name: yup
    .string()
    .matches(
      /^[A-Za-z]+$/,
      'Last name must contain only alphabetical characters'
    )
    .required('Last name is required'),
  phone: yup
    .string()
    .matches(/^\(\d{3}\) \d{3}-\d{4}$/, 'Phone number is not valid')
    .required('Phone number is required'),
  email: yup
    .string()
    .label('Email')
    .email('Please enter a valid email address')
    .required('This field is required'),
});

export interface NewCustomerFormValues {
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
  privilege_id?: number;
}

interface AddNewCustomerProps {
  formRef: React.RefObject<FormikProps<NewCustomerFormValues>>;
  onSubmit: (values: any) => void;
}

const CreateNewCustomerForm: React.FC<AddNewCustomerProps> = ({
  formRef,
  onSubmit,
}) => {
  return (
    <Formik
      innerRef={formRef}
      validationSchema={schema}
      onSubmit={onSubmit}
      initialValues={
        {
          first_name: '',
          last_name: '',
          email: '',
          phone: '',
        } as NewCustomerFormValues
      }
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ handleSubmit }) => (
        <Form onSubmit={handleSubmit} data-testid="add-new-cus-eng-form">
          <Box display="flex" flexDirection="column" gap={2}>
            <Box display="flex" gap={2}>
              <Field
                label="First name"
                name="first_name"
                placeholder="First name"
                component={TextInputFormField}
              />
              <Field
                label="Last name"
                name="last_name"
                placeholder="Last name"
                component={TextInputFormField}
              />
            </Box>
            <Field
              label="Email address"
              name="email"
              placeholder="Enter an email address"
              component={TextInputFormField}
            />
            <Box display="flex" gap={2}>
              <Field
                label="Phone number"
                name="phone"
                format="(###) ###-####"
                placeholder="(123)-456-7890"
                component={MaskedInput}
              />
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default CreateNewCustomerForm;

import React from 'react';
import { Tabs, Tab, Button, styled, Box } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import { topBarBg } from '../../../mainTheme';

const StyledTab = styled(Tab)({
  textTransform: 'none',
  minWidth: 'auto',
  width: '129px',
  height: '22px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',
  flexShrink: 0,
  borderRadius: '10px',
  fontSize: '16px',
  letterSpacing: '0.2px',
  lineHeight: '22px',
  fontWeight: 500,
  color: '#585863',
  '&.Mui-selected': {
    backgroundColor: '#F6F2F7',
    border: '1px solid #9773AB',
    fontWeight: 700,
    color: '#2A2A30',
  },
});

interface ButtonConfig {
  show: boolean;
  label: string;
  onClick?: () => void;
}

interface DashboardTabsProps {
  selectedTab: number;
  onTabChange: (tab: number) => void;
  buttonConfig?: ButtonConfig;
}

export const DashboardTabs: React.FC<DashboardTabsProps> = ({
  selectedTab,
  onTabChange,
  buttonConfig,
}) => (
  <Box
    bgcolor={topBarBg}
    display="flex"
    borderTop="1px solid #e0e0e0"
    justifyContent="space-between"
    p={1}
    alignItems="center"
    height="68px"
  >
    <Box
      bgcolor="#ffff"
      borderRadius="10px"
      p={2}
      height="40px"
      display="flex"
      alignItems="center"
    >
      <Tabs
        value={selectedTab}
        onChange={(_, value) => onTabChange(value)}
        sx={{
          '& .MuiTabs-flexContainer': {
            gap: 1,
            height: '30px',
          },
          '& .MuiTabs-indicator': { display: 'none' },
          minHeight: 'unset',
          '& .MuiTab-root': {
            minHeight: '30px',
            paddingTop: '13px',
            paddingBottom: '13px',
          },
        }}
      >
        <StyledTab label="View all" />
        <StyledTab label="Facilities" />
        <StyledTab label="Consumers" />
      </Tabs>
    </Box>

    {buttonConfig?.show && (
      <Button
        variant="contained"
        startIcon={<AddIcon />}
        color="secondary"
        onClick={buttonConfig.onClick}
        sx={{
          height: '40px',
        }}
      >
        {buttonConfig.label}
      </Button>
    )}
  </Box>
);

import { MQTTStoreActionTypes } from './mqttTypes';

export const mqttConnect = (host: string, token: string) => ({
  type: MQTTStoreActionTypes.MQTT_CONNECT,
  payload: { host, token },
});

export const mqttConnected = () => ({
  type: MQTTStoreActionTypes.MQTT_CONNECTED,
});

export const mqttDisconnect = () => ({
  type: MQTTStoreActionTypes.MQTT_DISCONNECT,
});

export const mqttDisconnected = () => ({
  type: MQTTStoreActionTypes.MQTT_DISCONNECTED,
});

export const mqttPublish = (topic: string, msg: any) => ({
  type: MQTTStoreActionTypes.MQTT_PUBLISH,
  payload: { topic, msg },
});
